/* ----------  Invite View  ---------- */

.invites-container
{
	.category-container
	{
		padding-top: 20px;
	}

	.create-meeting-container
	{
		.btn
		{
			padding: 0;
			min-width: 60px;
			min-height: 60px;
			margin-right: 20px;

			@include border-radius(0 0 12px 12px);
			@include font-s($font-base, $weight-regular, 1.2, $brand-warning-01);
			@include button-theme($brand-warning-01, $brand-gray-05, transparent);

			i
			{
				font-size: rem-calc(35);
			}

			&:last-child
			{
				margin-right: 0;
			}
		}
	}
}
