.default-post-card {
    padding: 0 !important;
    color: $black-color;
    .strip {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $brand-gray-01;
        margin: 0;
    }
}

.filter-wrapper {
    .form-group {
        .custom-control-label {
            color: $black-color !important;
        }
    }
}

.response-manager-container {
    font-family: $font-primary;
    // padding: 0 144px;
    .tab-header {
        display: flex;
        justify-content: space-between;
        background-color: $brand-gray-01;
        padding: 10px;
        border-radius: 8px;
        margin: 16px 0px;
        i {
            margin: 0 10px;
        }
    }
    .response-manager {
        padding: 0 8px;
        .response-card {
            border: 1px solid $brand-gray-01;
            border-radius: 8px;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;

            .left-img {
                width: 80px;
                border-radius: 50%;
                height: 85px;
                object-fit: cover;
            }
            h2 {
                font-size: $font-sm;
            }
            h3 {
                font-size: $font-xs;
            }
            p {
                font-size: $font-xs;
                font-family: $font-secondary;
                color: $brand-gray-03;
                margin-bottom: 0;
                text-align: center;
            }
            span {
                font-size: $font-xs;
                margin: 10px 0;
                i {
                    margin-right: 4px;
                }
            }
            h4 {
                color: $orange;
                font-family: $font-secondary;
                font-size: 10px;
                text-align: center;
            }
            .right-img {
                width: 50px;
                border-radius: 50%;
                object-fit: cover;
            }
            &__right {
                display: flex;
                flex-direction: column;
                align-items: center;
                border-left: 1px dashed $brand-gray-01;
                // ::before {
                //     content: '';
                //     display: block;
                //     width: 3px;
                //     height: 100%;
                //     background-color: var(--green);
                //     position: absolute;
                //     right: 0;
                //     bottom: 0;
                //     top: 0;
                //     z-index: 10;
                // }
            }
            &__footer {
                border-top: 1px dashed $brand-gray-01;
                align-items: center;
                &--col {
                    display: flex;
                    border-left: 1px solid $brand-gray-01;
                    border-right: 1px dashed $brand-gray-01;
                    align-items: center;
                    h5 {
                        font-family: $font-secondary;
                        font-size: $font-xs;
                        img {
                            width: 20px;
                            margin: 0 4px;
                        }
                    }
                }
            }
        }
    }
}
