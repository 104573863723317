.response-post-card {
    padding: 0 !important;
    .strip {
        background-color: $brand-gray-02;
        margin: 0;
        align-items: center;
        padding: 6px 10px;
        i {
            color: $brand-gray-04;
        }
    }
}

.response-manager-tab-head {
    background-color: $brand-gray-01;
    padding: 4px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.comment {
    border: 1px solid $brand-gray-01;
    border-radius: 8px;
    padding: 0 40px;
    margin: 10px 0;
    &__box {
        padding: 20px 0px;
        &--user-side {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            align-items: center;
        }
        .col-md-3 {
            border-right: 1px solid $brand-gray-01;
        }
        .col-md-9 {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    &__replies {
        padding: 20px 0;
        border-top: 1px dashed $brand-gray-01;
    }
    .info {
        display: flex;
        justify-content: space-between;
        font-size: $font-xs;
        div:nth-child(2) {
            font-style: italic;
            font-family: $font-secondary;
        }
    }
}

.referral-card {
    border: 1px solid $brand-gray-01;
    border-radius: 8px;
    padding: 20px 0;
    margin-top: 20px;
    .col-left {
        display: flex;
        border-right: 1px solid $brand-gray-01;
        img {
            width: 80px;
        }
        h2 {
            font-size: $font-md;
            font-weight: $weight-regular;
        }
        h3 {
            font-size: $font-sm;
            color: $brand-gray-04;
            font-weight: $weight-regular;
            margin: 10px 0;
        }
        p{
            font-size: $font-xs;
            color: $brand-gray-04;
            font-weight: $weight-light;
        }
    }
    .col-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        h4 {
            color: $orange;
            font-style: italic;
            font-size: $font-xs;
            font-family: $font-secondary;
        }
        img {
            width: 40px;
        }
        p {
            font-size: $font-sm;
            margin-top: 10px;
        }
    }
    span {
        font-size: $font-xs;
        font-family: $font-secondary;
        font-style: italic;
        color: $brand-gray-03;
    }
}