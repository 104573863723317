/* ----------  Search Profile View  ---------- */

.search-profile-container
{
	.form-label, label
	{
		margin-bottom: 10px;
		font-family: $font-secondary;
	}

	.text-muted
	{
		@include text-color($brand-gray-03);
	}

	.form-group
	{
		margin-bottom: 50px;
	}

	.card-search-profile
	{
		padding: 25px 20px;
		padding-bottom: 60px;
	}
}
