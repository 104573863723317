.indConnection {
    .nav-link {
        font-weight: 400 !important;
    }
    .importText {
        font-size: 12px;
        margin-right: 26px;
    }
    i {
        color: $brand-gray;
    }
    &__importedWrapper {
        padding: 0 144px;
        @include media-breakpoint-down(lg) {
            padding: 0 20px;
        }
    }
}

.connection-card {
    .dropdown {
        i {
            color: $brand-gray;
        }
    }
}

.ind-contact-row {
    border: 1px solid $brand-gray-01;
    border-radius: $br;
    margin-bottom: 20px;
    padding: 20px 10px;
    font-size: $font-base;
    .col-6 {
        padding-left: 50px;
        border-left: 1px solid $brand-gray-01;
        border-right: 1px solid $brand-gray-01;
    }
    .col-2 {
        text-align: center;
        a {
            text-decoration: none;
            color: orange;
        }
    }
    .custom-control {
        position: absolute;
        top: -6px;
        left: -5px;
    }
}

.btn-blue-gray {
    background-color: $white-color ;
    color: $brand-dark;
    border: 1px solid $brand-gray-01;
}

.btn-blue-gray:hover {
    background-color: $brand-dark;
    color: $white-color;
}

.mutualConnectionModal {
    .modal-title {
        font-size: $font-sm;
        white-space: nowrap;
    }
    input {
        font-size: $font-sm;
    }
}