/* ----------  Calendar  ---------- */

.app-calendar
{
	.calendar-navigation
	{
		margin-bottom: 18px;

		// @include display-flex(column, normal, flex-end);

		.navigation-inner
		{
			@include display-inline-flex(center, flex-end);
		}

		.form-control
		{
			&.form-control-month
			{
				width: 140px;
			}

			&.form-control-year
			{
				width: 90px;
			}
		}

		.form-group
		{
			margin: 0;
			margin-right: 10px;

			&:last-child
			{
				margin: 0;
			}
		}
	}

	.react-calendar
	{
		abbr
		{
			border-bottom: none !important;
			cursor: inherit !important;
			text-decoration: none !important;
		}

		&__tile
		{
			margin: 0;
			min-height: 80px;
			text-align: left;
			padding: 5px !important;
			border-color: $brand-gray-01 !important;

			@include position;
			@include bg-color(transparent);
			@include display-flex(column, flex-start, flex-start);
			@include font-p(rem-calc(10), $weight-regular, 1.2, $brand-dark);

			abbr
			{
				display: none;
			}

			span
			{
				display: block;
			}

			.tile-date
			{
				@include display-inline-flex;

				span
				{
					margin-right: 5px;

					&:last-child
					{
						margin-right: 0;
					}
				}
			}

			.tile-event-counts
			{
				width: 40px;
				height: 40px;
				font-size: $font-base;
				background: $white-color;
				border: 1px solid rgba($brand-dark, 0.7);

				@include absolute-center;
				@include border-radius(99px);
				@include display-flex(column, center, center);
			}

			&:hover:not(.react-calendar__tile--active), &:active:not(.react-calendar__tile--active), &:focus:not(.react-calendar__tile--active)
			{
				@include bg-color(rgba($brand-gray-01, 0.3));
			}
		}

		&__tile--now
		{
			@include bg-color(transparent);

			.tile-date
			{
				color: $brand-warning;
			}
		}

		&__tile--active
		{
			border-color: rgba($brand-dark, 0.7) !important;

			@include bg-color(rgba($brand-gray-01, 0.6));
		}

		&__month-view__days
		{
			// border: 1px solid $brand-gray-01;
		}

		&__month-view__weekdays__weekday
		{
			padding: 0;
			padding-bottom: 5px;

			@include font-p($font-xs, $weight-regular, 1.2, rgba($brand-dark, 0.8));
		}
	}
}
