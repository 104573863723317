/* ----------  Navbar  ---------- */

.navbar
{
	padding: 15px 30px;

	.navbar-nav
	{
		> .nav-link
		{
			.fa
			{
				color: $white-color;
				font-size: rem-calc(22);
			}
		}

		&.navbar-nav-user
		{
			.nav-user-dropdown
			{
				.dropdown-toggle
				{
					padding-right: 30px !important;

					&:before
					{
						content: '\f078';

						@include transform(translateY(-50%));
						@include position(absolute, 5, $top: 50%, $right: 0);
						@include font-fa($font-base, $weight-bold, 1, $white-color);
					}

					&:after
					{
						display: none;
					}
				}

				.dropdown-menu
				{
					min-width: 230px;
					max-width: 230px;
					top: calc(100% + 10px);
					right: -20px !important;
					padding: 15px 0 !important;

					.dropdown-item
					{
						border-bottom: 0 !important;
						padding: 10px 30px !important;
						padding-bottom: 12px !important;

						@include position;
						@include display-inline-flex(center, space-between);
						@include font-p($font-sm, $weight-regular, 1.2, $black-color);

						&:after
						{
							content: '';
							height: 1px;
							background: $brand-gray-01;

							@include position(absolute, 1, $bottom: 0, $left: 30px, $right: 30px);
						}

						&:last-child
						{
							&:after
							{
								display: none;
							}
						}

						&:hover, &:active, &:focus
						{
							background: $brand-gray-02 !important;
						}
					}

					&:before
					{
						content: '';
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 8px 8px 8px;
						border-color: transparent transparent $white-color transparent;

						@include position(absolute, 2, $top: -8px, $right: 20px);
					}
				}
			}
		}
	}

	&.navbar-dark
	{
		@include bg-color($black-color);
	}
}
