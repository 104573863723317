.company-sec-nav {
    input {
        font-style: italic;
    }
    .btn {
        // background: none;
        // border: none;
        // background-color: transparent;
        i {
            color: $brand-gray-04;
        }
    }
}

.btn-bg-none {
    background-color: transparent !important;
    border: none !important;
}

.text-orange {
    color: $orange
}

@import 'navbar';
@import 'home';
@import 'profile';
@import 'editProfile';
@import 'newPost';
// @import 'wallet';
@import 'responseManager';
