.regUserCard {
    .card-body {
        img {
            width: 100px;
            height: 100px;
        }
        h2 {
            font-size: 18px;
            margin-bottom: 16px;
        }
        h3 {
            font-size: $text;
            color: #383838;
        }
        p {
            font-size: 12px;
            color: #808080;
            margin-bottom: 0;
        }
        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 16px;
        }
    }
    .card-footer {
        padding: 0;
        background-color: transparent;
        .footerHead {
            font-size: $text;
            display: flex;
            justify-content: center;
            padding: 8px 0;
        }
        .col-2 {
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 1px solid #DDD;
        }
    }
}

.regCompanyCard {
    .card-body {
        padding: 14px 6px;
        h2 {
            font-size: 16px;
        }
        h3 {
            font-size: $text;
            color: $d-grey;
        }
        .col-6 {
            color: $d-grey;
            font-size: $text;
        }
        p {
            padding: 0 15px;
            color: $d-grey;
            font-size: $text;
        }
    }
    .card-footer {
        padding: 0;
        background-color: transparent;
        .footerHead {
            font-size: $text;
            display: flex;
            justify-content: center;
            padding: 8px 0;
        }
        .col-2 {
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 1px solid #DDD;
        }
    }
}