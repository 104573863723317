/* ----------  Data  ---------- */

$global-contextuals: (
	// (class, brand-primary, brand-secondary)
	('white', $white-color, $black-color),
	('black', $black-color, $white-color),
	('primary', $brand-primary, $white-color),
	('secondary', $brand-secondary, $white-color),
	('success', $brand-success, $white-color),
	('info', $brand-info, $white-color),
	('warning', $brand-warning, $white-color),
	('danger', $brand-danger, $white-color),
	('light', $brand-light, $white-color),
	('dark', $brand-dark, $white-color),
	('gray', $brand-gray-01, $brand-dark),
	('gray-light', $brand-gray-03, $brand-gray-04),
);

/* ----------  Contextuals  ---------- */

@each $class, $primary, $secondary in $global-contextuals
{
	.text-#{$class}
	{
		@include text-color($primary);
	}

	.link-#{$class}
	{
		@include text-color($primary, true);
	}

	.bg-#{$class}
	{
		@include bg-color($primary);
	}

	.bd-#{$class}
	{
		border-color: $primary !important;
	}

	.btn.btn-#{$class}
	{
		@include button-theme($secondary, $primary, transparent);

		&:hover, &:active, &:focus
		{
			@include button-theme($secondary, darken($primary, 5%), transparent);
		}
	}

	.btn.btn-outline-#{$class}
	{
		@include button-theme($primary, transparent, $primary);

		&:hover, &:active, &:focus
		{
			@include button-theme($secondary, $primary, $primary);
		}
	}
}
