/*----------  Font Variables  ----------*/

$font-primary				: 'Roboto', sans-serif;
$font-secondary				: 'Nunito', serif;
// $sub-font-01				: sans-serif;
// $sub-font-02				: sans-serif;

/*----------  Icon Fonts  ----------*/
$font-fontawesome			: 'Font Awesome 5 Free';

/*----------  Color Variables  ----------*/

/*----------  Bootstrap's Defaults  ----------*/

$brand-primary				: #007bff;
$brand-secondary			: #868e96;
$brand-success				: #1ede78;
$brand-info					: #17a2b8;
$brand-warning				: #f16823;
$brand-danger				: #dc3545;
$brand-light				: #f8f9fa;
$brand-dark					: #15161e;
$brand-gray					: #6d6d6d;


/*-----  Default Variables  -----*/

$white-color				: #fff !default;
$black-color				: #000 !default;
$light-gray-color			: #ddd  !default;;
/*-----  Unsorted Variables  -----*/

// $color-01					: #;
// $color-02					: #;

/*-----  Brands Variables  -----*/

/* Brand Primary */
// $brand-primary-01			: #;
// $brand-primary-02			: #;

/* Brand Info */
// $brand-info-01				: #;
// $brand-info-02				: #;

/* Brand Warning */
$brand-warning-01			: #eb5015;
$brand-warning-02			: #d6a021;

/* Brand Success */
// $brand-success-01			: #;
// $brand-success-02			: #;

/* Brand Danger */
// $brand-danger-01				: #;
// $brand-danger-02				: #;

/* Brand Dark */
$brand-dark-01				: #202020;
// $brand-dark-02				: #;

/* Brand Gray */
$brand-gray-01				: #ddd;
$brand-gray-02				: #e9ecef;
$brand-gray-03				: #b4b4b4;
$brand-gray-04				: #707070;
$brand-gray-05				: #f4f4f4;
$brand-gray-06				: #959595;
$brand-gray-07				: #979292;

/* Misc */
$orange						: $brand-warning;

/*----------  Font Weight Variables  ----------*/

$weight-hairline 			: 100;
$weight-light	 			: 300;
$weight-regular 			: 400;
$weight-medium				: 500;
$weight-semibold 			: 600;
$weight-bold	 			: 700;
$weight-extrabold	 		: 900;

/*----------  Font Size Variables  ----------*/

$font-base					: rem-calc(16);					// 16px
$font-xs					: $font-base - rem-calc(4);		// 12px
$font-sm					: $font-base - rem-calc(2);		// 14px
$font-md					: $font-base + rem-calc(2);		// 18px
$font-lg					: $font-base + rem-calc(4);		// 20px
$font-xl					: $font-base + rem-calc(24);	// 40px
$font-xxl					: $font-base + rem-calc(44);	// 60px
