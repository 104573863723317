/* ----------  Modals  ---------- */


.modal
{
	.modal-dialog
	{
		.modal-content
		{
			height: auto;
			padding: 30px 0;
			min-height: auto;
			max-height: 80vh;

			.modal-header
			{
				padding: 0;
				margin: 0 30px;
				padding-bottom: 12px;
				border-bottom: 2px solid $brand-gray-01 !important;

				.modal-title
				{
					text-transform: uppercase;

					@include font-p($font-base, $weight-medium, 1.3, $brand-dark);
				}

				button.close
				{
					margin: 0;
					float: none;
					clear: both;

					@include position(absolute, 999, $top: 0, $right: 0);
				}
			}
			.noBorders
			{
				border: 0 !important;
			}
			.modal-body
			{
				padding: 18px 30px;
			}
			.noBlockPaddings
			{
				padding-block: 0;
			}
			.modal-footer
			{
				border: 0;
				padding: 0 30px;
				padding-top: 18px;
			}
			.noPaddings
			{
				padding: 0;
			}
		}
		.customPaddings
		{
			padding: 20px 0px;
		}
		&.modal-lg
		{
			max-width: 680px;
		}
	}

	&.mutualConnectionModal, &.modal-select-campus, &.modal-minimal
	{
		.modal-dialog
		{
			.modal-content
			{
				.modal-header
				{
					border: 0 !important;
				}
			}
		}
	}

	&.modal-selected-institutes
	{
		.modal-dialog.modal-lg
		{
			max-width: 800px;
		}

		.modal-header
		{
			.filter-tags
			{
				padding: 10px 0;
				padding-bottom: 15px;
				border-bottom: 1px dashed $brand-gray-01;
			}
		}

		.modal-body
		{
			.row
			{
				margin-left: -10px;
				margin-right: -10px;

				> [class^="col"]
				{
					padding-left: 10px;
					padding-right: 10px;
				}
			}
		}
	}

	&.modal-calendar
	{
		.events-list-container
		{
			margin-top: 20px;
			padding-top: 20px;
			border-top: 1px dashed $brand-gray-01;

			ul
			{
				margin: 0;
			}

			.list-item
			{
				padding: 10px 15px;
				margin-bottom: 10px;
				border: 1px solid $brand-gray-01;

				@include border-radius(4px);
				@include display-inline-flex;
				@include font-p($font-base, $weight-regular, 1.2, rgba($brand-dark, 0.6));

				span, i
				{
					display: block;
				}

				.item-timestamp
				{
					text-align: center;
					padding-right: 15px;

					span
					{
						font-style: italic;
						margin-bottom: 5px;
						font-size: $font-sm;
						color: rgba($brand-dark, 0.8);

						&:last-child
						{
							margin-bottom: 0;
						}
					}
				}

				.item-actions
				{
					padding-left: 15px;

					.item-atn
					{
						width: 40px;
						height: 40px;
						cursor: pointer;
						font-size: $font-sm;
						background: $brand-gray-01;

						@include border-radius(99px);
						@include display-flex(column, center, center);
					}
				}

				.item-text
				{
					padding: 0 20px;
					min-height: 40px;

					border: 1px solid rgba($brand-gray-01, 0.5);
					border-bottom: 0;
					border-top: 0;

					@include flex(1);
					@include display-flex(column, center, center);
				}

				&:last-child
				{
					margin-bottom: 0;
				}
			}
		}
	}

	&.modal-invite-import
	{
		.invite-form
		{
			margin-bottom: 15px;

			.form-group
			{
				margin-bottom: 0;
			}
		}

		.invite-list
		{
			ul
			{
				margin: 0;
			}

			.list-item
			{
				padding: 15px 0;
				border-bottom: 1px dashed $brand-gray-01;

				@include display-inline-flex;
				@include font-p($font-base, $weight-regular, 1.3, rgba($brand-dark, 0.8));

				span
				{
					display: block;
				}

				> span
				{
					padding: 0 15px;
					border-right: 1px solid rgba($brand-gray-01, 0.5);

					&:first-child
					{
						padding-left: 0;
					}

					&:last-child
					{
						border: 0;
						padding-right: 0;
					}
				}

				.item-name, .item-email
				{
					@include flex(1);
				}

				.item-actions
				{
					.atn
					{
						width: 34px;
						height: 34px;
						font-size: $font-base;
						background: $brand-gray-03;

						@include border-radius(99px);
						@include display-flex(column, center, center);

						*
						{
							font-size: inherit;
						}
					}
				}

				&:last-child
				{
					border: 0;
				}
			}
		}
	}
}

.customPaddings
{
	.modal-dialog
	{
		.modal-content
		{
			padding: 20px 0px;
		}
	}
}