// VARIABLES

$black: #15161E;
$white: #FFFFFF;
$l-grey: #DDDDDD;
$d-grey: #707070;
$orange: #F16823;

$font: Roboto, sans;
$font-sec: Nunito, Roboto;

$text: 14px;
$text-lg: 16px;
$br: 8px;

// IMPORTS

@import 'navbar';
@import 'home';
@import 'connection';
@import 'explore';
@import 'editProfile';
@import 'profile';
@import 'wallet';
@import 'voucher';
@import 'settings';
@import 'responseManager';

.individual-sec-nav {
    padding: 10px 0;
    .nav-item {
        margin-left: 20px;
        @include media-breakpoint-down(md) {
            padding-left: 0;
        }
    }
    .nav-item:first-child {
        margin-left: 0;
    }
    input {
        font-style: italic;
    }
    // .btn {
    //     background: none;
    //     border: none;
    //     background-color: transparent !important;
    //     i {
    //         color: $brand-gray-04;
    //     }
    // }
    label {
        margin-bottom: 0;
        font-size: $font-sm;
    }
    .import-btn {
        background-color: $brand-gray-03 !important;
        margin-left: 10px;
    }
}

.btn-bg-none {
    background-color: transparent !important;
    border: none !important;
}

.mainContainer {
    font-family: $font;
}

.referral-green-badge {
    border-radius: 50rem;
    background-color: #C0DDCE;
    border-color: #C0DDCE;
    padding: 5px 12px;
}
