:root{
  --blue-black: #081238;
  --orange: #F16823;
  --green: #1EDE78;
  --text-primary: #15161E;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0px !important;
}
.custom-radio .custom-control-label, .custom-checkbox .custom-control-label{
  /* padding-left: 2px !important; */
  /* padding-top: 2px; */
}
.edit-profile h3 {
  margin: 10px 0 4px;
}
.userVcard .edit-btn, .userVcard .btn-orange-o {
  position: absolute;
  right: 1px;
  top: 1px;
  font-size: 14px;
}
.userVcard {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 9px;
  height: 100%;
}
.userVcards {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 7px;
  height: 100%;
}
.cards .userVcards {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #fff;
  border-radius: 0.25rem;
}

@media only screen and (min-width: 700px){
  .custom-radio .custom-control-label, .custom-checkbox .custom-control-label {
      /* padding-left: 18px !important; */
      /* padding-top: 2px; */
  }
}

@media only screen and (min-width: 1200px){
  .custom-radio .custom-control-label, .custom-checkbox .custom-control-label {
      /* padding-left: 1px !important; */
      /* padding-top: 2px; */
  }
}


.react-calendar {
  width:auto !important;
  border: 1px solid #fff !important;
}
.react-calendar__tile--active {
  background: #e8e8e8  !important;
  color:#686767 !important;
}
.react-calendar__tile--now {
  background: #f4f4f4   !important;
}
.react-calendar button {
  padding: 1px 41px 37px 0px !important;
  border: 1px solid #f4f4f4  !important;
}
.react-calendar__month-view__days__day--weekend{
  color: #686767;
}
.react-calendar__navigation__arrow .react-calendar__navigation__next2-button{
  border: 1px solid #fff  !important;
}
.react-calendar__month-view__weekdays{
     text-align: left;
    text-transform: inherit;
     font-weight: normal;
    font-size: 0.75em;
}
.btn.btn-dark {
  width: 100px;
  color: #fff !important;
  background: #15161e !important;
  border-color: transparent !important;
}
textarea {
  resize: none !important;
}
h4, .h4 {
  font-size: 14px;
}
.or-hr{
  margin: -7px 0;
}
.modal .modal-header, .modal .modal-body{
  /* padding:10px 1rem 3px 20px !important; */
}
label {
    font-size: 14px;
}
.mt-3, .my-3{
  margin-top: 7px !important;
}
.vl {
  border-left: 2px solid #dedede;
  height: 24px;
  margin-top: 4px;
}
.dropdown-toggle::after{
  border-top: 0em solid !important;
}

@media only screen and ( min-width: 1200px) {
  .body
  {
    font-size: 15px;
  }
  .btn {
    font-size: 12px!important;
    padding: 8px 10px;
    white-space: nowrap;
    line-height: 1;
  }
  .form-group
  {
    margin-bottom: 10px;
  }
  .custom-control-label
  {
    font-size: 14px!important;
  }
  .login
  {
    background-position-y: -30px;
  }
  .login .form-control
  {
    height: auto;
    padding: 4px 6px;
    font-size: 14px!important;
    border-radius: 2px!important;
    border: none!important;
    font-size: 13px;
  }
  .login .form-text
  {
    font-size: 12px;
  }
  .login .sheetBody .form-group label
  {
    font-size: 12px;
  }
  .login .sheetBody .form-control
  {
    border: 1px solid #ddd!important;
  }
  .signupRightSheet
  {
    top: 90px;
    height: calc(100vh - 90px);
  }
  .navbar .navbar-brand
  {
    padding: 0;
    margin-right: 70px!important;
  }
  .navbar .navbar-nav
  {
    align-items: center;
  }
  .navbar .nav-link
  {
    font-size: 15px!important;
    color: #ffffff60!important;
    padding: 0px 16px!important;
  }
  .navbar .nav-link.active{
    color: #fff!important;
  }
  .form-group label
  {
    font-size: 12px;
  }
  .custom-control
  {
    padding-left: 18px;
    font-size: 14px;
  }
  .custom-control-label {
    /* position: initial !important ; */
  }
  .custom-radio .custom-control-input:checked~.custom-control-label::after
  {
    width: 7px!important;
    height: 7px!important;
    left: -17px!important;
    top: 6.8px!important;
  }
  .custom-control-label::before
  {
    width: 13px;
    height: 13px;
    left: -20px;
  }
  .custom-checkbox .custom-control-label::after {
    position: absolute;
    top: .15rem;
    left: -1.35rem;
  }
  .secondary-nav .nav .nav-item .nav-link
  {
    font-size: 14px;
    font-weight: 500;
    padding: .4rem .8rem;
  }
  .custom-tabs .nav-item .nav-link
  {
    font-size: 14px;
  }
  p
  {
    font-size: 14px;
  }
  .profileCard
  {
    /* position: relative !important;
    top: 0!important;
    padding: 16px 8px!important; */
  }
  .profileCard.sticky-top,
  .profileCard-sticky-top
  {
    position: sticky!important;
    top: 82px!important;
  }
  .profileCard li
  {
    font-size: 14px;
  }
  .no-data{
    font-size: 16px;
  }
  .profileCard .userPic img,
  .profileCard .userPic,
  .userPic-modal,
  .userPic-modal img
  {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
  .profileCard .userPic .btn,
  .userPic-modal .btn
  {
    bottom: 0;
  }
  .profileCard h3
  {
    margin: 0 0 0px;
  }
  .profileCard .progress-ring {
    position: absolute;
    left: -13px;
    top: -11.52px;
  }
  .voucherDtCard {
    /* padding: 16px 8px!important; */
    border: 1px solid #ddd!important;
  }
  .voucherDtCard .form-text
  {
    font-size: 14px;
  }
  .post-dt h1
  {
    font-size: 18px;
  }
  .edit-profile .postCard
  {
    padding: 0 8.5px!important;
  }
  .edit-profile  .userVcard
  {
    padding: 16px 8px!important;
  }
  .edit-profile .nav
  {
    top: 73px;
  }
  .scrollNav .nav-link
  {
    font-size: 14px;
  }
  .postCard
  {
    padding: 0 8.5px;
  }
  .postCard .companyPic
  {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .postCard .referred-by .companyPic
  {
    width: 30px;
    height: 30px;
  }
  .postCard h2
  {
    font-size: 14px;
  }
  .postCard h2 label
  {
    font-size: 14px;
  }
  .postCard h2 small
  {
    font-size: 13px;
  }
  .postCard p
  {
    font-size: 13px;
  }
  .postCard .status
  {
    padding: 6px;
    font-size: 11px;
  }
  .postCard .btn-track
  {
    padding: 0!important;
    right: 16px;
  }
  .postCard .strip
  {
    font-size: 12px;
    /* margin:  0 0 -8.5px; */
  }
  .postCard .btn-white
  {
    /* padding: .6rem .2rem!important;
    font-size: 11px!important; */
  }
  .postCard .large-btns .btn
  {
    padding: 8px 10px!important;
  }
  .postCard .dropdown .dropdown-menu .dropdown-item
  {
    font-size: 14px;
  }
  .postCard .fInfo,
  .connection-card .fInfo
  {
    font-size: 12px;
  }
  .postCard .time-abv
  {
    right: 20px;
  }
  .postCard.campusCard .time-abv
  {
    top: 4px;
    right: 8px;
  }
  span.match-tp {
    display: inline-block;
    width: 100%;
    text-align: right;
    margin: 0!important;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .mb-3, .my-3 {
    margin-bottom: 0px !important;
}
.mb-2, .my-2 {
  margin-bottom: 0px !important;
}
@media only screen and (min-width: 1200px) and (min-width: 1200px){

  .mb-3, .my-3 {
    margin-bottom: 0px !important;
  }
  .mb-2, .my-2 {
    margin-bottom: 0px !important;
  }
}
@media only screen and (min-width: 1200px)
{
  .mb-3, .my-3 {
    margin-bottom: 0px !important;
  }
  .mb-2, .my-2 {
    margin-bottom: 0px !important;
  }
}

  .postCard .referred-by label
  {
    font-size: 12px;
  }
  .postCard .hiredOther
  {
    font-size: 12px;
    margin: 0 -8.4px;
    padding: 6px 8px;
  }
  .connection-card .userPic
  {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
  .connection-card h2, .edit-profile .profile-data-card h2
  {
    font-size: 16px;
  }
  .connection-card h2 label, .edit-profile .profile-data-card h2 label
  {
    font-size: 14px;
  }
  .connection-card h2 small, .edit-profile .profile-data-card h2 small
  {
    font-size: 14px;
  }
  .mutualPics
  {
    font-size: 10px;
    text-transform: initial!important;
  }
  .mutualPics img
  {
    width: 20px;
    height: 20px;
  }
  .mutualPics img:not(:first-child)
  {
    margin-left: -14px;
  }
  .btn-rounded
  {
    width: 25px;
    height: 25px;
    line-height: 23px;
    border-radius: 50%!important;
  }
  .btn-rounded-lg
  {
    border-radius: 50%!important;
  }
  .chart .score-card label
  {
    font-size: 12px;
    margin: 0;
  }
  .chart .score-card .fa{
    font-size: 30px;
  }
  .chart .card .userCard{
    padding: 0 8px;
  }
  .chart .card .userCard label,
  .chart .card .userCard .bg-orange-light .text-bold{
    font-size: 14px;
  }
  .chart .card .userCard .bg-orange-light .tile{
    font-size: 12px;
  }
  text.recharts-text,
  .recharts-cartesian-axis-tick text
  {
    font-size: 12px;
  }
  .connection-card .card-check {
    position: absolute;
    left: 25px;
    top: 2px;
  }
  .postCard span .badge
  {
    font-size: 12px;
  }
  .modal-title {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 600;
  }
  .table tbody td
  {
    font-size: 14px;
  }
  .table tbody td .user h2
  {
    font-size: 14px;
  }
  .table tbody td .user h2 span
  {
    font-size: 12px;
  }
  table td .td-rights
  {
    font-size: 12px;
  }
  .tile table td.right-td .badge-secondary
  {
    font-size: 12px!important;
  }
  .invite .tab-btn {
    padding: 9px 11px!important;
    border-radius: 0!important;
  }
  .postCard .resReq
  {
    line-height: 1;
    padding: 0.6rem;
    font-size: 12px;
    margin: 0 -9px;
  }
  .postCard .carousel-item
  {
    padding: 0 .5rem;
  }
  .postCard .carousel-control-prev
  {
    left: -31px;
  }
  .postCard .carousel-control-next
  {
    right: -20px;
  }
  .rightsheet .sheetBody
  {
    max-width: 400px;
    padding: 1rem;
  }
  .rightsheet .sheet-tittle
  {
    font-size: 18px;
    margin: 0 0 16px;
  }
  .switch label
  {
    font-size: 14px;
  }
  .form-text
  {
    font-size: 12px;
  }
  .search h1
  {
    font-size: 18px;
  }
  .notification .dropdown-menu {
    min-width: 26rem;
    margin-top: 14px!important;
  }
  .notification .dropdown-menu .note h3
  {
    font-size: 15px;
  }
  .notification .dropdown-menu .note p
  {
    font-size: 14px;
  }
  .notification .dropdown-menu .note label
  {
    font-size: 12px;
  }
  .btn-add-amt
  {
    height: 31px;
  }
  .wallet .walletCard .wallet-stat .col
  {
    padding: 0 10px;
  }
  .wallet .walletCard h3
  {
    font-size: 18px;
    font-weight: 600;
  }
  .wallet .walletCard label,
  .wallet .bankCard label
  {
    font-size: 14px;
  }
  .wallet .bankCard .item h2,
  .wallet .bankCard .item h2 label
  {
    font-size: 15px;
  }
  .voucher .voucher-stat .card
  {
    padding: 1rem;
  }
  .voucher .voucher-stat .card h4
  {
    font-size: 18px;
  }
  .voucher .voucher-stat .card label
  {
    font-size: 14px;
  }
  .voucher .voucher-cat .card label
  {
    font-size: 12px;
  }
  .voucherCard .companyPic
  {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .voucherCard h2
  {
    font-size: 16px;
  }
  .voucherCard span
  {
    font-size: 12px;
    white-space: nowrap;
  }
  .voucher .cart-stat-card h4
  {
    font-size: 18px;
  }
  .voucher .cart-stat-card label
  {
    font-size: 14px;
  }
  .btn-collapse
  {
    padding: 15px 30px 15px 16px!important;
  }
  .btn-collapse,
  .sec-list h3
  {
    font-size: 14px!important;
    text-transform: initial!important;
  }
  .collapse-body .list
  {
    font-size: 14px;
  }
  .collapse h3
  {
    font-size: 16px;
    font-weight: 600;
  }
  .userVcard h3
  {
    font-size: 14px;
  }
  .userVcard h3 span
  {
    font-size: 12px;
  }
  .col-md-auto.custom-control,
  .col.custom-control,
  .col-xl-6.custom-control,
  .col-auto.custom-control
  {
    padding: 0 16px!important;
  }
  .search-profile .connection-card .fInfo.sm,
  .search-profile .connection-card .ps-abs
  {
    font-size: 12px;
  }
  .comment h3
  {
    font-size: 14px;
  }
  .comment p{
    font-size: 12px;
  }
  .d-mds-inline-block
  {
    display: inline-block!important;
  }
  .pb-0
  {
    padding-bottom: 0!important;
  }
  .pl-3, .px-3 {
    padding-left: 1rem!important;
  }
  .pr-3, .px-3 {
    padding-right: 1rem!important;
  }
  .mb-0
  {
    margin-bottom: 0!important;
  }
  .mds-mt-3
  {
    margin-top: 1rem;
  }
  .mds-pt-5
  {
    padding-top: 3rem;
  }
  .mds-pt-4
  {
    padding-top: 2rem;
  }
  .pt-mds-2
  {
    padding-top: .5rem!important;
  }
  .mb-mds-2
  {
    margin-bottom: .5rem!important;
  }
  .row {
    margin-right: -9px;
    margin-left: -9px;
  }
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto
  {
    padding-left: 8px;
    padding-right: 8px;
  }
  .mds-12
  {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mds-4
  {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .radius-0
  {
    border-radius: 0!important;
  }
}

.profileCard .userPic img, .userPic-modals img{
  width: 80px;
    height: 80px;
    border-radius: 0%;
    object-fit: cover;
    border: 1px solid #dddddd;
}
.profileCard .userPic .btn input, .userPic-modals .btn input {
  position: absolute;
  width: 80%;
  height: 80%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.profileCard .userPic .btn, .userPic-modals .btn {
    /* position: absolute;
    bottom: 3px;
    left: calc(35% );
    background-color: transparent !important;
    border: none !important;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    line-height: 30px !important;
    padding: 0 !important;
    color: #fff;
    cursor: pointer;
    font-size: 22px !important; */
}



.profileCard .userPic img, .userPic-modal img{
  width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}
.profileCard .userPic .btn input, .userPic-modal .btn input {
  position: absolute;
  width: 160%;
  height: 160%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.profileCard .userPic .btn, .userPic-modal .btn {
    /* position: absolute;
    bottom: 13px;
    left: calc(35% );
    background-color: transparent !important;
    border: none !important;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    line-height: 30px !important;
    padding: 0 !important;
    color: #fff;
    cursor: pointer;
    font-size: 22px !important; */
}

@media only screen and (min-width: 1200px)
{
  .profileCard .userPic img, .profileCard .userPic, .userPic-modal, .userPic-modal img {
      width: 100px;
      height: 100px;
      line-height: 100px;
  }
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #c1c1c1 !important;
}

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #F16823;
  width: 4px;
}
.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
  background-color: #fff;
  opacity: 0.9;
}

#about{
	overflow: auto;
	overflow-x: hidden;
}

#about::-webkit-scrollbar-track{
	background-color: #fff;
}

#about::-webkit-scrollbar{
	width: 6px;
	background-color: #fff;
}

#about::-webkit-scrollbar-thumb{
	border-radius: 100px;
	background-color: #F16823;
}
.align-items-center .row{
  /* margin-top: -11px !important; */
}

.card-selected {
  border: 1px solid var(--green) !important;
}


