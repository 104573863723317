/*----------  Animation  ----------*/

@mixin animation($value)
{
	-webkit-animation: $value;
	-moz-animation: $value;
	-o-animation: $value;
	animation: $value;
}

/*----------  Animation Delay  ----------*/

@mixin animation-delay($value)
{
	-webkit-animation-delay: $value;
	-o-animation-delay: $value;
	animation-delay: $value;
}