/* ----------  Wall  ---------- */

.wall
{
	h1
	{
		margin-bottom: 25px;

		@include font-p(rem-calc(22), $weight-regular, 1.2, $brand-dark);
	}
}
