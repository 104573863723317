/* ----------  React Auto Suggest  ---------- */

.react-autosuggest__container
{
	.react-autosuggest__input
	{
		height: auto;
		min-height: 40px;
		padding: 9px 20px;
		border: 1px solid $brand-gray-01;

		@include border-radius(4px);
		@include font-p($font-base, $weight-regular, 1.6, $brand-dark);

		&:focus
		{
			border-color: $brand-dark;
		}
	}
}
