/* ----------  Google Components  ---------- */

.google-place-autocomplete
{
	@include position;

	> .form-control
	{
		padding-right: 45px;
	}

	&:after
	{
		content: '\f002';

		@include transform(translateY(-50%));
		@include font-fa($font-sm, $weight-bold, 1, $brand-dark);
		@include position(absolute, 55, $top: 50%, $right: 15px);
	}
}
