@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.resume-container {
    .name-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid $brand-gray-04;
        padding: 20px 0;
        h1 {
            font-family: 'Playfair Display', serif;
            font-size: $font-xxl;
        }
        img {
            width: 130px;
            height: 130px;
        }
    }
    h2 {
        font-size: $font-base;
    }
    .profile-summary {
        padding: 20px 0;
        border-bottom: 2px solid $brand-gray-02;
        span {
            font-size: $font-sm;
            margin: 10px 0;
            i {
                margin-right: 14px;
            }
        }
        &__head {
            text-align: end;
            font-size: $font-base;
            color: $brand-gray-03;
            margin-bottom: 10px;
        }
        &__summary {
            p {
                font-size: $font-sm;
                color: $brand-gray-04;
            }
        }
    }
    .bio {
        .work-summary {
            font-size: $font-sm;
            font-weight: $weight-regular;
            color: $black-color;
        }
        .col-4 {
            border-right: 2px solid $brand-gray-02;
        }
        h3 {
            font-size: $font-base;
        }
        h4 {
            font-size: $font-sm;
            font-weight: $weight-regular;
            line-height: 1.6;
        }
        p {
            font-size: 13px;
            color: $brand-gray-04;
            font-weight: $weight-light;
        }
        &__education {
            border-bottom: 2px solid $brand-gray-02;
            padding-top: 16px;
            h2 {
                margin: 10px 0;
            }
        }
        &__skills {
            border-bottom: 2px solid $brand-gray-02;
            padding-top: 16px;
            .skill-tiles {
                background-color: $brand-gray-01;
                margin: 10px;
                padding: 6px 14px;
                border-radius: 18px;
            }
        }
    }
}

.resume-container2 {
    h3 {
        font-size: $font-base;
    }
    h4 {
        font-size: $font-sm;
        font-weight: $weight-regular;
        line-height: 1.6;
    }
    p {
        font-size: 13px;
        color: $brand-gray-04;
        font-weight: $weight-light;
    }
    .col-8 {
        border-right: 2px solid $brand-gray-04;
        .name-row {
            display: flex;
            flex-direction: column;
            border-bottom: 2px solid $brand-gray-04;
            padding: 20px 0;
            h1 {
                font-family: 'Playfair Display', serif;
                font-size: $font-xxl;
            }
            h2 {
                font-size: 28px;
            }
        }
        h2 {
            font-size: $font-base;
        }

        .profile-summary {
            padding: 20px 0;
            border-bottom: 2px solid $brand-gray-02;
            p {
                font-size: $font-sm;
                padding-left: 30px;
                border-left: 2px solid $brand-gray-02;
                margin-top: 20px;
                color: $black-color;
                font-weight: $weight-regular;
            }
            span {
                font-size: $font-sm;
                margin: 10px 0;
                i {
                    margin-right: 14px;
                }
            }
        }
        
        .work-wrapper {
            padding-left: 30px;
            border-left: 2px solid $brand-gray-02;
            .work-summary {
                font-size: $font-sm;
                font-weight: $weight-regular;
                color: $black-color;
            }
        }
    }

    .col-4 {
        .img-wrapper {
            display: flex;
            justify-content: flex-end;
            img {
                width: 130px;
                height: 130px;
            }
        }
        .contact {
            span {
                font-size: $font-sm;
                margin: 10px 0;
                i {
                    margin-right: 14px;
                }
            }
        }
        h2 {
            font-size: $font-base;
        }
    }
    
    .bio {
        .col-4 {
            border-right: 2px solid $brand-gray-02;
        }
        &__education {
            border-bottom: 2px solid $brand-gray-02;
            padding-top: 16px;
            h2 {
                margin: 10px 0;
            }
        }
        &__skills {
            border-bottom: 2px solid $brand-gray-02;
            padding-top: 16px;
            .skill-tiles {
                background-color: $brand-gray-01;
                margin: 10px;
                padding: 6px 14px;
                border-radius: 18px;
            }
        }
    }
}

.resume-container3 {
    .col-8 {
        margin-top: 20px;
        border-left: 2px solid $brand-gray-04;
        h2 {
            font-size: $font-base;
        }

        img {
            width: 130px;
            height: 130px;
        }

        .profile-summary {
            p {
                font-size: $font-sm;
                margin-top: 20px;
                color: $black-color;
                font-weight: $weight-regular;
            }
        }
        .work-summary {
            font-size: $font-sm;
            font-weight: $weight-regular;
            color: $black-color;
        }
    }

    .col-4 {
        color: $white-color;
        background-color: rgba($color: #15161E, $alpha: 0.8);
        h2 {
            color: $white-color;
        }
        h3 {
            font-size: $font-base;
            color: $white-color;
        }
        h4 {
            font-size: $font-sm;
            font-weight: $weight-regular;
            line-height: 1.6;
            color: $white-color;
        }
        p {
            font-size: 13px;
            color: $brand-gray-04;
            font-weight: $weight-light;
            color: $white-color;
        }
        .name-row {
            display: flex;
            flex-direction: column;
            border-bottom: 2px solid $brand-gray-02;
            padding: 20px 0;
            h1 {
                font-family: 'Playfair Display', serif;
                font-size: $font-xl;
                font-weight: $weight-medium;
                color: $white-color;
            }
            h2 {
                font-size: 22px;
            }
        }
        .img-wrapper {
            display: flex;
            justify-content: flex-end;
            img {
                width: 130px;
                height: 130px;
            }
        }
        .contact {
            span {
                font-size: $font-sm;
                margin: 10px 0;
                i {
                    margin-right: 14px;
                }
            }
        }
        h2 {
            font-size: $font-base;
        }
    }
    
    .bio {
        .col-4 {
            border-right: 2px solid $brand-gray-02;
        }
        &__education {
            border-bottom: 2px solid $brand-gray-02;
            padding-top: 16px;
            h2 {
                margin: 10px 0;
            }
        }
        &__skills {
            border-bottom: 2px solid $brand-gray-02;
            padding-top: 16px;
            .skill-tiles {
                background-color: #585965;
                margin: 10px;
                padding: 6px 14px;
                border-radius: 18px;
            }
        }
    }
}

.resume-container4 {
    .border-down-orange {
        border-bottom: 2px solid $orange;
        padding-bottom: 20px;
    }
    .border-down-black {
        border-bottom: 2px solid $black;
        padding-bottom: 20px;
    }
    h1 {
        font-family: 'Playfair Display', serif;
        font-size: $font-xl;
        font-weight: $weight-semibold ;
    }
    h2 {
        font-size: 32px;
        font-weight: $weight-regular;
    }
    h3 {
        font-size: 24px;
        letter-spacing: 2px;
        border-bottom: 2px solid $black-color;
        display: initial;
        padding-bottom: 6px;
    }
    h4 {
        font-size: $font-sm;
        font-weight: $weight-regular;
    }
    h5 {
        font-size: 13px;
        color: $brand-gray-04;
        font-weight: $weight-hairline;
        line-height: 1.5;
    }
    .contact-wrapper {
        display: flex;
        justify-content: space-between;
        border-top: 2px solid $brand-gray-02;
        padding: 10px 0;
    }
    .address {
        padding-right: 100px;
        h3 {
            font-size: $font-md;
            letter-spacing: 2px;
            border-bottom: 2px solid $black-color;
            display: initial;
            padding-bottom: 6px;
        }
        p {
            font-size: $font-sm;
            line-height: 1.8;
            margin-top: 10px;
        }
    }
    .profile {
        border-bottom: 2px solid $black-color;
        padding-bottom: 30px;
        h3 {
            font-size: $font-md;
            font-weight: $weight-semibold;
            margin-bottom: 20px;
            letter-spacing: 2px;
        }
        img {
            width: 130px;
            height: 130px;
            margin-top: 20px;
        }
    }
    .profile-summrary {
        font-size: $font-sm;
        color: $brand-gray-04;
        line-height: 1.7;
        align-items: flex-end;
        display: flex;
        border-bottom: 2px solid $orange;
        padding-bottom: 30px;
    }
    .job-summary {
        font-size: $font-sm;
        line-height: 1.7;
    }
    .skill-tiles {
        background-color: $brand-gray-01;
        margin: 10px;
        padding: 6px 14px;
        border-radius: 18px;
    }
}