/* ----------  Edit Profile View  ---------- */

.edit-profile
{
	.btn-save
	{
		padding-left: 55px;
		padding-right: 55px;
		width: auto !important;
	}

	.btn-preview
	{
		padding-left: 40px;
		padding-right: 40px;
	}

	form
	{
		> h2, > h3, > .section-title
		{
			margin: 0 4px;
			margin-top: 20px;
			margin-bottom: 10px;
			padding-bottom: 10px;

			@include font-p($font-md, $weight-medium, 1.2, $brand-dark);
			@include display-inline-flex(center, space-between);

			span
			{
				display: block;

				@include flex(1);
			}
		}
	}

	.nav
	{
		top: 70px;
	}
}

/* ----------  User Pic  ---------- */

.profileCard .user-pic, .profileCard .userPic, .userPic-modal, .userPic-modals
{
	width: 120px;
	height: 120px;
	overflow: hidden;
	line-height: unset;

	@include position;

	img
	{
		border: 0;
		width: 100%;
		height: 100%;
	}

	.btn
	{
		padding: 0;
		min-width: auto;
		min-height: auto;
		color: $white-color;
		width: 100% !important;
		height: 100% !important;
		background: transparent;
		padding-bottom: 10px !important;
		font-size: rem-calc(22) !important;

		@include border-radius(999px);
		@include display-flex(column, center, flex-end);
		@include position(absolute, 11, 0, 0, auto, auto);
		@include text-shadow(0 0 2px rgba($black-color, 0.6));

		input
		{
			width: 100%;
			height: 100%;
		}

		&:hover, &:active, &:focus
		{
			background: rgba($brand-dark, 0.4) !important;

			@include text-color($white-color);
		}
	}
}

.userPic-modal, .userPic-modals
{
	width: 80px;
	height: 80px;
}

/* ----------  Profile Card  ---------- */

.edit-profile, .profile, .company-profile
{
	.card.profileCard
	{
		padding: 20px;
		padding-top: 30px;
		margin-bottom: 30px;

		> h3
		{
			padding: 0;

			@include font-p($font-base, $weight-regular, 1.2, $brand-dark);
		}

		.email-details
		{
			padding-top: 60px;

			@include display-inline-flex;

			span, i
			{
				display: block;
			}
		}
	}

	.privacy-toggles
	{

		.toggles-title
		{
			margin-bottom: 10px;
		}

		.toggles-list
		{
			padding-left: 15px;

			.custom-control
			{
				margin-bottom: 5px;

				&:last-child
				{
					margin: 0;
				}
			}
		}
	}
}
