.ind-profile {
    .profileCard {
        p {
            color: $brand-gray-04;
        }
        i {
            color: $black-color;
        }
    }
}

.work-experience-card i, .education-card i, .accomplishment-card i {
    color: $brand-gray-03;
    margin-right: 1.5rem;
}

.work-experience-card span i, .education-card span i, .accomplishment-card span i {
    margin-right: 0px !important;
    color: #15161E99;

}
.work-experience-card.profile-data-card, .education-card.profile-data-card, .accomplishment-card.profile-data-card {
    padding-right: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }