.manage-users {
    padding: 0 174px;
    @include media-breakpoint-down(lg) {
        padding: 0;
    }
    @include media-breakpoint-down(xs) {
        padding: 0 20px;
    }
}

.response-card {
    border: 1px solid $brand-gray-01;
    border-radius: 8px;
    margin-bottom: 20px;
    .left-img {
        width: 80px;
    }
    h2 {
        font-size: $font-sm;
    }
    h3 {
        font-size: $font-xs;
    }
    p {
        font-size: $font-xs;
        font-family: $font-secondary;
        color: $brand-gray-03;
        margin-bottom: 0;
        text-align: center;
    }
    span {
        font-size: $font-xs;
        margin: 10px 0;
        i {
            margin-right: 4px;
        }
    }
    .created-by {
        margin-top: 20px;
        img {
            width: 30px;
            margin: 0 10px;
        }
    }
    &__footer {
        border-top: 1px dashed $brand-gray-01;
        align-items: center;
        padding: 10px 0;
        &--col {
            display: flex;
            border-left: 1px solid $brand-gray-01;
            border-right: 1px dashed $brand-gray-01;
            align-items: center;
            h5 {
                font-family: $font-secondary;
                font-size: $font-xs;
                img {
                    width: 20px;
                    margin: 0 4px;
                }
            }
        }
    }
}