.settings {
    .btn-white {
        border: 1px solid $brand-gray-01 !important;
        border-radius: 8px !important;
    }
    &__collapse {
        border: none;
        .connection-card {
            padding-bottom: 0 !important;
            .btn-grey {
                background-color: $brand-gray-02;
            }
        }
    }
    .sec-list-child {
        border: 1px solid $brand-gray-01;
        border-radius: 8px;
        justify-content: space-between;
        padding: 14px 20px !important;
        margin: 10px 0;
        span {
            font-size: $font-sm;
            font-weight: $weight-regular;
        }
    }
}