/* ----------  Misc  ---------- */

.response-tab-actions
{
	z-index: 11;
}

[class*="fa-coins"]
{
	@include text-color($brand-warning-02);
}

[class*="fa-ticket"]
{
	@include text-color($brand-warning-01);
}

/* ----------  Default Components  ---------- */

a
{
	text-decoration: none;

	&:hover, &:active, &:focus
	{
		text-decoration: none;
	}
}

/* ----------  Divider  ---------- */

.or-hr
{
	span
	{
		font-style: italic;
		color: $brand-dark;
		text-transform: uppercase;

		@include opacity(0.5);
	}

	&:after
	{
		background-color: transparent;
		border-bottom: 1px dashed $brand-gray-01;
	}
}

/* ----------  Mutual Pics  ---------- */

.mutualPics
{
	@include font-p($font-sm, $weight-regular, 1.2, $brand-dark);

	> img
	{
		width: 30px;
		height: 30px;

		&:not(:first-child)
		{
			margin-left: -15px;
		}
	}
}

/* ----------  Custom Scroll  ---------- */

*
{
	&::-webkit-scrollbar
	{
		width: 6px;
		height: 6px;
		background-color: $white-color;
	}

	&::-webkit-scrollbar-track
	{
		background-color: $white-color;
	}

	&::-webkit-scrollbar-thumb
	{
		background-color: $brand-warning;

		@include border-radius(100px);
	}
}

/* ----------  Container Fluid  ---------- */

.container-fluid
{
	padding-left: 30px;
	padding-right: 30px;
}

/* ----------  Lists  ---------- */

.list-inline
{
	margin: 0;

	@include display-inline-flex($wrap: wrap);
}

/* ----------  Right Sheet  ---------- */

.rightsheet
{
	overflow: hidden;
	background: none;

	@include pointer-events(none);

	.sheetBody
	{
		overflow: auto;
		max-height: 100%;

		@include pointer-events(all);

		&::-webkit-scrollbar
		{
			display: none;
		}
	}

	&.active
	{
		@include animation(none);
	}
}

/* ----------  Scrolls  ---------- */

.no-scroll
{
	overflow: hidden !important;
}

.no-scroll-x
{
	overflow-x: hidden !important;
}

.no-scroll-y
{
	overflow-y: hidden !important;
}

/* ----------  Match Badge  ---------- */

.match-badge
{
	@include display-inline-flex;
	@include font-p($font-xs, $weight-regular, 1, rgba($brand-dark, 0.6));

	span
	{
		display: block;
	}

	.badge-percentage
	{
		min-width: 58px;
		min-height: 26px;
		text-align: center;
		border: 1px solid $brand-success;
		border-color: rgba($brand-success, 0.3);

		@include border-radius(99px);
		@include display-flex(column, center, center);
		@include faded-background($brand-success, 0.15);
		@include font-p($font-md, $weight-regular, 1, $brand-dark);
	}

	.badge-text
	{
		margin-left: 8px;
	}

	&.badge-text-leading
	{
		.badge-text
		{
			margin: 0;
			margin-right: 8px;

			@include order(1);
		}

		.badge-percentage
		{
			@include order(2);
		}
	}
}
