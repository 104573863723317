.voucher-card {
    border-radius: 16px !important;
    select {
        border: none;
    }
    .btn {
        background-color: $brand-gray-01 !important;
        border-radius: 0;
        border-bottom-right-radius: 16px;
        padding: 10px 0;
        i {
            color: $black-color;
        }
    }
    .col-6 {
        font-size: $font-xs;
        color: $brand-gray-03;
        padding-left: 20px;
    }
}

.voucher-cart {
    border: 1px solid $brand-gray-01;
    border-radius: 16px;
    font-size: $font-sm;
    font-weight: $weight-medium;
    &__details {
        img {
            width: 36px;
        }
        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 16px;
        }
    }
    .row {
        border-bottom: 1px solid $brand-gray-01;
        padding: 16px 0;
    }
    .row:last-child {
        border-bottom: none;
        background-color: #CEF8E1
    }
}

.voucher-balance-card {
    border-radius: 16px;
    .walletCard {
        border: none;
        border-bottom: 1px solid $brand-gray-01;
        padding-top: 16px;
    }
    .card-title, .card-footer {
        font-size: $font-sm;
        color: $brand-gray-04;
        font-weight: 400;
        text-align: center;
    }
    .card-footer {
        background-color: transparent;
        text-align: center;
        span {
            font-size: $font-md;
            color: $black-color;
            margin: 4px 0;
            i {
                color: #A88125;
            }
        }
    }
}