.campus-alumini-card {
    .card-header {
        display: flex;
        justify-content: center;
        border-bottom: 0;
        span {
            font-size: $font-sm;
            color: $brand-gray-04;
            margin: 0 10px;
            i {
                color: $orange;
                margin-right: 6px;
            }
        }
    }
    .card-body {
        h5 {
            font-size: $font-md;
            font-weight: $weight-regular;
        }
        label {
            font-size: $font-sm;
            i {
                color: $brand-gray-03;
                margin-right: 6px;
            }
        }
        div {
            display: flex;
            align-items: center;
            img {
                width: 40px;
            }
            h4 {
                font-size: $font-sm;
                margin: 0 10px;
            }
            span {
                font-size: $font-xs;
                color: $brand-gray-04;
                font-style: italic;
                i {
                    margin-right: 6px;
                    color: $brand-gray-04 !important;
                }
            }
        }
    }
    .card-footer {
        display: flex;
        justify-content: space-around;
        background-color: transparent;
        padding: 0;
        button {
            background-color: transparent;
            border: none;
        }
        div:nth-child(2) {
            border-left: 1px solid $brand-gray-03;
            border-right: 1px solid $brand-gray-03;
        }
    }
}

.alumini-modal {
    .modal-content {
        padding-bottom: 20px;
    }
    .modal-title {
        font-size: $font-base;
    }
    .modal-footer {
        display: flex;
        justify-content: center;
        border-top: none;
    }
}

.managemnt-modal {
    .modal-dialog {
        max-width: 427px;
    }
    .modal-content {
        padding-bottom: 20px;
    }
    .modal-title {
        font-size: $font-base;
    }
    .modal-footer {
        display: flex;
        justify-content: center;
        border-top: none;
    }
    .fade{
        overflow-y: auto;
        width: 408px;
        height: 381px;

    }
}