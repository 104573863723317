.radioNavWrapper {
    padding: 10px 0;
    a {
        color: $black;
    }
    .nav-link.active {
        background-color: transparent;
        color: $black;
    }
}

.enqContainerWrapper {
    overflow-x: scroll;
    .enqContainer {
        font-family: $font-sec;
        font-size: 12px;
        @include media-breakpoint-down(md) {
            width: 1122px !important;
        }
        .row:nth-child(1) {
            background-color: $l-grey;
            font-weight: 600;
            padding: 10px 0px;
            border-radius: $br;
            border: none;
            font-size: $text;
        }
        .row{
            border: 1px solid $l-grey;
            border-radius: $br;
            padding: 14px 0px;
            div {
                border-right: 1px solid $l-grey;
            }
        }
    }
}