/* ----------  Gobal Buttons  ---------- */

.btn
{
	letter-spacing: 0.5px;
	text-transform: uppercase;

	@include transition;
	@include border-radius(8px);
	@include display-inline-flex(center, center, nowrap, true);
	@include font-p($font-sm, $weight-regular, 1.1, $brand-dark);

	&.btn-h-auto
	{
		min-height: auto !important;
	}

	&.btn-w-auto
	{
		min-width: auto !important;
	}

	&.btn-rounded-sm
	{
		@include border-radius(4px);
	}

	&.btn-outline-gray-light
	{
		@include text-color($brand-gray-04);

		&:hover, &:active, &:focus
		{
			border-color: lighten($brand-gray-03, 18);

			@include text-color($brand-dark);
			@include bg-color(lighten($brand-gray-03, 18));
		}
	}

	&:not(.btn-auto):not(.btn-rounded):not(.dropdown-toggle):not(.btn-pic-upload)
	{
		padding: 0 12px;
		min-height: 40px;
		min-width: 120px;
		height: auto !important;

		@include border-radius(8px);
	}
}

/* ----------  Buttons  ---------- */

.btn
{
	&.btn-expand
	{
		padding: 12px 40px !important;
		font-size: $font-sm !important;
	}

	&.btn-new-post-select-campus
	{
		padding: 12px 65px;
		font-size: $font-base !important;
	}
}
