/* ----------  React Tags  ---------- */

.react-tags
{
	border: 0;
	margin: 0;
	padding: 0;

	@include border-radius(0);
	@include display-flex(column-reverse);

	.react-tags__search
	{
		border: 0;
		margin: 0;
		padding: 0;

		.react-tags__search-wrapper
		{
			display: block;

			.react-tags__search-input
			{
				width: 100% !important;
			}

			&.has-search-icon
			{
				@include position;

				.react-tags__search-input
				{
					padding-right: 45px;
				}

				&:after
				{
					content: '\f002';

					@include transform(translateY(-50%));
					@include font-fa($font-sm, $weight-bold, 1, $brand-dark);
					@include position(absolute, 55, $top: 50%, $right: 15px);
				}
			}
		}
	}

	.react-tags__selected
	{
		margin: 0 -5px;
		margin-top: 5px;

		@include display-inline-flex($wrap: wrap);

		.react-tags__selected-tag
		{
			border: 0;
			padding: 0;
			margin: 5px;
			min-height: 38px;
			padding: 8px 16px;
			padding-right: 45px;
			background: $white-color;
			border:1px solid $light-gray-color;

			@include position;
			@include border-radius(38px);
			@include display-flex(column, center, center);
			@include font-s($font-sm, $weight-medium, 1, $brand-dark);

			&:after
			{
				content: '\f00d';
				@include transform(translateY(-50%));
				@include position(absolute, 5, $top: 50%, $right: 16px);
				@include font-fa(1.1em, $weight-bold, 1, $brand-warning);
			}

			&.no-remove
			{
				padding-right: 16px;

				&:after
				{
					display: none;
				}
			}
		}
	}
}
