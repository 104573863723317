.navBar {
    font-family: $font;
    background-color: #081238;
    .navBrand {
        font-size: $text-lg;
    }
    .navIcons {
        color: $white;
    }
    .navRegBtn {
        background-color: $white;
        color: $black;
        font-size: 14px;
        padding: 6px 10px;
        border: none;
        border-radius: 4px;
    }
    .navSec a{
        margin: 0 4px;
    }
}