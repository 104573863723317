/* ----------  Post Details  ---------- */

.post-dt
{
	padding-top: 30px;
	padding-bottom: 50px;

	.card.profileCard
	{
		padding: 0;

		.user-details
		{
			text-align: center;
			margin-bottom: 25px;

			h3
			{
				margin: 0;
				margin-bottom: 5px;

				@include font-p($font-base, $weight-medium, 1.3, $brand-dark);

				&:last-child
				{
					margin: 0;
				}
			}

			ul
			{
				text-align: center;

				li
				{
					margin-bottom: 5px;

					@include display-inline-flex(center, center);
					@include font-p($font-base, $weight-regular, 1.2, rgba($brand-dark, 0.8));

					i, span
					{
						display: block;
					}

					i
					{
						margin-right: 7px;
					}

					&:last-child
					{
						margin-bottom: 0;
					}
				}
			}
		}

		.card-meta-list
		{
			ul
			{
				margin: 0;

				li
				{
					margin-bottom: 10px;

					@include display-inline-flex;
					@include font-p($font-sm, $weight-regular, 1.3, $brand-dark);

					i, span
					{
						display: block;
					}

					i
					{
						margin-right: 8px;
					}

					span
					{
						@include flex(1);
					}

					&:last-child
					{
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.card.card-post-comments
	{
		border: 0;
		margin-top: 50px;
		padding-bottom: 40px;

		.card-header
		{
			padding: 0;
			background: none;
			padding-bottom: 12px;
			border-color: $brand-gray-04;

			.card-title
			{
				margin: 0;

				@include display-inline-flex;

				h2
				{
					margin: 0;

					@include font-p($font-md, $weight-medium, 1, $brand-dark);
				}

				> span
				{
					width: 24px;
					height: 24px;
					margin-left: 15px;
					background: $brand-gray-01;

					@include border-radius(99px);
					@include display-flex(column, center, center);
					@include font-p($font-sm, $weight-regular, 1, $brand-dark);
				}
			}
		}

		.card-comment
		{
			padding: 12px 0;
			border-top: 1px dashed $brand-gray-01;

			span
			{
				display: block;
			}

			.item-comment-contents
			{
				.item-body
				{
					margin-bottom: 15px;

					.item-user
					{
						@include display-inline-flex;

						img
						{
							width: 50px;
							height: 50px;
							object-fit: cover;

							@include border-radius(99px);
						}

						.user-avatar
						{
							margin-right: 15px;
						}

						.user-name
						{
							@include font-p($font-base, $weight-regular, 1.2, $brand-dark);
						}
					}

					.item-comment-text
					{
						padding-left: 60px;

						@include font-p($font-base, $weight-regular, 1.4, rgba($brand-dark, 0.6));
					}
				}

				.item-actions
				{
					padding-left: 65px;

					ul
					{
						margin: 0 -20px;

						li
						{
							padding: 0 20px;

							.atn
							{
								cursor: pointer;

								@include transition;
								@include display-inline-flex;
								@include font-p($font-sm, $weight-regular, 1.2, rgba($brand-dark, 0.4));

								*
								{
									@include user-select(none);
								}

								.atn-icon
								{
									display: block;
									margin-right: 8px;
								}

								&.active
								{
									color: $brand-dark;
								}

								&:hover:not(.active), &:active:not(.active), &:focus:not(.active)
								{
									color: rgba($brand-dark, 0.8);
								}
							}
						}
					}
				}
			}

			.item-replies
			{
				padding-top: 16px;
				padding-left: 65px;

				.card-comment
				{
					border: 0;
					padding: 16px 0;

					&:last-child
					{
						padding-bottom: 4px;
					}
				}
			}

			&.card-comment-child
			{
				padding: 16px 0;
				margin-left: 65px;
			}

			&:first-child
			{
				border-top: 0;
			}
		}

		.card-comment-form
		{
			.reply-indicator
			{
				display: block;
				margin-bottom: 8px;
				font-size: $font-sm;
			}

			.form-control
			{
				@include border-radius(8px);
			}

			.form-group
			{
				&.form-group-floating-addon
				{
					.input-group
					{
						.form-control
						{
							padding-right: 130px;
						}

						.input-group-append
						{
							right: 20px;
							width: 110px;

							@include display-inline-flex(center, flex-end);

							.btn
							{
								width: 32px;
								height: 32px;
								margin-right: 10px;

								&.btn-send
								{
									color: $brand-dark;
								}

								&.btn-emoji
								{
									border: transparent;
								}

								&:last-child
								{
									margin: 0;
								}
							}
						}
					}
				}
			}
		}

		.card-body
		{
			padding: 8px 0;
			border-bottom: 1px dashed $brand-gray-01;
		}

		.card-footer
		{
			border: 0;
			margin: 0;
			padding: 20px 0;
			background: $white-color;

			@include position(fixed, 999, $bottom: 0, $left: 0);
		}
	}
}
