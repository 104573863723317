/* ----------  Response Manager  ---------- */

.response-manager-container
{
	padding-bottom: 90px;

	.primary-tabs-container
	{
		border: 1px dashed $brand-gray-01;

		@include border-radius(8px);
		@include display-inline-flex(stretch);

		.total-responses, .response-manager-tab-navs
		{
			padding: 0 10px;
			max-width: 50%;

			@include flex(1 0 50%);
		}

		.total-responses
		{
			padding-top: 8px;
			padding-bottom: 8px;
			border-right: 1px solid rgba($brand-gray-01, 50%);

			span
			{
				display: block;
				text-align: center;

				@include font-p($font-base, $weight-regular, 1.4, $brand-dark);
			}

			.responses-title
			{
				padding-bottom: 6px;
				margin-bottom: 10px;
				border-bottom: 1px dashed $brand-gray-01;
			}

			.responses-count
			{
				margin: 0 10px;
				padding: 4px 10px;
				color: $white-color;
				font-size: rem-calc(30);
				background: $brand-gray-03;

				@include border-radius(16px);
			}
		}

		.response-manager-tab-navs
		{
			.nav.nav-pills
			{
				.nav-item
				{
					border-bottom: 1px dashed $brand-gray-01;

					.nav-link
					{
						padding: 8px 10px;

						@include font-p($font-sm, $weight-regular, 1.2, $brand-dark-01);

						&.active
						{
							color: $brand-warning;
							background: transparent;
						}
					}
				}
			}
		}
	}

	.card-post
	{
		border: 1px solid $brand-gray-01;

		@include box-shadow(none);
	}

	.post-filters-container
	{
		.filter-tabs
		{
			padding: 0 8px;

			.nav-item
			{
				.nav-link
				{
					padding: 10px 15px;

					border: 1px solid $brand-gray-01;
					border-bottom: 0;

					@include font-p($font-sm, $weight-regular, 1.2, $brand-dark);

					&.active
					{
						border-color: transparent;
						background: rgba($brand-gray-01, 0.5);
					}
				}
			}
		}

		.filter-container
		{
			padding: 15px;
			border: 1px solid $brand-gray-01;

			@include border-radius(16px);

			.atn-view-more
			{
				display: block;
				text-decoration: none;

				@include opacity(0.4);
				@include font-p($font-xs, $weight-regular, 1.2, $brand-warning);

				&:hover, &:active, &:focus
				{
					@include opacity(0.6);
				}
			}

			.form-group
			{
				.form-label, .custom-control-label
				{
					@include font-s($font-sm, $weight-regular, 1.2, $brand-gray-04);
				}

				.custom-control-label
				{
					font-size: $font-xs;
				}

				.form-control
				{
					padding: 5px 14px;
					border-color: $brand-gray-01;

					@include border-radius(4px);
					@include placeholder($brand-dark, 0.4);
					@include font-p($font-base, $weight-regular, 1.6, $brand-dark);

					&:focus
					{
						border-color: $brand-dark;

						@include placeholder($brand-dark, 0.7);
					}
				}
			}
		}
	}

	.response-manager
	{
		.connection-card
		{
			.badge
			{
				&.badge-green
				{
					@include faded-background($brand-success, 0.15);
				}
			}
		}
	}
}
