.addBar {
    span {
        background-color: transparent;
        i {
            color: $orange;
        }
    }
}

.dataSearch {
    input {
        border: none;
        border-bottom: 1px solid $l-grey;
    }
    span {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $l-grey;
    }
}

.dataSearch input::placeholder {
    font-style: italic;
    font-weight: 100;
    color: $l-grey;
}

.dataTableRows {
    border: 1px solid $l-grey;
    border-radius: 8px;
    font-family: $font-sec;
    font-size: $text;
    font-weight: 600
}