/* @import url('../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'); */
/* @import url('../../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css'); */

:root {
	--blue-black: #081238;
	--orange: #f16823;
	--green: #1ede78;
	--yellow: #d6a021e3;
	--text-primary: #15161e;
	--fontawesome: 'Font Awesome 5 Free';
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
}

.btn {
	font-size: 14px;
	padding: 7px 30px;
	white-space: nowrap;
	border-radius: 4px;
}

.btn:disabled,
.btn.disabled {
	cursor: not-allowed;
	pointer-events: none;
}

.form-group {
	position: relative;
}

.btn:hover,
.btn:focus,
.form-control:hover,
.form-control:focus,
.custom-select:hover,
.custom-select:focus,
.react-datepicker__input-container input:hover,
.react-datepicker__input-container input:focus {
	outline: none !important;
	box-shadow: none !important;
}

.custom-dropdown-menu {
	padding: 0px !important;
	font-weight: 500 !important;
	background-size: 22px !important;
}

.google-place-autocomplete {
	z-index: 1 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.btn-dark {
	background-color: var(--blue-black) !important;
	border: 1px solid var(--blue-black) !important;
}

.btn-dark:hover {
	background-color: #fff !important;
	color: var(--blue-black) !important;
}

.btn-dark-o {
	background-color: #fff !important;
	color: var(--blue-black) !important;
	border: 1px solid var(--blue-black) !important;
}

.btn-dark-o:hover {
	color: #fff !important;
	background-color: var(--blue-black) !important;
}

.btn-light {
	background-color: #ddd;
	color: var(--blue-black);
	border: 1px solid #ddd;
}

.btn-light:hover {
	color: #fff;
	background-color: var(--blue-black);
	border-color: var(--blue-black);
}

.btn-light-o {
	background-color: #fff !important;
	color: var(--blue-black) !important;
	border: 1px solid #ddd !important;
}

.btn-light-o:hover {
	color: #fff !important;
	background-color: var(--blue-black) !important;
	border-color: var(--blue-black) !important;
}

/* .btn-white {
	background-color: #fff !important;
	border: 1px solid #fff !important;
} */

/* .btn-white:hover {
	background-color: var(--blue-black) !important;
	border-color: var(--blue-black) !important;
	color: #fff !important;
} */

.btn-orange {
	background-color: var(--orange) !important;
	border: 1px solid var(--orange) !important;
	color: #fff !important;
}

.btn-orange:hover {
	background-color: #fff !important;
	color: var(--orange) !important;
}

.btn-orange-o {
	background-color: #fff !important;
	color: var(--orange) !important;
	border: 0 !important;
}

.btn-orange-o:hover {
	background-color: var(--orange) !important;
	color: #fff !important;
}

.btn-green-o {
	background-color: #fff !important;
	color: var(--dark) !important;
	/* border: 1px solid var(--green) !important; */
}

.btn-green-o:hover {
	background-color: var(--dark) !important;
	color: #fff !important;
}

.btn-green {
	background-color: var(--green) !important;
	border: 1px solid var(--green) !important;
	color: #fff !important;
}

.btn-green:hover {
	background-color: #fff !important;
	color: var(--green) !important;
}

.btn-yellow-o {
	background-color: #fff !important;
	color: var(--yellow) !important;
	border: 1px solid var(--yellow) !important;
}

.btn-yellow-o:hover {
	background-color: var(--yellow) !important;
	color: #fff !important;
}

.btn-orange-gray {
	background-color: #fff !important;
	color: var(--orange) !important;
	border: 1px solid #ddd !important;
}

.btn-orange-gray:hover {
	background-color: var(--text-primary) !important;
	color: #fff !important;
}

.btn-view-all {
	border: none !important;
	font-size: 14px;
	padding: 4px !important;
	text-transform: initial !important;
	color: var(--orange);
}

.btn-rounded {
	width: 30px;
	height: 30px;
	line-height: 28px;
	border-radius: 50% !important;
	padding: 0 !important;
}

.btn-rounded-lg {
	width: 40px;
	height: 40px;
	line-height: 38px;
	border-radius: 50% !important;
	padding: 0 !important;
}

.btn-secondary {
	background-color: #dddddd !important;
	border-color: #dddddd !important;
	color: #15161ed2 !important;
}

.btn-bordered {
	border: 1px solid #ddd !important;
}

.btn-Upic {
	width: 20px;
	height: 20px;
	object-fit: cover;
	border-radius: 50%;
	vertical-align: middle;
}

.btn-verify {
	height: 38px !important;
}

.country-phone {
	width: 20%;
}

.form-control,
.custom-select,
.input-group-text,
.react-datepicker__input-container input {
	color: var(--text-primary);
	font-size: 15px;
}

.form-control::placeholder {
	font-size: 14px;
}

/* .form-control {
	height: calc(1.5em + .4rem + 2px);
} */

.form-control,
.react-datepicker__input-container input {
	border: 1px solid #ddd;
	width: 100%;
}

.custom-select {
	background: #fff
		url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' fill='rgba(0,0,0,1)'/%3E%3C/svg%3E")
		no-repeat right 0.75rem center/8px 20px;
	background-size: 12px;
}

.radius-0 {
	border-radius: 0 !important;
}

.radius-right-top-0 {
	border-top-right-radius: 0 !important;
}

.radius-right-bottom-0 {
	border-bottom-right-radius: 0 !important;
}

.radius-left-top-0 {
	border-top-left-radius: 0 !important;
}

.radius-left-bottom-0 {
	border-bottom-left-radius: 0 !important;
}

.nav-notification {
	font-size: 50%;
	color: var(--orange);
	top: -1em;
}

.nav-notification-count {
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	color: #fff;
	background-color: var(--orange);
	line-height: 15px;
	font-size: 12px;
	text-align: center;
}

.signupRightSheet {
	position: fixed;
	top: 120px;
	bottom: 0;
	width: 100%;
	height: calc(100vh - 120px);
	z-index: 999;
	overflow: hidden;
	transition: all 0.4s ease-in-out;
	right: -100%;
}

.signupRightSheet .sheetBody {
	position: absolute;
	padding: 1.2rem;
	border-radius: 8px 0 0 8px;
	width: 100%;
	height: 100%;
	max-width: 350px;
	overflow-y: auto;
	background-color: #fff;
	right: 0;
	transition: all 0.4s ease-in-out;
	transition-delay: 0.3s;
	box-shadow: -3px 0px 6px 1px rgba(0, 0, 0, 0.1);
	scrollbar-color: var(--orange) #ddd;
	scrollbar-width: thin;
}

.signupRightSheet .sheetBody::-webkit-scrollbar {
	width: 5px;
}

/* Track */
.signupRightSheet .sheetBody::-webkit-scrollbar-track {
	background: #ddd;
}

/* Handle */
.signupRightSheet .sheetBody::-webkit-scrollbar-thumb {
	background: var(--orange);
}

/* Handle on hover */
.signupRightSheet .sheetBody::-webkit-scrollbar-thumb:hover {
	background: var(--orange);
}

.signupRightSheet.active {
	right: 0;
}

.signupRightSheet .sheet-tittle {
	font-size: 18px;
	margin: 0 0 16px;
	padding: 0;
	color: var(--text-primary);
}

.signupRightSheet .sheetBody .close {
	position: absolute !important;
	top: 10px !important;
	right: 10px !important;
	width: 30px !important;
	height: 30px !important;
	text-align: center !important;
	line-height: 30px !important;
	padding: 0 !important;
	background-color: transparent !important;
}

.signupRightSheet .sheetBody .close:hover,
.signupRightSheet .sheetBody .close:focus {
	background-color: transparent !important;
}

.signupRightSheet .sheetBody .btn-dark {
	min-width: 70px;
}

.radioButton {
	position: relative;
}

.radioButton input {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
	z-index: 1;
}

.radioButton label {
	position: relative;
	z-index: 0;
	font-size: 14px;
	padding: 6px;
	margin: 0 0 0 -1px;
	text-align: center;
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 0;
	border-bottom: 1px solid #ddd;
}

.radioButton:nth-child(2) label {
	border-left: 0;
	border-right: 0;
}

.radioButton input:checked ~ label {
	border-bottom-color: var(--orange);
	color: var(--orange);
}

.radioButton input:checked ~ label::after {
	content: '';
	display: block;
	width: 100%;
	height: 3px;
	border-radius: 3px;
	background-color: var(--orange);
	position: absolute;
	left: 0;
	bottom: -1.5px;
}

/* rightsheet */
.rightsheet {
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	overflow-y: auto;
	z-index: 1200;
	overflow-x: hidden;
	transition: all 0.4s ease-in-out;
	right: -100%;
}

.rightsheet .sheetBody {
	position: absolute;
	padding: 2rem;
	width: 100%;
	max-width: 500px;
	min-height: 100%;
	background-color: #fff;
	right: 0;
	transition: all 0.4s ease-in-out;
	transition-delay: 0.3s;
	box-shadow: -3px 0px 6px 1px rgba(0, 0, 0, 0.1);
}

.rightsheet.active {
	right: 0;
	animation: bgColor 0.3s forwards;
	animation-delay: 0.4s;
}

@keyframes bgColor {
	from {
		background-color: #00000000;
	}

	to {
		background-color: #00000060;
	}
}

.rightsheet .sheet-tittle {
	font-size: 20px;
	margin: 0 0 30px;
	padding: 0;
	color: var(--text-primary);
}

.rightsheet .sheetBody .close {
	position: absolute !important;
	top: 10px !important;
	right: 10px !important;
	width: 30px !important;
	height: 30px !important;
	text-align: center !important;
	line-height: 30px !important;
	padding: 0 !important;
	background-color: transparent !important;
}

.rightsheet .sheetBody .close:hover,
.rightsheet .sheetBody .close:focus {
	background-color: transparent !important;
}

.custom-control-label {
	/* cursor: pointer !important;
	line-height: 1.3;
	margin-left: 3px !important; */
}

.custom-control-input:checked ~ .custom-control-label::before {
	background-color: #fff !important;
	border-color: #333 !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
	background-image: none !important;
	background-color: var(--orange) !important;
	border-radius: 50% !important;
	width: 10px !important;
	height: 10px !important;
	left: -21px !important;
	top: 7px !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	background-color: var(--orange) !important;
	border-color: var(--orange) !important;
}

.custom-control-input:focus ~ .custom-control-label::after,
.custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: none !important;
	outline: none !important;
}

.close {
	color: var(--orange) !important;
}

.login {
	margin: 0;
	padding: 15px;
	height: 100vh;
	background-image: url(/assets/login-bg-compressed.png);
	background-size: cover;
	background-repeat: no-repeat;
}

.login .form-control {
}

.login .signupSheet {
	position: absolute;
	background-color: #fff;
	right: 0;
}

.navbar .navbar-brand {
	margin-right: 100px !important;
}

.navbar .navbar-brand img {
	height: 30px;
}

.navbar .nav-link {
	font-size: 16px !important;
	color: #ffffff60 !important;
	padding: 6px 16px !important;
}

.navbar .nav-link.active {
	color: #fff;
}

.navbar .nav-link:hover {
	color: #fff !important;
}

.navbar .nav-link .header-searh {
	display: inline-block;
	width: 0;
	background-color: transparent;
	border: none;
	z-index: 0;
	padding-right: 34px;
	transform: translateX(30px);
	transition: all 0.3s ease-in;
	color: #fff;
}

.navbar .nav-link:hover .header-searh,
.navbar .nav-link .header-searh:focus {
	border-bottom: 1px solid #fff;
	width: 250px;
}

.navbar .btn-nav-chat {
	background-color: transparent !important;
	border: none !important;
	color: #ffffff60 !important;
	padding: 0 !important;
	line-height: 1;
}

.navbar .dropdown-menu {
	left: auto !important;
	right: 0 !important;
	padding: 16px !important;
	border: none !important;
	box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1) !important;
	max-width: 12rem;
}

.navbar .dropdown .dropdown-item {
	color: #333 !important;
	padding: 0.6rem 0 !important;
	border-bottom: 1px solid #ddd !important;
	line-height: 1;
}

.navbar .dropdown .dropdown-item .fa {
	color: #999 !important;
	vertical-align: middle;
}

.navbar .dropdown .dropdown-item.active,
.navbar .dropdown .dropdown-item:hover {
	background-color: var(--blue-black) !important;
	color: #fff !important;
}

.navbar .dropdown .dropdown-item.active .fa {
	color: #fff !important;
}

.navbar .dropdown .dropdown-item:last-child {
	border: none !important;
}

.navbar .dropdown .dropdown-item:hover {
	background-color: #fff !important;
	color: var(--text-primary) !important;
}

.navbar .dropdown .dropdown-toggle {
	position: relative;
}

.navbar .dropdown .dropdown-toggle img {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin-right: 6px;
	vertical-align: middle;
}

.navbar .dropdown .dropdown-toggle::after {
	position: absolute !important;
	right: -8px;
	top: 45%;
}

.navbar .dropdown .dropdown-item {
	font-size: 15px;
}

.modal .modal-content {
	border-radius: 16px;
	height: 80%;
	min-height: 80vh;
	min-width: 40vh;
}

.modal .modal-header {
	font-size: 16px !important;
	font-weight: 600 !important;
	color: var(--text-primary) !important;
	border: none !important;
}

.modal .modal-header,
.modal .modal-body {
	padding: 1rem 2rem;
}

.card {
	border-radius: 8px;
}

.edit-profile {
	padding: 1rem;
}

.edit-profile .card {
	/* padding: 1rem !important;
	border: 1px solid #ddd !important;
	top: 0 !important; */
}

.edit-profile .postCard {
	padding: 0 1rem !important;
}

.edit-profile .postCard p {
	margin: 1rem 0 !important;
}

.profileCard-sticky-top {
	position: sticky !important;
	top: 80px !important;
}

.profileCard {
	/* padding: 1rem !important;
	border: 1px solid #ddd !important;
	position: sticky !important;
	top: 80px !important; */
}

.profileCard .progress-ring {
	position: absolute;
	left: -8px;
	top: -6px;
}

.profileCard .progress-ring__circle {
	transition: 0.35s stroke-dashoffset;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
}

.profileCard .userPic,
.userPic-modal {
	position: relative;
	width: 160px;
	height: 160px;
	margin: 0 auto 16px;
	border-radius: 50%;
	text-align: center;
	line-height: 160px;
}

.profileCard .userPic img,
.userPic-modal img {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	object-fit: cover;
}

.profileCard .userPic .btn,
.userPic-modal .btn {
	/* position: absolute;
	bottom: 16px;
	left: calc(50% - 15px);
	background-color: transparent !important;
	border: none !important;
	width: 30px;
	height: 30px;
	border-radius: 50% !important;
	line-height: 30px !important;
	padding: 0 !important;
	color: #fff;
	cursor: pointer;
	font-size: 22px !important; */
}

.profileCard .userPic .btn input,
.userPic-modal .btn input {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	cursor: pointer;
}

.profileCard h3 {
	font-size: 18px;
	color: var(--text-primary);
	padding: 0;
	margin: 0 0 30px;
	text-align: center;
	border: none !important;
}

.profileCard h3 small {
	display: block;
	width: 100%;
	margin-top: 4px;
	text-align: center;
	color: #15161eda;
}

.profileCard .fa-ul {
	margin: 0 0 0.1rem 1.5rem;
}

.profileCard .fa-li {
	font-size: 14px;
	top: 3px;
}

.profileCard li {
	font-size: 15px;
	margin-bottom: 4px;
}

.profileCard .fa-check {
	width: 16px;
	height: 16px;
	line-height: 14px;
	font-size: 8px;
	text-align: center;
	border-radius: 50%;
	color: var(--green);
	border: 1px solid var(--green);
}

.profileCard hr {
	margin: 6px 0;
}

.edit-profile h5 {
	font-size: 16px;
	font-weight: 600;
	padding: 0;
	color: var(--text-primary) !important;
	text-transform: initial !important;
	margin: 0 0 6px;
	text-align: left !important;
}

.edit-profile .accordion .btn-link:hover,
.edit-profile .accordion .btn-link:focus {
	text-decoration: none !important;
}

.edit-profile .profileCard p {
	height: 90px;
	overflow: hidden auto;
	margin: 0 -8px 0 0;
	scrollbar-color: var(--orange) #ddd;
	scrollbar-width: thin;
}

.profileCard p::-webkit-scrollbar {
	width: 5px;
}

/* Track */
.profileCard p::-webkit-scrollbar-track {
	background: #ddd;
}

/* Handle */
.profileCard p::-webkit-scrollbar-thumb {
	background: var(--orange);
}

/* Handle on hover */
.profileCard p::-webkit-scrollbar-thumb:hover {
	background: var(--orange);
}

.edit-profile .fixedButtons {
	position: sticky;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 10px;
	background-color: #fff;
	text-align: center;
	/* box-shadow: 0px -1px 1px 1px rgba(0, 0, 0, .1); */
	z-index: 999;
}

.edit-profile h3 {
	font-size: 18px;
	color: var(--text-primary);
	padding: 0 0 10px;
	margin: 60px 0 30px;
	border-bottom: 1px solid #ddd;
}

.edit-profile h3::after {
	content: '';
	display: block;
	clear: both;
}

.edit-profile h2,
.edit-profile h3 {
	font-size: 16px;
	color: #15161e;
	padding: 0 0 10px;
	/* margin: 30px 0; */
	border-bottom: 1px solid #ddd;
}

.edit-profile h3 {
	margin: 30px 0 16px;
}

.edit-profile h3 .fa {
	transition: all 0.3s;
}

.edit-profile h3[aria-expanded='false'] .fa {
	transform: rotate(-180deg);
}

.no-data {
	padding: 60px 30px;
	text-align: center;
	font-size: 20px;
	color: #15161eb4;
	background-color: #ddd;
}

.no-wrap {
	white-space: nowrap !important;
}

.edit-profile .custom-file {
	width: 200px;
}

.edit-profile .custom-file .custom-file-label::after {
	display: none;
}

.edit-profile .nav {
	border-bottom: 1px solid #ddd;
	background-color: #fff;
	position: sticky;
	top: 58px;
	left: 0;
	right: 0;
	z-index: 900;
}

.edit-profile .nav-link {
	position: relative;
	font-size: 16px;
	color: #15161e60;
	font-weight: 400;
	padding: 0.5rem 0.6rem;
	cursor: pointer;
}

.edit-profile .nav-link.active {
	color: var(--orange);
}

.edit-profile .nav-link.active::after {
	content: '';
	display: block;
	width: 100%;
	height: 3px;
	border-radius: 3px;
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: var(--orange);
}

.scrollNav {
	flex-wrap: nowrap;
	overflow-x: auto;
	/* flex-direction: column; */
	background: #f2f2f2;
	text-align: center;
}

.scrollNav::-webkit-scrollbar {
	height: 5px;
	width: 0px;
}

/* Track */
.scrollNav::-webkit-scrollbar-track {
	background: #ddd;
}

/* Handle */
.scrollNav::-webkit-scrollbar-thumb {
	background: var(--orange);
}

/* Handle on hover */
.scrollNav::-webkit-scrollbar-thumb:hover {
	background: var(--orange);
}

.scrollNav .nav-item {
	flex: auto;
}

.connection-card,
.edit-profile .profile-data-card {
	padding: 1.8rem !important;
	border: 1px solid #ddd !important;
	margin: 0 0 16px !important;
	position: relative;
	z-index: 0;
}

.companyProfileDataCard .profile-data-card {
	padding: 1rem !important;
}

.connection-card {
	padding: 1rem !important;
}

/* .edit-profile .profile-data-card .card-icon {
	width: 60px;
	height: 60px;
	background-color: #ddd;
	color: #15161E30;
	font-size: 30px;
	line-height: 60px;
	text-align: center;
	margin-right: 16px;
} */

.connection-card .userPic {
	display: inline-block;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 10px;
}

.connection-card h2,
.edit-profile .profile-data-card h2 {
	font-size: 18px;
	color: var(--text-primary);
	margin: 0;
	padding: 0;
	border: 0;
	flex: 1 0 auto;
	text-align: left;
}

.connection-card h2 label,
.edit-profile .profile-data-card h2 label {
	display: block;
	width: 100%;
	font-size: 16px;
	font-weight: 400;
	margin: 6px 3px;
}

.connection-card h2 small,
.connection-card h2 label {
	height: 17px;
}

.connection-card h2 small,.italic small,
.edit-profile .profile-data-card h2 small {
	display: block;
	width: 100%;
	color: #15161ECC;
	font-size: 15px;
}
.italic small {
	color: #15161E99 !important;
}

.connection-card .btn-addFrd {
	position: absolute;
	top: 3px;
	right: 66px;
}

.connection-card .dropdown {
	position: absolute !important;
	top: -3px !important;
	right: 30px !important;
}

.connection-card .dropdown button {
	/* position: absolute !important;
	padding: 10px !important;
	height: auto !important;
	margin: 0 !important;
	background-color: transparent !important;
	color: var(--text-primary) !important;
	border: none !important; */
}

.connection-card .dropdown button::after {
	display: none;
}

.connection-card .dropdown .dropdown-menu {
	/* padding: 0 !important; */
	border: none !important;
	box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
}

.connection-card .dropdown .dropdown-menu::before {
	content: '';
	display: block;
}

.connection-card .dropdown .dropdown-menu .dropdown-item {
	/* padding: 6px 12px !important; */
	border-bottom: 1px solid #ddd;
	vertical-align: middle;
}

.connection-card .dropdown .dropdown-menu .dropdown-item::after {
	content: '';
	display: block;
	clear: both;
}

.connection-card .dropdown .dropdown-menu .dropdown-item .fa {
	vertical-align: middle;
}

.connection-card .card-check {
	position: absolute;
	left: 30px;
	top: 3px;
}

.connection-card .card-check .custom-control {
	padding: 0;
}

.connection-card .request-btns {
	position: absolute;
	top: 3px;
	right: 10px;
}

.connection-card .btn-unbl {
	margin: 16px -16px 0;
	border-top: 1px solid #ddd !important;
}

.connection-card .fInfo {
	font-size: 14px;
	color: #15161ead;
}

.connection-card .fInfo .fa {
	margin-right: 6px;
	color: #15161e60;
}

.edit-profile .profile-data-card p {
	font-size: 15px;
	margin: 16px 0 0;
	color: #15161eda;
}

.companyProfileDataCard .prdCard {
	position: relative;
	padding: 1rem;
	margin: 0 0 16px;
	border: 1px solid #ddd;
	border-radius: 8px;
}

.companyProfileDataCard .prdCard:last-child {
	margin: 0;
}

.companyProfileDataCard .prdCard .companyPic {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
}
.companyProfileDataCard .prdCard h4 {
	font-size: 16px;
	padding: 0;
	margin: 0 0 6px;
	font-weight: 500;
	color: var(--text-primary);
}

.companyProfileDataCard .prdCard p {
	font-size: 16px;
	margin: 0;
	color: #15161ed2;
}
.companyProfileDataCard .prdCard .btns {
	position: absolute;
	right: 5px;
	top: 5px;
}

.edit-profile .jobLoc label {
	font-size: 16px;
	color: #15161e8f;
	font-weight: 600;
	vertical-align: middle;
}

.badge-secondary,
.edit-profile .jobLoc span {
	display: inline-block !important;
	font-size: 14px;
	padding: 6px 14px !important;
	background-color: #ddd !important;
	border-radius: 8px !important;
	font-weight: 400 !important;
	color: var(--text-primary) !important;
}

.badge-secondarys,
.edit-profile .jobLoc span {
	display: inline-block !important;
	font-size: 14px;
	padding: 6px 14px !important;
	background-color: #ddd !important;
	border-radius: 12px 0px 0px 12px !important;
	font-weight: 400 !important;
	color: var(--text-primary) !important;
}
.global-modal .modal-body {
	padding: 0 !important;
}
.global-modal .modal-content {
	width: fit-content !important;
	padding: 30px !important;
}
.global-modal .modal-dialog {
	display: flex;
	justify-content: center;
}
.global-modal .fa-times,.global-modal .fa-check,.global-modal .fa-question {
	font-size: 25px;
	display: block;
	width: 80px;
	height: 80px;
	line-height: 80px;
	border-radius: 50%;
	text-align: center;
	margin: 0 auto;
}
.global-modal .fa-times{
	background-color: #fbded4;
	color: #ef7755;
}
.global-modal .fa-check {
	background-color: rgba(109, 208, 62, 0.2);
	color: #68cb39;
}
.global-modal .fa-question {
	background-color: #707070;
	color: #fff;
}
.global-modal .error-heading {
	font-size: 18px;
	margin-bottom: 20px;
	color: #EF7755;
	text-align: center;
  }
  
  .global-modal .modal-title {
	color: #15161ECC;
	text-align: center;
  }
  
  .global-modal .modal-title.success,.modal-title.warning {
	font-size: 20px;
	margin-top: 32px;
  }
  .global-modal .modal-title.error {
	font-size: 14px;
	margin-top: 15px;
	margin-bottom: 25px;
	margin-inline: 20px;
  }
  
  .global-modal .modal-button {
	min-width: unset;
	width: fit-content;
  }
  
.save-modal .fa-check,
.save-modal .fa-exclamation,
.save-modal .fa-times {
	display: block;
	width: 60px;
	height: 60px;
	line-height: 60px;
	border-radius: 50%;
	background-color: #6dd03e33;
	color: #68cb39;
	text-align: center;
	margin: 0 auto;
}

.save-modal h2 {
	font-size: 20px;
	padding: 30px;
	text-align: center;
	font-weight: 600;
	color: var(--text-primary);
	margin: 30px 0 0;
}

.mutualPics {
	padding: 16px 0;
	font-size: 12px;
	color: var(--text-primary);
	font-weight: 500;
}

.mutualPics img {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	object-fit: cover;
	vertical-align: middle;
}

.mutualPics img:not(:first-child) {
	margin-left: -20px;
	box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
}

.mutualPics img:last-child {
	margin-right: 6px;
}

.pointer {
	cursor: pointer;
}

.recomment {
	padding: 1rem;
}

.recomment h1 {
	font-size: 22px;
	font-weight: 400;
	color: var(--orange);
	padding: 0;
	margin: 0 0 30px;
}

.recomment h1 span {
	display: inline-block;
	padding: 6px;
	/* border-bottom: 2px solid var(--orange); */
}

.recomment .form-group .btn-white {
	border-color: #ddd !important;
}

.secondary-nav {
	background-color: #f4f4f4;
}

.secondary-nav .row {
	margin: 0 -15px;
}

.secondary-nav nav {
	margin: 0 -15px;
}

.secondary-nav .nav .nav-item .nav-link {
	font-size: 16px;
	font-weight: 600;
	color: #15161ebb;
	padding: 0.4rem 2rem;
}

.secondary-nav .nav .nav-item .nav-link.active,
.secondary-nav .nav .nav-item .nav-link:hover {
	color: var(--orange);
}

.secondary-nav select {
	background-color: transparent;
	border: 0;
	border-bottom: 1px solid #ddd;
	border-radius: 0;
}

.secondary-nav .btn-white {
	background-color: transparent !important;
	border-color: #ddd !important;
}

.secondary-nav .btn-white:hover {
	background-color: var(--text-primary) !important;
}

.input-group .btn-white {
	border-color: #ddd !important;
}

.filter-Dropdown .dropdown-menu {
	padding: 0;
	border: none;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
	min-width: 15rem;
	overflow: visible;
	margin-top: 9px;
}

.filter-Dropdown .dropdown-menu::before {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-bottom: 10px solid #fff;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	position: absolute;
	right: 10px;
	top: -9px;
}

.filter-Dropdown .dropdown-menu .scroll-y {
	padding: 1rem;
	max-height: 60vh;
	overflow-y: auto;
	scrollbar-color: var(--orange) #ddd;
	scrollbar-width: thin;
}

.filter-Dropdown .dropdown-menu label:not(.custom-control-label) {
	font-size: 14px;
	color: #15161e90;
	font-weight: 500;
	line-height: 1;
	margin-bottom: 10px;
}

.filter-Dropdown .dropdown-menu .custom-control {
	margin-bottom: 6px;
}

.filter-Dropdown .dropdown-menu .btn {
	font-size: 12px;
	padding: 0.4rem 1.5rem;
}

.filter-Dropdown .scroll-y::-webkit-scrollbar {
	width: 5px;
}

/* Track */
.filter-Dropdown .scroll-y::-webkit-scrollbar-track {
	background: #ddd;
}

/* Handle */
.filter-Dropdown .scroll-y::-webkit-scrollbar-thumb {
	background: var(--orange);
}

/* Handle on hover */
.filter-Dropdown .scroll-y::-webkit-scrollbar-thumb:hover {
	background: var(--orange);
}

.scroll-y {
	scrollbar-width: thin;
	border-radius: 4px;
}

.scroll-y::-webkit-scrollbar {
	border-radius: 4px;
	width: 5px;
}

/* Track */
.scroll-y::-webkit-scrollbar-track {
	border-radius: 4px;
	background: #ddd;
}

/* Handle */
.scroll-y::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--orange);
}

/* Handle on hover */
.scroll-y::-webkit-scrollbar-thumb:hover {
	background: var(--orange);
}

.noUi-target {
	height: 5px !important;
	background-color: #ddd !important;
	border-radius: 5px !important;
	box-shadow: none !important;
	border: none !important;
}

.noUi-horizontal .noUi-handle {
	width: 15px !important;
	height: 15px !important;
	border-radius: 10px !important;
	background-color: var(--orange) !important;
	box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1) !important;
	border: none !important;
}

.noUi-horizontal .noUi-handle:focus,
.noUi-horizontal .noUi-handle::after,
.noUi-horizontal .noUi-handle::before {
	outline: none !important;
	display: none !important;
}

.filterTags span {
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	color: #15161ea9;
	padding: 6px 10px;
	border: 1px solid #ddd;
	border-radius: 30px;
}

.filterTags span .fa {
	width: 15px;
	height: 15px;
	text-align: center;
	color: var(--text-primary);
	opacity: 0.4;
	cursor: pointer;
	margin-left: 6px;
}

.postCard {
	display: block !important;
	padding: 0 1rem;
	border: none;
	margin: 0 0 16px !important;
	box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	/* min-height: 380px; */
}

.bonusPost::before {
	content: '';
	display: block;
	width: 100%;
	height: 32px;
	background-color: #dddddd;
	padding: 20px;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 0;
}

.badge-green {
	display: inline-block;
	font-size: 16px !important;
	padding: 4px 10px;
	background-color: #1ede7880;
	color: var(--text-primary) !important;
	font-weight: 400;
	border-radius: 20px;
}

.postCard a:hover {
	text-decoration: none;
	color: inherit;
}

.postCard .companyPic {
	display: inline-block;
	width: 54px;
	height: 54px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 16px;
}

.postCard h2 {
	font-size: 18px;
	color: var(--text-primary);
	margin: 0;
	padding: 0;
	border: 0;
	flex: 1;
}

.postCard h2 small {
	display: block;
	width: 100%;
	color: #15161ea9;
	font-size: 15px;
}

.postCard h2 label {
	display: block;
	width: 100%;
	font-size: 16px;
	font-weight: 400;
	margin: 6px 3px;
}

.postCard h2 .fa {
	width: 40px;
	height: 40px;
	line-height: 40px;
	background-color: #ddd;
	color: #fff;
	text-align: center;
	font-size: 18px;
	margin: 0 10px 0 0;
	border-radius: 50%;
	flex: 1 0 auto;
}

/* .postCard .dropdown button {
	height: auto !important;
	margin: 0 !important;
	background-color: transparent !important;
	color: #15161E60 !important;
	border: none !important;
} */

/* .postCard .dropdown button:not(.btn-relative) {
	padding: 10px !important;
	position: absolute !important;
	top: -30px !important;
	right: -10px !important;
} */

/* .postCard .dropdown button::after {
	display: none;
}

.postCard .dropdown .dropdown-menu {
	padding: 0 !important;
	border: none !important;
	box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, .1);
}

.postCard .dropdown .dropdown-menu::before {
	content: '';
	display: block;

}

.postCard .dropdown .dropdown-menu .dropdown-item {
	padding: 6px 12px !important;
	border-bottom: 1px solid #ddd;
	vertical-align: middle;
}

.postCard .dropdown .dropdown-menu .dropdown-item:last-child {
	border: none !important;
}

.postCard .dropdown .dropdown-menu .dropdown-item::after {
	content: '';
	display: block;
	clear: both;
}

.postCard .dropdown .dropdown-menu .dropdown-item .fa {
	vertical-align: middle;
} */

.postCard p {
	font-size: 15px;
	color: #15161ec2;
	line-height: 20px;
	height: 40px;
	overflow: hidden;
	margin: 1rem 0;
}

.postCard .fInfo {
	font-size: 14px;
	color: #15161ead;
}

.postCard .fInfo .fa {
	margin-right: 6px;
	color: #15161e60;
}

.postCard span {
	font-size: 12px;
	color: #15161e60;
}

.postCard span .badge {
	display: inline-block;
	padding: 3px 10px;
	border: 1px solid var(--green);
	color: var(--text-primary);
	font-size: 16px;
	border-radius: 30px;
	font-weight: 400;
}

.postCard .btn-white {
	/* padding: .4rem 0.6rem !important; */
	/* width: 100% !important; */
	/* border: none !important; */
	/* border-top: 1px solid #ddd !important; */
	/* border-right: 1px solid #ddd !important; */
	/* border-radius: 0px !important; */
}

.postCard .col:last-child .btn-white {
	border-right: 0 !important;
}

.postCard .large-btns .btn {
	width: auto !important;
	border: 1px solid #ddd !important;
}

.postCard .strip {
	position: relative;
	display: inline-block;
	/* background-color: #FAFAFA; */
	padding: 6px 10px 6px 6px;
	font-size: 14px;
	color: var(--text-primary);
	margin: 0 0 0 -9px;
	z-index: 100;
}

.postCard h2 div:last-child {
	flex: 1 0 auto;
	width: 100%;
}

.postCard .strip .fa {
	margin-right: 4px;
}

/* .postCard .strip::after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-right: 10px solid #fff;
	border-top: 14px solid transparent;
	border-bottom: 14px solid transparent;
	position: absolute;
	top: 0;
	right: 0;
}
.postCard .strip::before {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-left: 20px solid #ababab;
	border-top: 0px solid transparent;
	border-bottom: 15px solid transparent;
	position: absolute;
	top: -5px;
	left: 2px;
	z-index: -1;
	transform: rotate(-15deg);
} */

.postCard .strip .fa-ticket-alt {
	color: var(--orange);
}

.postCard .status {
	display: inline-block;
	background-color: #ddd;
	margin: 6px 0;
	text-transform: initial;
	color: var(--text-primary);
	font-size: 12px;
	padding: 6px 16px;
	border-radius: 30px;
}

.connection-card .btn-track,
.postCard .btn-track {
	position: absolute;
	right: 10px;
	top: 9px;
	background-color: transparent;
	border: none;
	padding: 0;
}

.connection-card .btn-track:focus,
.postCard .btn-track:focus,
.connection-card .btn-track:hover,
.postCard .btn-track:hover,
.connection-card .btn-track:active,
.postCard .btn-track:active,
.connection-card .btn-track:not(:disabled):not(.disabled):active,
.postCard .btn-track:not(:disabled):not(.disabled):active {
	/* background-color: #fff; */
	/* transition: top .2s; */
	border: none;
}

.connection-card .btn-track.position-relative,
.postCard .btn-track.position-relative {
	top: 0;
	right: 0;
	vertical-align: middle;
}

.response-card .btn-track.position-relative {
	top: 0;
	right: 0;
	vertical-align: middle;
}

.connection-card .btn-track .no,
.postCard .btn-track .no {
	color: var(--text-primary);
}

.connection-card .btn-track .no svg,
.postCard .btn-track .no svg {
	position: absolute;
	top: -3px;
	left: -3px;
}

.response-card .btn-track .no svg {
	position: absolute;
	top: -3px;
	left: -3px;
}

.postCard .time-abv {
	position: absolute;
	right: 40px;
	top: 10px;
}

.postCard .hiredOther {
	margin: 0 -1rem;
	background-color: #1ede7856;
	padding: 6px 16px;
	text-align: center;
	color: var(--text-primary);
	font-size: 14px;
}

.postCard .resReq {
	padding: 0.6rem;
	text-align: center;
	margin: 0 -1rem;
	color: var(--text-primary);
	font-size: 14px;
	color: var(--text-primary);
	background-color: #ddd;
}

.postCard .referred-by {
	position: relative;
}

.postCard .referred-by .companyPic {
	width: 40px;
	height: 40px;
}

.postCard .referred-by label {
	font-size: 14px;
	color: #15161ead;
	display: block;
	margin: 0 0;
}

.postCard .referred-by h2 {
	font-size: 14px;
}

.postCard .referred-by .btn-track {
	position: relative;
	top: 0;
	right: 0;
}

.postCard .carousel-control-prev-icon,
.postCard .carousel-control-next-icon {
	background-image: none;
	width: 8%;
}

.postCard .carousel-control-prev {
	background: none;
	left: -32px;
	top: 30%;
	bottom: auto;
}

.postCard .carousel-control-next {
	background: none;
	right: -32px;
	left: auto;
	top: 30%;
	bottom: auto;
}

.postCard .carousel-control-prev-icon::after {
	content: '\f053';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	color: #15161e;
	font-size: 18px;
}

.postCard .carousel-control-next-icon::after {
	content: '\f054';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	color: #15161e;
	font-size: 18px;
}

.postCard .postStatus {
	margin: -9px -9px 8px;
	background-color: #ddd;
	padding: 8px 8px;
}

.postCard .postStatus .companyPic {
	width: 30px;
	height: 30px;
	margin: 0px 3px 0 6px;
}

.pl-5 {
	padding-left: 5rem !important;
}

.subCard {
	padding: 1rem !important;
	margin: 0 0 6px !important;
	border: 1px solid #ddd !important;
}

.subCard:last-child {
	margin: 0 !important;
}
.subCard .btns {
	position: absolute;
	top: 5px;
	right: 5px;
}

.subCard .pic {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
}
.subCard h2 {
	margin: 0 0;
	border: 0;
}

.bg-light {
	position: relative;
	background-color: #f5f5f5 !important;
	z-index: 0;
}

.bg-orange-light {
	background-color: #ffe1d5;
}

.bg-transparent {
	background-color: transparent !important;
}

.wall h1 {
	font-size: 22px;
	color: var(--text-primary);
	margin: 0 0 30px;
	padding: 0;
}

.chart .score-card {
	position: relative;
	border: 1px solid #ecd0c5;
	border-radius: 4px;
	padding: 8px !important;
}

.chart .score-card span {
	display: block;
	width: 100%;
	font-size: 20px;
	font-weight: 700;
	color: var(--orange);
	padding: 0;
	margin: 8px 0 8px;
	text-align: right;
}

.chart .score-card label {
	display: block;
	margin: 0;
	font-size: 14px;
	color: #15161ed2;
	text-align: center;
}

.chart .score-card .fa {
	font-size: 40px;
	position: absolute;
	top: 8px;
	left: 8px;
	color: #ffbfa6;
}

.chart .card {
	padding: 1rem;
	border: 1px solid #ddd;
	height: 100%;
}

.chart h2 {
	font-size: 18px;
	color: var(--text-primary);
	margin: 0;
	padding: 0;
}

.chart h3 {
	font-size: 16px;
	color: var(--text-primary);
	font-weight: 600;
	margin: 0 0 16px;
	padding: 0;
}

.chart .card .recharts-wrapper {
	margin: 0 auto;
}

.chart .card .recharts-wrapper .recharts-legend-item span {
	vertical-align: middle;
}

.chart .card .recharts-wrapper .recharts-default-tooltip {
	background-color: var(--text-primary) !important;
	border: none !important;
	color: #fff;
	border-radius: 4px;
}

.chart .card .recharts-wrapper .recharts-default-tooltip li {
	padding: 0 !important;
}

.chart .card .recharts-wrapper .recharts-default-tooltip span {
	color: #fff;
	font-size: 14px;
}

.chart .card .table {
	border-color: #dfc5ba;
}

.chart .card .table th {
	font-size: 12px;
	border-color: #ddd !important;
	background-color: #f4f4f4;
	color: var(--text-primary);
	font-weight: 400;
	padding: 0.5rem;
}

.chart .card .table td {
	border: 1px solid #ddd !important;
	font-size: 14px;
	padding: 0.5rem !important;
	font-weight: 400;
	color: var(--text-primary);
}

.chart .card .userCard {
	background-color: #fff;
	padding: 0 1rem;
	height: auto;
}

.chart .card .userCard img {
	display: block;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	object-fit: cover;
	margin: 0 auto 0;
}

.chart .card .userCard label {
	display: block;
	font-size: 16px;
	font-weight: 400;
	color: var(--text-primary);
	margin: 12px 0 0;
	text-align: center;
}

.chart .card .userCard .bg-light {
	position: relative;
}

.chart .card .userCard .bg-light::after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-left: 10px solid #fff;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	position: absolute;
	top: calc(50% - 5px);
	left: 0;
}

.chart .card .userCard .bg-light .tile {
	display: flex;
	border-bottom: 1px dashed #ddd;
	padding: 6px 0;
	font-size: 12px;
	font-weight: 300;
	color: var(--text-primary);
}

.chart .card .userCard .bg-light .tile:last-child {
	border-bottom: 0;
}

.chart .card .userCard .bg-light .text-bold {
	font-size: 14px;
	font-weight: 400;
	color: var(--text-primary);
}

.apply-modal .card {
	/* max-height: 25vh; */
	border: 1px solid #ddd;
	/* overflow-y: auto; */
}

.apply-modal .card .refered-list {
	padding: 16px;
	overflow-y: auto;
	border-bottom: 1px dashed #ddd;
	display: flex;
	align-items: center;
}

.apply-modal .card .refered-list:last-child {
	border: none;
}

.apply-modal .card .refered-list .item {
	display: inline-block;
	position: relative;
	margin: 0;
	text-align: center;
	padding: 1rem 0;
}

.apply-modal .card .refered-list .item .fa-check,
.apply-modal .card .refered-list .item .fa-clock {
	color: var(--green);
	font-size: 14px;
	position: absolute;
	top: 0px;
	left: calc(50% - 7.5px);
}
.apply-modal .card .refered-list .item .fa-clock {
	color: black;
}
.apply-modal .card .refered-list .item img {
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
	margin: 0 auto;
}

.apply-modal .card .refered-list .item label {
	font-size: 14px;
	color: var(--text-primary);
	margin: 6px 0 0 !important;
}

.apply-modal .card .refered-list .item small {
	display: block;
	color: #15161ed2;
	line-height: 16px;
}

.apply-modal .card .refered-list .item.arrow {
	vertical-align: text-top;
	padding: 0 4px;
}

.apply-modal .card .refered-list .item.arrow img {
	width: 80px;
	height: 15px;
}

.apply-modal .card .refered-list .item:nth-child(even) {
	min-width: 100px;
}

.apply-modal .sub-p {
	font-weight: bold;
	color: var(--text-primary);
	text-align: center;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	margin-left: -30px;
	margin-right: -30px;
}

.text-orange {
	color: var(--orange) !important;
}

.text-green {
	color: var(--green) !important;
}

.text-primary {
	color: var(--text-primary) !important;
}

.text-yellow {
	color: var(--yellow) !important;
}

.apply-modal .deg {
	padding: 16px;
	margin: 30px -32px;
	border-top: 1px solid #333;
	border-bottom: 1px solid #333;
}

.apply-modal .deg .item {
	position: relative;
	margin: 0;
	text-align: center;
	padding: 0 0.5rem;
}

.apply-modal .no svg {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
}

.apply-modal .deg .item .dash {
	width: 100%;
	height: 2px;
	background-color: #ddd;
	margin-top: 16px;
}

.apply-modal .no {
	width: 30px;
	height: 30px;
	position: relative;
	font-size: 18px;
	font-weight: 600;
	color: var(--orange);
	text-align: center;
	line-height: 30px;
	margin: 0 auto;
}

.apply-modal .deg .item label {
	font-size: 14px;
	color: var(--text-primary);
	display: block;
	margin: 10px 0 0;
	font-weight: 400;
	white-space: nowrap;
}

.apply-modal h1 {
	font-size: 18px;
	color: var(--text-primary);
	padding: 0;
	margin: 30px 0 16px;
}

.apply-modal .btn-collapse {
	background-color: transparent !important;
	border: none !important;
	padding: 0.4rem 2rem 0.4rem 1rem !important;
	font-size: 16px !important;
	color: var(--text-primary) !important;
	text-transform: none !important;
	text-align: left !important;
	border-radius: 0 !important;
	min-height: unset !important;
	position: relative;
}

.apply-modal .btn-collapse:not(:first-child) {
	border-top: 1px solid #ddd !important;
}

.apply-modal .btn-collapse:after {
	content: '\f078';
	display: block;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	color: #15161ed2;
	position: absolute;
	right: 10px;
	top: 15px;
	font-size: 12px;
	transition: all 0.4s ease-in-out;
}

.apply-modal .btn-collapse[aria-expanded='true']:after {
	transform: rotate(180deg);
}

.apply-modal .btn-collapse img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 10px;
}

.apply-modal .btn-collapse label {
	font-size: 12px;
	color: #15161ead;
	margin: 0;
}

.apply-modal .btn-collapse label span {
	display: inline-block;
	padding: 3px 12px;
	font-size: 14px;
	border: 1px solid var(--green);
	border-radius: 20px;
	margin-right: 4px;
}

.apply-modal .collapse {
	border-top: 1px solid #ddd;
}

.apply-modal .refered-list {
	padding: 6px 16px;
}

.apply-modal .collapse .refered-list .item {
	padding: 1rem 0 0;
}

/* .fixed {
	position: fixed ;
} */

.custom-tabs .nav {
	flex-wrap: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	padding-left: 1px;
	scrollbar-color: var(--orange) #ddd;
	scrollbar-width: thin;
}

.custom-tabs .nav-item {
	margin-left: -1px;
}

.custom-tabs .nav-item .nav-link {
	position: relative;
	font-size: 14px;
	font-weight: 400;
	color: var(--text-primary);
	padding: 8px 16px;
	border: none;
	border-bottom: 1px solid #ddd;
	border-radius: 0;
	white-space: nowrap;
}

.connections .nav {
	border-bottom: 1px solid #ddd;
}

.connections .nav-item .nav-link {
	border: none;
}

.custom-tabs .nav-item .nav-link:focus {
	outline: none;
	box-shadow: none;
}

.custom-tabs .nav-item .nav-link.active {
	color: var(--orange);
	background-color: #fff;
}

.custom-tabs .nav-item .nav-link.active::after {
	content: '';
	display: block;
	width: 100%;
	height: 3px;
	border-radius: 3px;
	background-color: var(--orange);
	position: absolute;
	left: 0;
	bottom: -1.5px;
}

.vertical-nav .nav-item .nav-link {
	position: relative;
	font-size: 14px;
	font-weight: 400;
	color: var(--text-primary);
	padding: 10px 30px;
	border: none;
	border-bottom: 1px dashed #ddd;
	border-radius: 0;
	text-align: left;
	white-space: nowrap;
}

.vertical-nav .nav {
	flex-wrap: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	padding-left: 1px;
	scrollbar-color: var(--orange) #ddd;
	scrollbar-width: thin;
}

.vertical-nav .nav-item {
	margin-left: -1px;
}

.vertical-nav .nav-item .nav-link.active::after {
	content: '';
	display: block;
	width: 4px;
	height: 100%;
	border-radius: 3px;
	background-color: var(--orange);
	position: absolute;
	left: 0;
	bottom: 0px;
}

.vertical-nav .nav-item .nav-link:focus {
	outline: none;
	box-shadow: none;
}

.vertical-nav .nav-item .nav-link.active {
	color: var(--orange);
	background-color: #fff;
}
.filter-tabs .nav-item .nav-link {
	border: 1px solid #ddd;
	border-radius: 4px 4px 0;
	color: var(--text-primary);
}
.filter-tabs .nav-item .nav-link.active {
	background-color: #ddd;
	color: var(--text-primary);
}

.secondary-nav .custom-tabs .nav-item .nav-link {
	border: 0;
}

.secondary-nav .custom-tabs .nav-item .nav-link.active {
	background-color: transparent;
}

.secondary-nav .custom-tabs .nav-item .nav-link.active::after {
	display: none;
}

.inviteModal .emailList .item,
.mutualConnectionModal .UserList .item,
.remark-modal .UserList .item {
	padding: 6px 0;
}

.inviteModal .emailList .item:not(:last-child),
.mutualConnectionModal .UserList .item.item:not(:last-child),
.remark-modal .UserList .item.item:not(:last-child) {
	border-bottom: 1px dashed #ddd;
}

.inviteModal .emailList .item span,
.mutualConnectionModal .UserList .item div,
.remark-modal .UserList .item div {
	flex: 1 1 auto;
}

.mutualConnectionModal .UserList .item .modal-check {
	flex: 0;
	margin-right: 16px;
}

.mutualConnectionModal .UserList .item button,
.inviteModal .emailList .item button {
	flex: 0 0 auto;
}

.mutualConnectionModal .UserList .item img,
.remark-modal .UserList .item img {
	flex: 0 0 40px;
	width: 40px;
	height: 40px;
	object-fit: cover;
	border-radius: 50%;
	margin-right: 10px;
}

.mutualConnectionModal .UserList .item div,
.remark-modal .UserList .item div {
	font-size: 16px;
	color: var(--text-primary);
}

.mutualConnectionModal .UserList .item div span,
.mutualConnectionModal .UserList .item div small,
.remark-modal .UserList .item div span,
.remark-modal .UserList .item div small {
	display: block;
	width: 100%;
	font-size: 14px;
	color: #15161ed3;
}

.mutualConnectionModal .UserList .item div small,
.remark-modal .UserList .item div small {
	color: #15161ead;
}

.border-dotted {
	border-style: dashed;
}

.border-yellow {
	border-color: var(--yellow) !important;
}

.po-relative {
	position: relative;
}

.table {
	border-radius: 16px;
}

.table thead {
	border: 1px solid #ddd;
}

.table thead th {
	/* border-bottom: 1px solid #ddd !important; */
	/* padding: 6px 0 !important; */
	font-size: 14px;
	font-weight: 500;
	color: var(--text-primary);
	white-space: nowrap;
}

.sortable-table thead th {
	padding: 0 !important;
}

.sortable-table thead th button {
	position: relative;
	border: none;
	background-color: transparent;
	padding: 6px 20px 6px 6px !important;
	box-shadow: none;
	text-align: left;
	font-size: 14px;
	font-weight: 500;
	color: var(--text-primary);
	white-space: nowrap;
	width: 100%;
}

.sortable-table thead th button:hover,
.sortable-table thead th button:focus {
	outline: none;
	border: none;
	box-shadow: none;
}

.sortable-table thead th button::after {
	content: '\f0dc';
	font-family: var(--fontawesome);
	font-weight: 800;
	font-size: 12px;
	color: var(--text-primary);
	position: absolute;
	right: 8px;
	top: 8px;
	opacity: 0.6;
}

.sortable-table thead th button.ascending::after {
	content: '\f0dd';
	top: 6px;
}

.sortable-table thead th button.descending::after {
	content: '\f0de';
	top: 10px;
}

.table tbody td {
	border: none;
	/* padding: 8px 0 !important; */
	color: var(--text-primary);
	font-size: 16px;
	word-break: break-all;
	height: 30px;
	display: table-cell;
}

.table tbody td .img-circle {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	object-fit: cover;
}

.table thead th .tile,
.table tbody td .tile {
	padding: 6px 16px;
	border-right: 1px solid #ddd;
	/* height: 100%; */
}

.table tbody td .tile {
	position: relative;
	box-shadow: 0px 4px 0px 0px #00000009, 0 -3px 0px 0px #00000009,
		12px 0 15px -4px #ffffff09, -12px 0 15px -4px #ffffff09;
	border: none;
}

.table tbody td:first-child {
	/* padding-left: 6px !important; */
}

.table tbody td:last-child {
	/* padding-right: 6px !important; */
}

.table tbody td:first-child .tile {
	box-shadow: 0px 4px 0px 0px #00000009, 0 -3px 0px 0px #00000009,
		12px 0 15px -4px #ffffff09, -3px 0 9px 0px #00000010;
	border: none;
}

.table tbody td:last-child .tile {
	box-shadow: 0px 4px 0px 0px #00000009, 0 -3px 0px 0px #00000009,
		3px 0 9px 0px #00000009, -12px 0 15px -4px #ffffff09;
	border: none;
}

.table tbody td .tile::after {
	content: '';
	display: block;
	width: 1px;
	height: 80%;
	position: absolute;
	right: 0;
	top: 10%;
	background-color: #ddd;
}

.table thead th:last-child .tile,
.table tbody td:last-child .tile {
	border-right: 0;
}

.table tbody td:last-child .tile::after {
	display: none;
}

.table tbody td .tile .user,
.table tbody td .tile.comp,
.table tbody td .tile.com {
	width: 200px;
}

.tile table td {
	border-right: 1px solid #ddd;
	padding: 0 16px !important;
}

.tile table td:last-child {
	border: none;
}

.tile table td.right-td {
	padding: 6px 16px 0px 16px !important;
	border-top: 1px solid #ddd;
}

.tile table td.right-td .badge-secondary {
	padding: 4px 10px !important;
	background-color: #fff !important;
	font-size: 14px;
	border: 1px solid #ddd !important;
	margin-right: 4px;
}

table td .td-rights {
	display: inline-block;
	padding: 0 6px;
	font-size: 14px;
	color: #15161ed2;
	border-right: 1px solid #ddd;
}

table td .td-rights:last-child {
	border-right: none;
}

.table tbody td .user img {
	width: 30px;
	height: 30px;
}

.table tbody td .user h2 {
	font-size: 16px;
	padding: 0 0 0 12px;
	margin: 0;
	flex: 1;
}

.table tbody td .user h2 span {
	font-size: 14px;
	color: #15161ed2;
}

.res-btn {
	position: fixed;
	right: 0;
	left: 0;
	bottom: 0;
	padding: 16px;
	text-align: center;
	background-color: #fff;
	z-index: 100;
	box-shadow: -3px 0px 3px 1px rgba(0, 0, 0, 0.1);
}

.comment .Upic {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
}

.comment .comment-reply .Upic {
	width: 30px;
	height: 30px;
}

.comment h3 {
	font-size: 16px;
	color: var(--text-primary);
	margin: 0 0 4px;
	padding: 0;
}

.comment p {
	color: #15161eaf;
}

.comment .info {
	font-size: 14px;
	color: #15161e7a;
}

.comment .fa-thumbs-up {
	cursor: pointer;
}

.comment .fa-thumbs-up.active {
	color: var(--orange);
}

.invite .calendar-btn {
	padding: 12px !important;
	border-radius: 0;
	color: var(--orange) !important;
	border: 0;
	background-color: #f4f4f4;
	position: absolute;
	right: 0;
	top: -16px;
	font-size: 18px !important;
}

.search h1 {
	position: relative;
	font-size: 22px;
	padding: 0;
	margin: 1.8rem 0 0.5rem;
	color: var(--text-primary);
}

.search h1 span {
	position: relative;
	display: inline-block;
	background-color: #fff;
	padding: 0 16px 0 0;
	z-index: 10;
}

.search h1::after {
	content: '';
	display: block;
	width: 100%;
	height: 1px;
	border-top: 1px dashed #ddd;
	position: absolute;
	left: 0;
	right: 0;
	top: 60%;
}

.google-btn {
	border: none;
	color: white;
	padding: 4px 4px;
	text-align: center;
	text-decoration: none;
	/* display: inline-block; */
	cursor: pointer;
}

.notification .dropdown-toggle::after {
	display: none;
}

.notification .dropdown-menu {
	min-width: 26rem;
}

.notification .dropdown-menu::before {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-right: 10px solid transparent;
	border-bottom: 10px solid #fff;
	border-left: 10px solid transparent;
	position: absolute;
	top: -9px;
	right: 2px;
}

.notification .dropdown-menu h2 {
	font-size: 16px;
	padding: 0 0 6px;
	margin: 0;
	border-bottom: 1px dashed #ddd;
	color: var(--text-primary);
}

.notification .dropdown-menu .note {
	padding: 16px 0;
	border-bottom: 1px dashed #ddd;
}

.notification .dropdown-menu .note:last-child {
	border-bottom: none;
}

.notification .dropdown-menu .note h3 {
	font-size: 16px;
	color: var(--text-primary);
	padding: 0;
	margin: 0 0 16px;
}

.notification .dropdown-menu .note .upic {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 16px;
}

.notification .dropdown-menu .note p {
	flex: 1;
	font-size: 15px;
	color: #15161ed2;
}

.notification .dropdown-menu .note label {
	display: block;
	width: 100%;
	font-size: 15px;
	color: #15161ead;
	margin: 0;
}

.voucher .voucher-stat .card {
	border: 1px solid #ddd;
	padding: 16px 30px;
	border-radius: 4px;
	box-shadow: none;
}

.voucher .voucher-stat .card h4 {
	font-size: 24px;
	color: var(--text-primary);
	padding: 0;
	margin: 0 0 6px;
	font-weight: 600;
}

.voucher .voucher-stat .card label {
	display: block;
	font-size: 15px;
	color: #15161eda;
	margin: 0;
}

@media only screen and (max-width: 767px) {
	.voucher .voucher-cat .col {
		flex: 0 0 50%;
		max-width: 50%;
	}
}

.voucher .voucher-cat .card {
	border: none;
	padding: 0;
	text-align: center;
	border-radius: 4px;
	overflow: hidden;
	cursor: pointer;
}

.voucher .voucher-cat .card img {
	width: 100%;
}

.voucher .voucher-cat .card label {
	cursor: pointer;
	position: absolute;
	margin: 0;
	line-height: 1;
	top: calc(50% - 8px);
	left: 16px;
	right: 16px;
	font-size: 16px;
	font-weight: 600;
	color: #fff;
	text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
	letter-spacing: 0.5px;
}

.voucher h1 {
	font-size: 18px;
	color: var(--text-primary);
	padding: 0;
	margin: 0;
}

.voucherCard {
	display: block !important;
	border-radius: 4px !important;
	padding: 1rem 1rem 0 !important;
	border: 1px solid #ddd !important;
	margin: 0 0 16px !important;
}

.voucherCard .companyPic {
	display: inline-block;
	width: 54px;
	height: 54px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 16px;
}

.voucherCard h2 {
	font-size: 18px;
	color: var(--text-primary);
	margin: 0;
	padding: 0;
	border: 0;
	flex: 1;
}

.voucherCard .fInfo {
	font-size: 14px;
	color: var(--text-primary);
}

.voucherCard .fInfo .fa {
	margin-right: 6px;
	color: var(--text-primary);
}

.voucherCard span {
	font-size: 14px;
	color: #15161e80;
}

.voucherCard .cart-btns {
	margin: 16px -16px 0;
	border-top: 1px solid #ddd;
	align-items: center;
}

.voucherCard .cart-btns .form-control {
	border: none !important;
	border-right: 1px solid #ddd !important;
	border-left: 1px solid #ddd !important;
}

.voucherCard .cart-btns .btn-secondary {
	padding: 11px 16px !important;
}

.voucher .table {
	border: 1px solid #ddd !important;
}

.voucher .table thead th {
	padding: 6px !important;
	border-bottom: 1px solid #000 !important;
}

.voucher .table tbody td {
	padding: 6px !important;
	border-top: 1px solid #ddd !important;
}

.voucher .table tfoot th {
	padding: 6px !important;
	border-top: 1px solid #000 !important;
}

.voucher .table tbody td .logo {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	object-fit: cover;
}

.voucher .cart-stat-card {
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: none;
	padding: 30px 16px;
	overflow: hidden;
}

.voucher .cart-stat-card h4 {
	font-size: 24px;
	color: var(--text-primary);
	font-weight: 600;
	padding: 0;
	margin: 0 0 16px;
	text-align: center;
}

.voucher .cart-stat-card label {
	display: block;
	margin: 0;
	text-align: center;
	font-size: 15px;
	color: #15161ed2;
}

.voucher .cart-stat-card .icon {
	font-size: 78px;
	line-height: 1;
	position: absolute;
	transform: rotate(-45deg);
	right: -20px;
	bottom: -20px;
	color: #f1682370;
	z-index: 0;
}

.wallet h1 {
	font-size: 18px;
	color: var(--text-primary);
	padding: 0;
	margin: 0;
}

.wallet .walletCard {
	border: 1px solid #ddd;
	padding: 0 16px 16px;
	height: 100%;
	overflow: hidden;
}

.wallet .walletCard h3 {
	font-size: 24px;
	color: var(--text-primary);
	margin: 0 0 38px;
	padding: 0;
}

.wallet .walletCard .wallet-stat {
	color: var(--text-primary);
	margin: 0 -15px 0;
	text-align: center;
	font-size: 14px;
}

.wallet .walletCard .wallet-stat .wallet-circ {
	font-size: 16px;
	font-weight: 600;
	width: 92px;
	height: 92px;
	margin: 0 auto;
	border: 3px solid var(--orange);
	border-radius: 50px;
	padding: 18px 0 0;
}

.wallet .walletCard .wallet-stat .wallet-circ .fa {
	color: var(--orange);
}

.wallet .walletCard label {
	font-size: 16px;
	color: var(--text-primary);
	display: block;
	font-weight: 400;
	margin: 0 -16px 16px;
	background-color: #ddd;
	text-align: center;
}

.wallet .walletCard label .btn {
	background-color: var(--orange);
	padding: 4.5px 6px;
	line-height: 1;
	border-radius: 8px 0 0 8px;
	color: #fff;
	border: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.wallet .bankCard label {
	font-size: 16px;
	color: var(--text-primary);
	display: block;
	font-weight: 400;
	margin: 0 -16px 16px;
	text-align: center;
	background-color: #ddd;
}

.wallet .walletCard .btns {
	margin: 16px -16px 0;
}

.wallet .bankCard {
	border: 1px solid #ddd;
	padding: 0 16px 16px;
	height: 100%;
	overflow: hidden;
}

.wallet .bankCard .item {
	position: relative;
	margin-bottom: 16px !important;
}

.wallet .bankCard .item img {
	display: inline-block;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 16px;
}

.wallet .bankCard .item h2 {
	font-size: 16px;
	color: var(--text-primary);
	margin: 0;
	padding: 0;
	border: 0;
	flex: 1;
}

.wallet .bankCard .item h2 label {
	display: block;
	margin: 4px 0 0;
	font-size: 16px;
	font-weight: 400;
	color: var(--text-primary);
}

.wallet .bankCard .item .btns {
	position: absolute;
	right: 0;
	top: 0;
	display: none;
}

.wallet .bankCard .item:hover .btns {
	display: block;
}

.wallet .table {
	/* border: 1px solid #ddd !important; */
}

.wallet .table thead th {
	/* border-bottom: 1px solid #000 !important; */
}

.wallet .table tbody td {
	/* padding: 6px !important; */
	/* border-top: 1px solid #ddd !important; */
	word-break: keep-all !important;
}

.switch {
	position: relative;
	display: flex;
	align-items: center;
}

.switch label {
	display: block;
	flex: 0;
	margin: 0;
}

.switch .toggler {
	position: relative;
	flex: 1;
	width: 40px;
	max-width: 40px;
	height: 20px;
	border-radius: 20px;
	margin: 0 6px;
}

.switch .toggler .toggler-bg {
	border-radius: 12px;
	border: 1px solid #ddd;
	background-color: #fff;
}

.switch .toggler input {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 10;
	cursor: pointer;
}

.switch .toggler .toggler-ico {
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background-color: #ddd;
	transition: all 0.3s;
	margin: 3px 0;
	transform: translateX(4px);
}

.switch .toggler input:checked ~ .toggler-bg {
	border: 1px solid #1ede78;
	background-color: #fff;
}

.switch .toggler input:checked ~ .toggler-bg .toggler-ico {
	transform: translateX(20px);
	transition: all 0.3s;
	background-color: #1ede78;
}

.btn-add.text {
	padding: 0 10px !important;
	margin-left: -4px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.btn-add {
	height: 33px !important;
}

.btn-add-amt {
	padding: 0 10px !important;
	height: 36px !important;
}

.post-dt .comment .list {
	border-bottom: 1px dashed #ddd;
	padding-bottom: 1rem;
}

.post-dt .comment .list:last-child {
	border: none;
}

.post-dt .comment .list .btn-reply {
	padding: 0 10px;
	background-color: #fff;
	color: var(--text-primary);
	border: none;
}

.post-dt .comment .list .btn-reply:focus,
.post-dt .comment .list .btn-reply:active {
	background-color: #fff;
}

.post-dt h1 {
	font-size: 22px;
	color: var(--text-primary);
	margin: 30px 0 16px;
	padding: 0;
}

.post-dt h1 .badge {
	background-color: #ddd;
	padding: 4px 10px;
	color: var(--text-primary);
	border-radius: 30px;
	font-size: 14px;
	font-weight: 400;
}

.voucherDtCard {
	/* padding: 1rem !important; */
	border: 1px solid #ddd !important;
}

.voucherDtCard label {
	display: block;
	font-size: 15px;
	margin: 0 0 16px;
	font-weight: 400;
	color: #15161eda;
	text-align: center;
}

.voucherDtCard .form-text {
	text-align: center;
	font-size: 16px;
	font-weight: 400;
	color: var(--text-primary);
	margin: 0 0 6px;
}

.voucherDtCard .dt .row {
	align-items: center;
	margin-bottom: 16px;
}

.voucherDtCard .dt .row:last-child {
	margin-bottom: 0;
}

.voucherDtCard .dt .no {
	width: 30px;
	height: 30px;
	position: relative;
	text-align: center;
	line-height: 30px;
	color: #15161e8f;
}

.voucherDtCard .dt .no svg {
	position: absolute;
	top: 0;
	left: 0;
}

.voucherDtCard .dt .val {
	position: relative;
	display: inline-block;
	padding: 4px 12px;
	font-size: 14px;
	font-weight: 600;
	color: #15161e8f;
	border-radius: 4px;
}

.voucherDtCard .dt .val.active {
	border: 1px solid #ddd;
	color: var(--text-primary);
}

.voucherDtCard .dt .val.active::after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-right: 10px solid #ddd;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	position: absolute;
	left: -10px;
	top: 3px;
}

.btn-collapse,
.sec-list {
	position: relative;
	width: 100%;
	background-color: #fff !important;
	color: var(--text-primary) !important;
	padding: 10px 30px 10px 16px !important;
	font-size: 16px !important;
	text-transform: inherit !important;
	border: 1px solid #ddd !important;
	text-align: left !important;
	border-radius: 0 !important;
}

.sec-list-child {
	padding: 10px 0 10px 0 !important;
}

.sec-list .sec-list-child:last-child {
	border: 0 !important;
}

.btn-collapse::after {
	content: '\f078';
	display: block;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	color: #15161ed2;
	position: absolute;
	right: 10px;
	top: 15px;
	font-size: 12px;
	transition: all 0.4s ease-in-out;
}

.btn-collapse[aria-expanded='true']:after {
	transform: rotate(180deg);
}

.collapse-body {
	padding: 1rem;
	margin: -9px 0 8px;
	border: 1px solid #ddd;
}

.collapse-body .collapse-body {
	margin: 0;
}

.collapse-body .list {
	text-align: left;
	font-size: 16px;
	color: var(--text-primary);
	border-bottom: 1px solid #ddd;
	padding: 16px 0;
}

.collapse-body .list:last-child {
	border: none;
	padding: 16px 0 0;
}

.collapse-body .list .inf div {
	text-align: left;
	display: inline-block;
	font-size: 14px;
	color: #15161e8e;
}

.sec-list {
	align-items: center;
	justify-content: space-between;
	margin-bottom: 6px;
}

.sec-list h3 {
	flex: 1 0 0;
	font-size: 16px;
	color: var(--text-primary);
	padding: 0;
	margin: 0;
	font-weight: 400;
}

.sec-list .switch {
	flex: 0 0 auto;
	width: 104px;
}

.sec-list .switch label {
	margin: 0;
}

.modal-backdrop,
.modal {
	z-index: 1201;
}

.userVcard {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 1rem;
	height: 100%;
}

.userVcard img {
	display: block;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	object-fit: cover;
	margin: 0 auto;
}

.userVcard h3 {
	font-size: 16px;
	color: var(--text-primary);
	padding: 0;
	margin: 16px 0 0;
	text-align: center;
	border: 0;
}

.userVcard h3 span {
	display: block;
	margin-top: 4px;
	color: #15161ed2;
	font-weight: 400;
}

.userVcard .fInfo {
	font-size: 14px;
	color: #15161ead;
}

.userVcard .edit-btn,
.userVcard .btn-orange-o {
	position: absolute;
	right: 5px;
	top: 5px;
}

.manage-users h1 {
	font-size: 22px;
	color: var(--text-primary);
	margin: 0 0;
	padding: 0;
}

.post h1 {
	font-size: 18px;
	color: var(--text-primary);
	margin: 0 0 16px;
	padding: 0;
}

.chat {
	position: fixed;
	right: 0;
	bottom: -100%;
	width: auto;
	background-color: #fff;
	box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
	transition: all 0.4s ease-in;
	z-index: 0;
}

.chat.active {
	bottom: 0;
	transition: all 0.4s ease-out;
}

.chat .btn-toggle-chat {
	padding: 0;
	margin: 0;
	line-height: 1;
	width: 42px;
	height: 32px;
	position: absolute;
	top: -31px;
	box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.1);
	transition: all 0.4s;
}

.chat .btn-toggle-chat:focus,
.chat .btn-toggle-chat:active {
	box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.1);
}

.chat .btn-toggle-small {
	width: 55px;
}

.chat .btn-toggle-small i {
	transition: all 0.4s ease-in;
	transform: rotate(180deg);
}

.chat .chat-head {
	display: flex;
	display: -moz-flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	padding: 10px 0;
}

.chat .chat-head h2 {
	font-size: 16px;
	color: var(--text-primary);
	font-weight: 600;
	padding: 0;
	margin: 0;
}

.chat .custom-tabs .nav {
	padding: 4px 0 0;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

.chat .custom-tabs .nav-item .nav-link {
	padding: 8px;
	border: none;
}

.chat .user-list {
	position: relative;
	flex-wrap: wrap;
	padding: 5px 10px;
	align-items: center;
	cursor: pointer;
}

.chat .user-list img {
	flex: 0 0 30px;
	width: 30px;
	max-width: 100%;
	height: 30px;
	border-radius: 50%;
	object-fit: cover;
}

.chat .user-list .msg {
	color: var(--orange);
	font-size: 8px;
	position: absolute;
	left: 32px;
	top: 6px;
}

.chat .user-list div {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
	font-size: 8px;
	color: #ddd;
}

.chat .user-list div.on {
	color: var(--green);
}

.chat .user-list h3 {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	padding: 0 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 14px;
	color: var(--text-primary);
	font-weight: 400;
	margin: 0;
}

.chat .chat-small .user-list h3,
.chat .chat-small .user-list div {
	display: none;
}

.chat .tab-pane {
	height: auto;
	max-height: 50vh;
	overflow-y: auto;
	transition: all 0.4s;
}

.chat .chat-footer {
	padding: 0 0.5rem 0.5rem;
}

.chat-widnow-holder {
	position: fixed;
	bottom: 0;
	right: 23em;
	width: calc(100% - 25em);
	display: flex;
	justify-content: flex-end;
	z-index: 1200;
}

.chat-holder-left {
	right: 4em;
	width: auto;
	max-width: calc(100% - 4em);
}

.chat-window {
	background-color: #fff;
	width: 24em;
	padding: 10px;
	box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
	z-index: 1200;
	margin: 0 10px;
}

.chat-window .head {
	align-items: center;
	padding: 0 0 10px;
	border-bottom: 1px solid #ddd;
}

.chat-window .head img {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	object-fit: cover;
	flex: 0 0 30px;
	width: 30px;
	max-width: 100%;
}

.chat-window .head h3 {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	padding: 0 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 14px;
	color: var(--text-primary);
	font-weight: 400;
	margin: 0;
}

.chat-window .head .dropdown-toggle {
	color: var(--text-primary);
}

.chat-window .head .dropdown-toggle::after {
	display: none;
}

.chat-window .head .btn-close {
	color: var(--orange);
	background-color: transparent !important;
	border: none !important;
}

.chat-window .head .dropdown-menu {
	border: none !important;
	box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1) !important;
}

.chat-window .head .dropdown-menu a {
	font-size: 14px;
	border: none;
}

.chat-window
	.head
	.dropdown-menu
	a:hover
	.chat-window
	.head
	.dropdown-menu
	a:focus {
	background-color: #fff;
}

.chat-window .chat-body {
	padding: 10px 0;
	max-height: 50vh;
	overflow-y: auto;
}

.chat-window .chat-body .dt {
	display: block;
	font-size: 12px;
	color: #15161ed2;
	text-align: center;
	margin: 12px 2px;
}

.chat-window .chat-body .in {
	position: relative;
	display: flex;
	justify-content: flex-start;
	margin: 4px 0;
}

.chat-window .chat-body .in div {
	display: inline-block;
	font-size: 14px;
	color: var(--text-primary);
	max-width: 90%;
	background-color: #f4f4f4;
	padding: 6px 10px;
	border-radius: 6px;
}

.chat-window .chat-body .in small {
	display: inline;
	width: 100%;
	text-align: right;
	padding: 0 2px;
	opacity: 0.6;
	margin-left: 4px;
}

.chat-window .chat-body .out {
	position: relative;
	display: flex;
	justify-content: flex-end;
	margin: 4px 0;
}

.chat-window .chat-body .out div {
	display: inline-block;
	font-size: 14px;
	color: var(--text-primary);
	max-width: 90%;
	background-color: #f1682330;
	padding: 6px 10px;
	border-radius: 6px;
}

.chat-window .chat-body .out small {
	display: inline;
	width: 100%;
	text-align: right;
	padding: 0 2px;
	opacity: 0.6;
	margin-left: 4px;
}

.chat-window .chat-footer {
	border-top: 1px solid #ddd;
	margin: 0 -10px;
	padding: 10px 0 0;
}

.chat-window .chat-footer textarea {
	resize: none;
}

.btn[aria-expanded='true'] .fa-angle-down {
	transform: rotate(180deg);
}

hr {
	border-color: #ddd;
}

.or-hr {
	position: relative;
	margin: 3px 0;
	text-align: center;
}

.or-hr::after {
	content: '';
	display: block;
	width: 100%;
	height: 1px;
	background-color: #ddd;
	position: absolute;
	left: 0;
	top: 50%;
	z-index: 0;
}

.or-hr span {
	position: relative;
	display: inline-block;
	padding: 0 12px;
	background-color: #fff;
	font-size: 14px;
	z-index: 10;
}

.relative {
	position: relative !important;
}

.border-0 {
	border: 0 !important;
}

.search-profile .sticky-top {
	top: 73px;
}

.search-profile label .float-right {
	color: #15161ed2;
	font-size: 90%;
}

.search-profile .text-primary {
	color: #1058e8 !important;
}

.response-manager .connection-card,
.search-profile .connection-card {
	position: relative;
	padding: 1.5rem 1rem 0.5rem !important;
	overflow: hidden;
}

.response-manager .connection-card.border-right-green::before,
.response-manager .response-card.border-right-green::before,
.search-profile .connection-card.border-right-green::before {
	content: '';
	display: block;
	width: 6px;
	height: 100%;
	background-color: var(--green);
	position: absolute;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 10;
}

.response-manager .connection-card .ps-abs,
.search-profile .connection-card .ps-abs {
	position: absolute;
	right: 40px;
	top: 6px;
}

.response-manager .connection-card .userPic,
.search-profile .connection-card .userPic {
	width: 80px;
	height: 80px;
}

.response-manager .connection-card .fInfo,
.search-profile .connection-card .fInfo {
	font-size: 14px;
	color: var(--text-primary);
}

.response-manager .connection-card .fInfo span,
.search-profile .connection-card .fInfo span {
	color: #15161ead;
}

.response-manager .connection-card .btns,
.search-profile .connection-card .btns {
	position: absolute;
	right: 0;
	top: 40%;
}

.response-manager .connection-card .btns.badge,
.response-manager .connection-card .btns .badge,
.search-profile .connection-card .btns .badge {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	padding-right: 22px !important;
}

.response-manager .connection-card .card-check,
.search-profile .connection-card .card-check {
	position: absolute;
	left: 30px;
	top: 5px;
}

.response-manager .connection-card .btn-chat,
.search-profile .connection-card .btn-chat {
	right: 10px;
}

.search-profile .big-check .custom-checkbox .custom-control-label::before,
.response-manager .connection-card .card-check .custom-control-label::before,
.search-profile .connection-card .card-check .custom-control-label::before {
	width: 14px;
	height: 14px;
}

.search-profile .big-check .custom-checkbox .custom-control-label::after,
.response-manager
	.connection-card
	.card-check
	.custom-checkbox
	.custom-control-label::after,
.search-profile
	.connection-card
	.card-check
	.custom-checkbox
	.custom-control-label::after {
	position: absolute;
	top: 0.2rem;
	left: -1.28rem;
}

.response-manager .connection-card .card-ft,
.search-profile .connection-card .card-ft {
	font-size: 14px;
	border-top: 1px dashed #ddd;
	margin-left: -16px;
	margin-right: -16px;
}

.response-manager .connection-card .card-ft .custom-select,
.search-profile .connection-card .card-ft .custom-select {
	height: 20px;
	padding: 0 1.75rem 0 0.75rem;
	line-height: 1;
}

.response-manager .connection-card .card-ft .sk,
.search-profile .connection-card .card-ft .sk {
	font-size: 14px;
}

.response-manager .connection-card .card-ft .sk span,
.search-profile .connection-card .card-ft .sk span {
	display: inline-block;
	padding: 4px 8px;
	font-size: 12px;
	line-height: 1;
	border: 1px solid #ddd;
	margin: 0 3px;
	border-radius: 40px;
}

.search-profile .connection-card .card-ft .sk1 span {
	display: inline-block;
	padding: 4px 8px;
	font-size: 12px;
	line-height: 1;
	margin: 0 3px;
	border-radius: 40px;
}

.response-manager .connection-referred {
	padding-top: 0rem !important;
}
.response-manager .connection-referred .ps-abs {
	top: -10px;
}

.response-manager .connection-referred .card-ft {
	margin-left: 0;
	margin-right: 0;
}
.response-manager .connection-referred .referred-div {
	border-left: 1px dashed #ddd;
	padding-top: 4px;
	text-align: center;
}
.response-manager .connection-referred .referred-div hr {
	width: 60px;
	margin-left: auto;
	margin-right: auto;
}

.response-card .response-card__right hr {
	width: 60px;
	margin-left: auto;
	margin-right: auto;
}

.search-profile .btm-btns {
	position: sticky;
	left: 0;
	right: 0;
	bottom: 0;
	font-size: 14px;
	color: #15161ed2;
	box-shadow: 0px -3px 3px 1px rgba(0, 0, 0, 0.1);
}

.search-profile .top-fx {
	background-color: #f6f6f6;
}

.subscription h1 {
	font-size: 22px;
	color: var(--text-primary);
	margin: 0 0 30px;
	padding: 0;
}

.subscription h2 {
	font-size: 18px;
	color: var(--text-primary);
	margin: 0 0 16px;
	padding: 0;
}

.subscription .pr-card {
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 0;
}

.subscription .pr-card h3 {
	font-size: 14px;
	font-weight: 600;
	color: var(--text-primary);
	padding: 0;
	margin: 0;
}

.subscription h4 {
	font-size: 30px;
	font-weight: 600;
	color: var(--text-primary);
	padding: 0;
	margin: 0;
}

.subscription h4 sup {
	font-weight: 400;
	top: -0.2em;
	left: -0.2em;
}

.subscription .pr-card .fInfo {
	background-color: #f6f6f6;
}

.subscription .pr-card .fInfo .status {
	font-size: 14px;
	font-weight: 400;
}

.subscription .pr-card .fInfo .date {
	font-size: 14px;
	font-weight: 400;
	color: #15161eb7;
}

.subscription .pr-card .fInfo .counts .col-6:nth-child(2) {
	border-left: 1px dashed #ddd;
	border-right: 1px dashed #ddd;
}

.subscription .pr-card .fInfo .counts span {
	display: block;
	font-size: 14px;
	font-weight: 400;
	color: #15161ed2;
}

.subscription .pr-card .fInfo .counts div {
	font-size: 16px;
	font-weight: 600;
	color: var(--text-primary);
}

.subscription .sub-card {
	position: relative;
	overflow: hidden;
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 1rem;
}

.subscription .sub-card::before {
	content: '';
	display: block;
	width: 122px;
	height: 122px;
	border-radius: 50%;
	background-color: #ddd;
	opacity: 0.2;
	position: absolute;
	right: -80px;
	bottom: -80px;
}

.subscription .sub-card::after {
	content: '';
	display: block;
	width: 160px;
	height: 160px;
	border-radius: 50%;
	background-color: #ddd;
	opacity: 0.2;
	position: absolute;
	right: -100px;
	bottom: -100px;
}

.subscription .sub-card label {
	display: block;
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	padding: 0;
	margin: 0 0 6px;
	color: #15161ed2;
}

.subscription .sub-card div {
	font-weight: 300;
	color: var(--text-primary);
	font-size: 12px;
	margin: 6px 0 0;
	text-align: center;
}

.subscription .sub-card div span {
	color: #15161eda;
}

.sub-progress {
	font-size: 14px;
	color: var(--text-primary);
}

.sub-progress .progress {
	background: rgb(216, 216, 216);
	/* Old browsers */
	background: -moz-linear-gradient(
		top,
		rgba(216, 216, 216, 1) 0%,
		rgba(242, 242, 242, 1) 26%,
		rgba(255, 255, 255, 1) 50%,
		rgba(242, 242, 242, 1) 77%,
		rgba(216, 216, 216, 1) 100%
	);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(
		top,
		rgba(216, 216, 216, 1) 0%,
		rgba(242, 242, 242, 1) 26%,
		rgba(255, 255, 255, 1) 50%,
		rgba(242, 242, 242, 1) 77%,
		rgba(216, 216, 216, 1) 100%
	);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to bottom,
		rgba(216, 216, 216, 1) 0%,
		rgba(242, 242, 242, 1) 26%,
		rgba(255, 255, 255, 1) 50%,
		rgba(242, 242, 242, 1) 77%,
		rgba(216, 216, 216, 1) 100%
	);
}

.sub-progress .progress-bar {
	background: rgb(20, 173, 0);
	/* Old browsers */
	background: -moz-linear-gradient(
		top,
		rgba(20, 173, 0, 1) 0%,
		rgba(0, 211, 56, 1) 30%,
		rgba(0, 237, 63, 1) 50%,
		rgba(0, 211, 56, 1) 71%,
		rgba(20, 173, 0, 1) 100%
	);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(
		top,
		rgba(20, 173, 0, 1) 0%,
		rgba(0, 211, 56, 1) 30%,
		rgba(0, 237, 63, 1) 50%,
		rgba(0, 211, 56, 1) 71%,
		rgba(20, 173, 0, 1) 100%
	);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to bottom,
		rgba(20, 173, 0, 1) 0%,
		rgba(0, 211, 56, 1) 30%,
		rgba(0, 237, 63, 1) 50%,
		rgba(0, 211, 56, 1) 71%,
		rgba(20, 173, 0, 1) 100%
	);
}

.ant-picker {
	width: 100%;
}

.ant-picker-dropdown {
	z-index: 1250 !important;
}

.filter-card-sticky-top {
	position: sticky;
	top: 46px;
}

.filter-card {
	height: auto;
	max-height: calc(100vh - 110px);
	overflow-y: auto;
}

.filter-card .btn-view-all {
	color: #1058e8 !important;
}

.pf-found {
	font-size: 16px;
	font-weight: 600;
	color: var(--text-primary);
	text-align: center;
	background-color: #fff;
	padding: 0.89rem 0;
}

.pf-found span {
	color: #15161eab;
	font-weight: 400;
	font-size: 12px;
	vertical-align: middle;
}

.tab-pane .filter-card {
	height: calc(100vh - 290px);
	border-radius: 8px;
}

.tab-pane .pf-found {
	padding: 0 0 0.89rem;
}

.track .filter-card {
	max-height: calc(100vh - 155px);
}

.requirements .filter-card {
	max-height: calc(100vh - 170px);
}

.referral .filter-card {
	max-height: calc(100vh - 170px);
}

.search-input input {
	border: none;
	border-bottom: 1px solid #ddd;
	border-radius: 0;
	background-color: transparent;
}

.search-input input:focus,
.search-input input:hover {
	background-color: transparent;
	border-color: var(--text-primary);
}

.search-input .input-group-append {
	background-color: transparent;
	border: none;
	border-radius: 0;
}

.search-input .input-group-append .btn,
.search-input .input-group-append .input-group-text {
	border: none !important;
	border-bottom: 1px solid #ddd !important;
	background-color: transparent !important;
	border-radius: 4px !important;
}

.drop-select {
	border: none;
	border-bottom: 1px solid #ddd;
	border-radius: 0;
	background-color: transparent;
}

.img-avatar {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	object-fit: cover;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
	line-height: 24px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	border: 0 !important;
	height: 31px !important;
	padding: 0px 1px !important;
	background-color: transparent !important;
	margin-top: -3px;
	width: 100px;
}

.ant-select-arrow .anticon {
	display: none;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
	padding-right: 11px;
}

.autocomplete-dropdown-container {
	position: absolute;
	left: 0;
	width: 100%;
	max-width: 500px;
	background-color: #fff;
	padding-left: 1rem;
	/* padding-bottom: 1rem; */
	z-index: 100;
}

input[aria-expanded='true'] ~ .autocomplete-dropdown-container {
	box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.1);
}
.custom-control {
	z-index: 0 !important;
}
.react-autosuggest__container input {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.4rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 15px !important;
	font-weight: 400;
	line-height: 1.5;
	color: var(--text-primary);
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ddd;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-emoji {
	max-width: 260px;
	align-items: flex-start;
}

.react-input-emoji--button {
	padding: 0 4px !important;
}

.react-input-emoji--container {
	margin: 0 !important;
	border: 0 !important;
}

.react-emoji-picker--wrapper {
	width: 356px;
}

.react-input-emoji--input {
	scrollbar-width: thin;
	border-radius: 4px;
}

.react-input-emoji--input::-webkit-scrollbar {
	border-radius: 4px;
	width: 5px;
}

/* Track */
.react-input-emoji--input::-webkit-scrollbar-track {
	border-radius: 4px;
	background: #ddd;
}

/* Handle */
.react-input-emoji--input::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--orange);
}

/* Handle on hover */
.react-input-emoji--input::-webkit-scrollbar-thumb:hover {
	background: var(--orange);
}

/* calendar */
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
	text-align: center;
	font-weight: 600;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
	display: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border: 0px solid #ddd !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
	background-color: #f1682330 !important;
	border-color: var(--orange) !important;
}

.ant-picker-calendar-full
	.ant-picker-panel
	.ant-picker-cell-selected
	.ant-picker-calendar-date-today
	.ant-picker-calendar-date-value {
	color: var(--orange) !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
	text-align: center;
	font-size: 16px;
	line-height: 60px !important;
}

.event-list {
	padding: 12px 0;
	color: var(--text-primary);
	border: 1px solid #ddd;
	border-radius: 4px;
	margin: 8px 0;
}

.event-list .dt i {
	color: #00000060;
}

.react-tags__suggestions {
	z-index: 200 !important;
}

.response-tab-actions {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background-color: #ddd;
	padding: 6px 0;
	border-radius: 8px;
	margin: 0 0 16px;
}

@media only screen and (min-width: 1200px) {
	.container {
		max-width: 970px;
	}
}

@media only screen and (min-width: 1440px) {
	.container {
		max-width: 1040px;
	}
}

@media only screen and (min-width: 1600px) {
	.container {
		max-width: 1220px;
	}
}

.hide-input {
	display: none !important;
}

#agreeTerms + label {
	user-select: none;
}

.connection-card span button {
	width: 40px !important;
	background-color: #dddddd !important	;
	height: 40px !important;
}

.zero-padding {
	padding: 0 !important;
}

.noMargins {
	margin: 0px !important;
}

.noBorders {
	border: 0px !important;
}
.noPaddings {
	padding: 0px !important;
}
.tracker-header .close
{
	margin-right: 14px!important;
}
.three-line-text {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
	height: calc(3 * 1.25em);
	line-height: 1.1em;
	text-overflow: ellipsis;
}
.two-line-text {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	height: calc(2 * 1.25em);
	line-height: 1.1em;
	text-overflow: ellipsis;
}

.box-19px {
	width: 19px !important;
	height: 19px !important;
}

.top-6px {
	top: 6px !important;
}
.chip-component {
	border: 1px solid #707070;
	padding: 8px;
	border-radius: 10px;
	font-size: 14px;
}
.chip-component-wrapper {
	display: flex;
	gap: 10px;
	padding: 16px;
	flex-wrap: wrap;
}
.react-tags {
	border: none !important;
}
.modal-width-fit .modal-dialog {
	width: fit-content;
	min-width: 400px;
	max-width: 70%;
	margin: 1.75rem auto;
}
.modal-width-fit {
	display: flex !important;
	justify-content: center !important;
}

.modal-width-fit .modal-content {
	width: fit-content;
	min-width: 300px;
}
.resendOtp
{
	color: blue;
	text-decoration: underline;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}
/* HTML: <div class="loader"></div> */
.custom-spinner {
	margin-right: 10px;
    height: 20px;
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
        radial-gradient(farthest-side,#ffffff 94%,#0000) top/4px 4px no-repeat,
        conic-gradient(#0000 30%,#ffffff);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
    animation: l13 1s infinite linear;
}
.btn-dark:hover .custom-spinner {
    background:
        radial-gradient(farthest-side,#000000 94%,#0000) top/4px 4px no-repeat,
        conic-gradient(#0000 30%,#000000);
}
@keyframes l13 {
    100% { transform: rotate(1turn) }
}
