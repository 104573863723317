/* ----------  Custom Toggles  ---------- */

.custom-radio
{
	padding-left: 0;

	@include position;
	@include display-flex(column, normal, center);

	.custom-control-label
	{
		margin: 0;
		padding: 0;
		padding-left: 25px;

		&:before
		{
			left: 0 !important;
			top: 50% !important;
			border: 1px solid $brand-dark;
		}

		&:after
		{
			@include border-radius(99px);
		}

		&:before, &:after
		{
			@include transform(translateY(-50%));
		}
	}

	.custom-control-input:checked ~ .custom-control-label
	{
		&:after
		{
			top: 50% !important;
			left: 3px !important;

			width: 10px !important;
			height: 10px !important;
		}
	}
}

.custom-checkbox
{
	// padding-left: 0;

	@include position;
	@include display-flex(column, normal, center);

	.custom-control-label
	{
		margin: 0;
		padding: 0;
		padding-left: 25px;

		@include font-p($font-base, $weight-regular, 1.2, $brand-dark);

		&:before
		{
			left: 0 !important;
			top: 50% !important;
		}

		&:after
		{
			content: '\f00c';

			@include font-fa(rem-calc(8), $weight-semibold, 1, $white-color);
		}

		&:before, &:after
		{
			@include transform(translateY(-50%));
		}
	}

	.custom-control-input:checked ~ .custom-control-label
	{
		&:after
		{
			top: 50% !important;
			left: 4px !important;

			width: auto !important;
			background-image: none;
			height: auto !important;
		}
	}
}

.custom-checkbox, .custom-radio
{
	padding-left: 0;
}

/* ----------  Form Control  ---------- */

.form-control, .custom-select
{
	height: auto;
	min-height: 40px;
	padding: 9px 20px;
	border: 1px solid $brand-gray-01;

	@include border-radius(4px);
	@include font-p($font-base, $weight-regular, 1.6, $brand-dark);

	&:focus
	{
		border-color: $brand-dark;
	}
}

select, select.custom-select, select.form-control
{
	cursor: pointer;
}

select.custom-select, select.form-control
{
	appearance: none !important;

	background: $white-color url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' fill='rgba(0,0,0,1)'/%3E%3C/svg%3E") no-repeat right .75rem center/8px 20px;
	background-size: 18px;
}

label, .form-label
{
	display: block;
	cursor: pointer;

	@include font-p($font-sm, $weight-regular, 1.3, $brand-dark);
}

.form-group
{
	label, .form-label
	{
		margin-bottom: 8px;
		font-size: $font-sm;
	}
}

/* ----------  Badge Tags  ---------- */

.badge-tags
{
	margin: 0 -5px;
	margin-top: 5px;

	@include display-inline-flex($wrap: wrap);

	.badge
	{
		border: 0;
		padding: 0;
		margin: 5px;
		min-height: 38px;
		padding: 8px 16px;
		padding-right: 45px;
		background: $brand-gray-01;

		@include position;
		@include border-radius(38px);
		@include display-flex(column, center, center);
		@include font-s($font-sm, $weight-medium, 1.2, $brand-dark);

		span
		{
			display: block;
		}

		.badge-remove
		{
			@include transform(translateY(-50%));
			@include position(absolute, 5, $top: 50%, $right: 16px);
			@include font-fa($font-xs, $weight-bold, 1, $brand-dark);
		}
	}

	&:empty
	{
		display: none !important;
	}
}

/* ----------  MUI Components  ---------- */

.MuiAutocomplete-inputRoot
{
	box-sizing: border-box !important;

	*
	{
		box-sizing: border-box !important;
	}
}

.form-group
{
	.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
	{
		.MuiOutlinedInput-notchedOutline
		{
			border: 0;
		}

		.MuiAutocomplete-input
		{
			height: auto;
			min-height: 40px;
			padding: 9px 20px;
			border: 1px solid $brand-gray-01;

			@include border-radius(4px);
			@include font-p($font-base, $weight-regular, 1.6, $brand-dark);

			&:focus
			{
				border-color: $brand-dark;
			}

			&:first-child
			{
				padding: 9px 20px;
			}
		}
	}

	.MuiInputBase-root
	{
		@include display-flex(column-reverse);

		.MuiAutocomplete-input
		{
			width: 100%;

			@include flex(1 1 100%);
		}

		.MuiAutocomplete-endAdornment
		{
			top: 10px;
		}
	}
}

/* ----------  Switch  ---------- */

.switch
{
	.toggler
	{
		@include transform(scaleX(-1));
	}
}


/* ----------  Input Groups  ---------- */

.form-group
{
	&.form-group-floating-addon
	{
		.input-group
		{
			@include position;

			.react-autosuggest__container
			{
				width: 100%;
			}

			.form-control
			{
				@include border-radius(4px);
			}

			.input-group-prepend, .input-group-append
			{
				border: 0;
				width: 40px;
				height: calc(100% - 8px);

				@include position(absolute, 99, 50%);
				@include transform(translateY(-50%));
				@include display-flex(column, center, center);

				.fa, .fas, .far
				{
					font-size: $font-base;
				}

				&.btn
				{
					// width: auto;
					padding: 0 !important;

					&:hover, &:active, &:focus
					{
						background: none;
					}
				}
			}

			.input-group-prepend
			{
				left: 4px;
			}

			.input-group-append
			{
				right: 4px;
			}
		}

		&.addon-prepend
		{
			.form-control
			{
				// padding-right: 0;
				padding-left: 55px;
			}
		}

		&.addon-append
		{
			.form-control
			{
				// padding-left: 0;
				padding-right: 55px;
			}
		}
	}

	&.form-group-search
	{
		margin-bottom: 0;

		.input-group
		{
			.form-control
			{
				border: 0;
				min-height: auto;
				background: none;
				padding-top: 3px;
				font-style: italic;
				padding-bottom: 3px;
				border-bottom: 1px solid $brand-gray-04;

				@include border-radius(0);
			}

			.input-group-prepend, .input-group-append
			{
				.fa, .fas, .far
				{
					font-size: $font-xs;
				}
			}
		}
	}
}
