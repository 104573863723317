.navBar {
    background-color: #081238;
    .navBrand {
        font-size: $text-lg;
    }
    .navIcons {
        color: $white;
    }
    .navPostBtn {
        background-color: $white;
        color: $black;
        font-size: 14px;
        padding: 6px 14px;
        border: 1px solid $white;
        border-radius: 4px;
    }
    .navPostBtn:hover {
        background-color: #081238;
        border: 1px solid $white;
        color: $white;
    }
    .navSec a{
        margin: 0 4px;
    }
}