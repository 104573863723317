.company-profile {
    &__left {
        h3 {
            margin: 0;
            padding: 0;
            font-weight: $weight-regular;
        }
        h4 {
            text-align: center;
            font-size: $font-xs;
            color: $brand-gray-04;
            margin: 20px 0;
        }
        label {
            font-size: $font-sm;
            i {
                color: $brand-gray-04;
            }
        }
    }
    &__about {
        h2 {
            font-size: $font-lg !important;
        }
        p {
            font-size: $font-sm !important;
            color: $brand-gray-04 !important;
        }
    }
    &__management, &__products {
        border-top: 1px solid $brand-gray-01;
        padding: 20px 0;
    }
    .btn-view-all {
        color: $orange;
        font-size: $font-sm !important;
    }
    &__products {
        border: 1px solid $brand-gray-01;
        padding: 4px 40px;
        border-radius: 8px;
        &--card {
            border-bottom: 1px solid $brand-gray-01;
            padding: 20px 0;
            img {
                width: 40px;
                border-radius: 50%;
            }
            h4 {
                font-size: $font-sm;
            }
            p{
                font-size: $font-sm;
                color: $brand-gray-04;
                margin-bottom: 0;
            }
        }
        &--card:last-child {
            border-bottom: none;
        }
    }
    &__partners {
        border: 1px solid $brand-gray-01;
        border-radius: 8px;
        &--card {
            border-bottom: 1px solid $brand-gray-01;
            padding: 20px 0;
            img {
                width: 40px;
                border-radius: 50%;
                margin: 0px 20px;
            }
            h4 {
                font-size: $font-sm;
            }
            p{
                font-size: $font-sm;
                color: $brand-gray-04;
                margin: 10px 0;
            }
        }
        &--card:last-child {
            border-bottom: none;
        }
    }
}