// VARIABLES

$black: #15161E;
$white: #FFFFFF;
$l-grey: #DDDDDD;
$d-grey: #707070;
$orange: #F16823;

$font: Roboto, sans;
$font-sec: Nunito, Roboto;

$text: 14px;
$text-lg: 16px;
$br: 8px;

// IMPORTS

@import 'navbar';
@import 'enquiry';
@import 'registration';
@import 'newReg';
@import 'data';