.notification {
    .ps__thumb-y {
        background-color: $orange;
    }
    &__title {
        display: flex;
        justify-content: center;
        border-bottom: 1px dashed $brand-gray-01;
        h2 {
            border-bottom: none;
        }
    }
    span {
        font-size: $font-sm;
        font-weight: $weight-regular;
        border: 1px solid $brand-gray-01;
        padding: 4px 8px;
        margin-bottom: 20px;
    }
    &__content {
        display: flex;
        align-items: center;
        margin-top: 20px;
        a {
            text-decoration: none;
            color: $black-color;
        }
    }
    label {
        font-style: italic;
    }
}