/*----------  Iterations  ----------*/

$unit: px;

@for $i from 1 through 10
{

	$class: $i * 5;
	$space: ($i * 5) + $unit;

	/*----------  Margins  ----------*/

	.m-#{$class}
	{
		margin: $space !important;
	}

	.m-t-#{$class}
	{
		margin-top: $space !important;
	}

	.m-b-#{$class}
	{
		margin-bottom: $space !important;
	}

	.m-l-#{$class}
	{
		margin-left: $space !important;
	}

	.m-r-#{$class}
	{
		margin-right: $space !important;
	}

	.m-v-#{$class}
	{
		margin-top: $space !important;
		margin-bottom: $space !important;
	}

	.m-h-#{$class}
	{
		margin-left: $space !important;
		margin-right: $space !important;
	}

	/*----------  Paddings  ----------*/

	.p-#{$class}
	{
		padding: $space !important;
	}

	.p-t-#{$class}
	{
		padding-top: $space !important;
	}

	.p-b-#{$class}
	{
		padding-bottom: $space !important;
	}

	.p-l-#{$class}
	{
		padding-left: $space !important;
	}

	.p-r-#{$class}
	{
		padding-right: $space !important;
	}

	.p-v-#{$class}
	{
		padding-top: $space !important;
		padding-bottom: $space !important;
	}

	.p-h-#{$class}
	{
		padding-left: $space !important;
		padding-right: $space !important;
	}
}
