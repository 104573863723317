.btn-toggle-chat {
    box-shadow: none !important;
}

.chat-head {
    display: flex;
    flex-direction: column;
    padding: 10px 12px !important;
    padding-bottom: 0;
    hr {
        margin: 0;
    }
}

.chat-body {
    .msg-date {
        display: flex;
        justify-content: center;
        span {
            display: inline-block;
            background-color: $brand-gray-01;
            border-radius: 8px;
            padding: 4px 10px;
            color: $brand-gray-03;
        }
    }
    .out {
        div {
            background-color: #1EDE7815 !important;
            border: 1px solid #1EDE7815;
        }
    }
    .in {
        div {
            background-color: transparent !important;
            border: 1px solid $black-color;
        }
    }
}

.chat {
    .custom-tabs {
        .nav {
            border-top: none;
        }
    }
}