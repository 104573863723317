.company-sec-nav {
    input {
        font-style: italic;
    }
    .btn {
        // background: none;
        // border: none;
        // background-color: transparent !important;
        i {
            color: $brand-gray-04;
        }
    }
}

.company-home-search-- {
    font-family: $font-secondary;
    .form-label {
        font-weight: $weight-semibold;
    }
    .react-tags {
        border-radius: 4px;
    }
    .form-control {
        font-size: 1em !important;
        padding: 22px 4px;
    }
    select {
        height: 46px;
    }
}
