.ind-explore-nav {
    .nav-link {
        font-weight: 400 !important;
    }
}

.postCard {
    .fInfo {
        font-size: $font-sm;
        
    }
}