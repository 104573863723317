.institute-home {
    h1 {
        font-size: $font-lg;
    }
    .btn-orange-o {
        border: 1px solid $brand-gray-02 !important;
        padding: 6px 24px;
        font-size: $font-sm !important;
    }
}

.institute-posts {
    .header-title {
        display: flex;
        align-items: center;
    }
    &__footer {
        border-top: 1px solid $brand-gray-01;
        margin-top: 10px !important;
        .col:nth-child(2) {
            border-left: 1px solid $brand-gray-01;
            border-right: 1px solid $brand-gray-01;
        }
    }
    .mutualPics {
        margin-top: 0 !important;
    }
}