.institute-edit-profile {
    &__left {
        .card {
            @include media-breakpoint-up(lg) {
                position: sticky;
                z-index: 1020;
            }
            span {
                font-size: $font-sm;
                i {
                    margin-right: 10px;
                }
            }
        }
    }
    .react-autosuggest__input {
        padding: 17px 10px;
    }
    .management-card {
        h3, span {
            font-weight: $weight-regular;
            text-align: center;
            font-size: $font-sm;
            margin: 4px 0;
        }
    }
    .profile-data-card {
        padding: 10px 20px !important;
    }
    i {
        @include media-breakpoint-down(lg) {
            font-size: 1em;
        }
    }
}