.homeNav {
    .nav-item {
        padding-left: 40px;
    }
    .nav-item:nth-child(1) {
        padding-left: 0;
    }
    .searchBar {
        font-style: italic;
        font-weight: 100;
    }
}

.indCard {
    box-shadow: none;
}