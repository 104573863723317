/* ----------  Carousels  ---------- */

.BrainhubCarousel__container
{
	.BrainhubCarousel
	{
		.BrainhubCarousel__trackContainer
		{
			.BrainhubCarousel__track
			{
				.BrainhubCarouselItem
				{
					padding: 0 15px;
				}

				.card
				{
					width: 100%;
				}
			}
		}

		.BrainhubCarousel__arrows
		{
			padding: 0;
			width: 24px;
			height: 24px;
			background: none;

			@include flex(0 0 24px);
			@include display-flex(column, center, center);

			span
			{
				display: none;
			}

			&:before
			{
				content: '\f053';

				@include font-fa($font-lg, $weight-extrabold, 1, $brand-dark);
			}

			&.BrainhubCarousel__arrowRight
			{
				&:before
				{
					content: '\f054';
				}
			}

			&.disabled, &[disabled]
			{
				@include opacity(0.6);
			}
		}

		&.spacing-sm
		{
			.BrainhubCarousel__trackContainer
			{
				.BrainhubCarousel__track
				{
					.BrainhubCarouselItem
					{
						padding-left: 7px;
						padding-right: 7px;
					}
				}
			}
		}
	}
}
