/* ----------  Third Party  ---------- */
@import '../../../node_modules/antd/dist/antd.css';
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
@import '../../../node_modules/@brainhubeu/react-carousel/lib/style.css';
@import '../../../node_modules/simplebar/dist/simplebar.css';

/* ----------  Functions  ---------- */
@import 'core/functions';

/* ----------  Direct CSS  ---------- */
@import 'css/autosuggest.css';
@import 'css/react-tags.css';
@import 'css/index.css';
@import 'css/App.css';

/* ----------  Mixins  ---------- */
@import 'core/mixins';

/* ----------  Utilities  ---------- */
@import 'utilities/variables';
@import 'utilities/contextuals';
@import 'utilities/iterations';

/* ----------  Modules  ---------- */
@import 'components/components';
@import 'common/common';
@import 'admin/admin';
@import 'individual/individual';
@import 'company/company';
@import 'institute/institute';
