/* ----------  Cards  ---------- */

.card
{
	// padding: 10px 16px;

	@include box-shadow(none);
	@include border-radius(8px);

	h2
	{
		@include font-p(rem-calc(15), $weight-regular, 1.2, $brand-dark);
	}

	p
	{
		font-size: $font-sm;
	}

	.restricted-height
	{
		line-height: 1.4 !important;
		overflow: hidden !important;
		height: #{ 1.4 * 2 }em !important;
	}

	.dropdown
	{
		.dropdown-toggle
		{
			padding: 0;
			width: 24px;
			height: 24px;
			background: transparent !important;
			color: rgba($brand-dark, 0.6) !important;

			@include display-flex(column, center, center);

			&:hover, &:active, &:focus
			{
				background: none !important;
			}
		}

		.dropdown-menu
		{
			border: 0;
			padding: 10px 0;
			max-width: 125px;
			min-width: 125px;

			@include border-radius(5px);
			@include box-shadow(0 0 6px 0 rgba($black-color, 0.16));

			.dropdown-item
			{
				border: 0;
				padding: 7px 16px;

				@include position;
				@include display-inline-flex(center, space-between);
				@include font-p($font-sm, $weight-regular, 1.2, $brand-dark);

				.fa
				{
					@include opacity(0.5);
					@include font-fa($font-sm, $weight-bold, 1, $brand-gray);
				}

				&:last-child
				{
					border: 0;

					&:after
					{
						display: none;
					}
				}

				&:after
				{
					content: '';
					height: 1px;
					width: calc(100% - 32px);
					background: $brand-gray-01;

					@include transform(translateX(-50%));
					@include position(absolute, -1, $bottom: 0, $left: 50%);
				}
			}
		}
	}

	.card-meta-header
	{
		margin: 0 -8.5px;
		padding: 10px 15px;
		background: rgba($brand-gray-01, 0.5);

		@include display-inline-flex(center, space-between);

		.header-title
		{
			@include font-p($font-sm, $weight-medium, 1.2, $brand-dark);

			i, span
			{
				color: $brand-dark;
			}
		}

		.header-post-meta
		{
			@include display-inline-flex;

			span, li
			{
				@include display-inline-flex($inline: true);
				@include font-p($font-sm, $weight-medium, 1.2, $brand-dark);
			}

			> span, li
			{
				margin-right: 5px;

				i, span
				{
					margin-right: 5px;

					&:last-child
					{
						margin: 0;
					}
				}

				i
				{
					display: block;
				}

				&:last-child
				{
					margin-right: 0;
				}
			}

			ul
			{
				li
				{
					&:after
					{
						content: '|';
						margin-left: 8px;
						margin-right: 2px;

						@include font-inherit;
					}

					&:last-child
					{
						&:after
						{
							display: none;
						}
					}
				}
			}
		}

		.header-meta
		{
			@include display-inline-flex;

			.meta-status
			{
				font-style: italic;
				margin-right: 10px;

				@include display-inline-flex;
				@include font-p($font-xs, $weight-regular, 1.2, rgba($brand-dark, 0.6));

				span
				{
					display: block;
				}

				.status-icon
				{
					margin-right: 5px;
				}

				.status-text
				{
					color: rgba($brand-warning-01, 0.7);
				}

				&:last-child
				{
					margin: 0;
				}
			}

			.meta-actions
			{
				@include display-inline-flex;

				.atn-meta
				{
					cursor: pointer;
					margin-right: 5px;

					@include font-p($font-sm, $weight-regular, 1.2, $brand-dark);

					&:last-child
					{
						margin: 0;
					}
				}
			}
		}
	}

	.card-description
	{
		margin-bottom: 15px;

		p
		{
			margin: 0;
			height: auto;

			@include font-p($font-sm, $weight-regular, 1.3, rgba($brand-dark, 0.6));
		}
	}

	.card-description-section, .card-section
	{
		margin-bottom: 20px;

		h3
		{
			margin: 0;

			@include font-p($font-md, $weight-medium, 1.3, $brand-dark);
		}

		p
		{
			margin-bottom: 10px;

			&:last-child
			{
				margin-bottom: 0;
			}
		}

		.section-title
		{
			margin-bottom: 8px;
		}

		&:last-child
		{
			margin-bottom: 0;
		}
	}

	.card-meta-info
	{
		ul
		{
			margin: 0;

			li
			{
				margin-bottom: 12px;

				@include display-inline-flex(normal);
				@include font-p($font-sm, $weight-regular, 1.4, rgba($brand-dark, 0.8));

				> span
				{
					display: block;

					@include font-inherit;
				}

				.item-icon
				{
					margin-right: 5px;
					color: $brand-gray-04;
					font-size: $font-base;

					i, span
					{
						color: inherit;
						font-size: inherit;
					}
				}

				&:last-child
				{
					margin: 0;
				}
			}
		}
	}

	.card-footer-actions
	{
		margin: 0 -20px;
		margin-top: 20px;

		@include display-inline-flex;

		.btn
		{
			color: $brand-gray-04;
			text-transform: uppercase;

			border: 1px solid $brand-gray-01;
			border-bottom: 0;
			border-left: 0;

			@include flex(1);
			@include border-radius(0 !important);

			&:last-child
			{
				border-right: 0;
			}
		}
	}

	&.card-post
	{
		padding: 0 15px;
		border: 1px solid $brand-gray-01;

		/* .btn
		{
			padding-top: 8px;
			padding-bottom: 8px;
			text-transform: none;

			@include font-p($font-sm, $weight-light, 1.2, $brand-gray-04);
		} */

		.fa-ticket, .fa-ticket-alt
		{
			font-size: $font-lg;
		}

		.mutualPics
		{
			font-style: italic;

			@include display-inline-flex;
			@include font-p($font-xs, $weight-regular, 1.2, rgba($brand-dark, 0.6));

			img
			{
				width: 18px;
				height: 18px;
			}
		}

		.post-time
		{
			font-style: italic;

			@include display-inline-flex(center, flex-end);
			@include font-p($font-xs, $weight-regular, 1.2, rgba($brand-dark, 0.6));

			i
			{
				color: inherit;
				font-size: inherit;
			}
		}

		.post-meta-container
		{
			margin-top: 16px;
		}

		.meta-info-list
		{
			margin-bottom: 8px;

			.fInfo
			{
				margin-bottom: 16px;

				@include font-p($font-sm, $weight-regular, 1.2, $brand-dark);

				.fa
				{
					font-size: $font-base;
					color: $brand-gray-03;
				}
			}
		}

		.post-head
		{
			display: block;
			padding-top: 15px;
			margin-bottom: 20px;

			.post-title
			{
				margin-bottom: 3px;

				@include text-truncate;
				@include font-p($font-md, $weight-medium, 1.3, $brand-dark);
			}

			.post-designation
			{
				margin-bottom: 7px;

				@include font-p($font-base, $weight-medium, 1.3, $brand-dark);

				&:last-child
				{
					margin: 0;
				}
			}

			.post-description
			{
				@include font-p($font-sm, $weight-regular, 1.3, $brand-dark);
			}

			.post-head-details
			{
				margin-bottom: 10px;

				@include display-inline-flex;

				.details-avatar
				{
					margin-right: 10px;

					img
					{
						@include border-radius(99px);
					}
				}

				.details-content
				{
					@include flex(1);
					@include text-truncate;
				}

				&:last-child
				{
					margin: 0;
				}
			}
		}

		.sent-to-info
		{
			margin-bottom: 10px;

			label
			{
				margin-bottom: 10px;

				@include font-p($font-xs, $weight-regular, 1.2, $brand-warning);
			}

			.info-content
			{
				@include display-inline-flex;

				img
				{
					width: 40px;
					height: 40px;
					margin-right: 15px;
				}

				h2
				{
					@include font-p($font-sm, $weight-regular, 1.2, $brand-dark);
				}

				.content-avatar
				{
					width: 40px;
					height: 40px;
					margin-right: 15px;
					background: $brand-gray-01;

					@include border-radius(99px);
					@include display-flex(column, center, center);

					i
					{
						color: $brand-dark;
						font-size: $font-base;
					}
				}
			}
		}

		.referred-by
		{
			label
			{
				margin-bottom: 10px;

				@include font-p($font-xs, $weight-regular, 1.2, $brand-gray-04);
			}
		}

		.card-footer-actions
		{
			border-top: 0;
			margin: 0 -15px;
			margin-top: 10px;
		}

		&:before
		{
			display: none;
		}
	}

	&.card-invite, &.card-campus-post, &.card-post
	{
		padding: 0 16px;

		.btn
		{
			font-weight: $weight-medium;
		}

		.card-meta-header
		{
			margin: 0 -16px;
		}

		.card-head
		{
			display: block;
			padding-top: 10px;
			margin-bottom: 15px;
		}

		.card-meta-info
		{
			margin-bottom: 30px;
		}

		.card-footer-actions
		{
			&.row
			{
				margin: 0 -20px;
			}
		}
	}

	&.card-invite-status
	{
		margin-bottom: 20px;

		.card-header
		{
			border: 0;
			background: none;
			padding: 8px 16px;

			@include display-inline-flex;

			img
			{
				max-width: 55px;
				margin-right: 15px;
			}
		}

		.card-body
		{
			padding: 15px 16px;

			.meta-info
			{
				@include display-inline-flex(center, space-between);

				.info-block
				{
					@include display-inline-flex;
					@include font-p($font-sm, $weight-regular, 1.2, rgba($brand-dark, 0.8));

					i, .fa
					{
						margin-right: 6px;
						color: $brand-gray;
					}

					i, span
					{
						display: block;
					}
				}
			}
		}

		.card-footer
		{
			border: 0;
			padding: 8px 16px;
			text-align: center;
			background: $brand-gray-01;

			p
			{
				margin: 0;
				text-transform: uppercase;

				@include font-p($font-base, $weight-regular, 1.2, $brand-dark);
			}
		}
	}

	&.userVcard
	{
		padding-top: 12px;
		padding-bottom: 12px;

		@include transition;

		h3
		{
			@include font-p($font-sm, $weight-regular, 1.4, $brand-dark);

			span
			{
				@include opacity(0.8);
			}
		}

		&:hover, &:active, &:focus
		{
			border-color: $brand-dark;
		}
	}

	&.card-profile-edit-form
	{
		padding: 30px;
	}
	
	&.connection-card
	{
		padding: 25px 20px !important;

		.userPic
		{
			margin: 0;
			width: 100px;
			height: 100px;

			@include border-radius(50%);
		}

		h2
		{
			margin-bottom: 7px;

			@include font-p($font-md, $weight-regular, 1.2, $brand-dark);
		}

		label, small, span
		{
			display: block;
			margin: 0;
			height: auto;
			margin-bottom: 4px;

			@include font-p($font-sm, $weight-regular, 1.2, rgba($brand-dark, 0.8));

			&:last-child
			{
				margin: 0;
			}
		}

		.dropdown
		{
			@include position(absolute, 999, $top: 6px !important, $right: 8px !important);
		}

		.card-image
		{
			margin-right: 15px;

			@include flex(0 0 100px);
		}

		.card-body
		{
			padding: 0;
		}

		.card-content
		{
			@include display-inline-flex(normal);
		}

		.card-floating-actions
		{
			padding-left: 14px;
			border-left: 1px solid rgba($brand-gray-01, 0.5);

			@include position(absolute, 99, $bottom: 9px, $right: 6px);

			.atn
			{
				width: 40px;
				height: 40px;
				background: $brand-gray-01;

				@include transition;
				@include border-radius(99px);
				@include display-flex(column, center, center);
				@include font-fa($font-sm, $weight-regular, 1, $brand-dark);

				&:hover, &:active, &:focus
				{
					color: $white-color;
					background: $brand-dark;
				}
			}
		}
	}
	&.padding-1rem
	{
		padding: 1rem !important; 
	}
	&.card-sublogin
	{
		margin-bottom: 15px;
		border: 1px solid $brand-gray-01;

		@include position;
		@include border-radius(8px);

		.card-pre-header
		{
			width: 100%;
			padding: 3px 8px;

			@include position(absolute, 99, 0, 0);
			@include display-inline-flex(center, space-between);

			span
			{
				display: block;
			}

			.card-actions-meta
			{
				@include display-inline-flex;

				.card-post-time-meta
				{
					margin-right: 22px;

					@include display-inline-flex;
					@include font-s(rem-calc(9), $weight-regular, 1.2, $brand-gray-06);

					.meta-text
					{
						margin-left: 6px;
						font-style: italic;
					}
				}

				.card-actions
				{
					.btn
					{
						color: $brand-dark-01;

						@include opacity(0.6);
					}
				}
			}
		}

		.card-user-details
		{
			@include display-inline-flex;

			.user-avatar
			{
				margin-right: 15px;

				img
				{
					width: 80px;
					height: 80px;
					object-fit: cover;

					@include border-radius(99px);
				}
			}

			.user-details
			{
				li, a
				{
					@include font-s($font-sm, $weight-regular, 1.4, $brand-dark);
				}

				.item-name
				{
					font-size: $font-base;
					font-weight: $weight-bold;
				}

				ul
				{
					margin: 0;

					li
					{
						margin-bottom: 5px;

						a
						{
							&:hover, &:active, &:focus
							{
								color: $brand-primary;
							}
						}

						&:last-child
						{
							margin-bottom: 0;
						}
					}
				}
			}
		}

		.card-user-meta
		{
			padding-top: 18px;

			@include display-flex(column, flex-end, space-between);

			span
			{
				display: block;
			}

			.card-user-role
			{
				padding: 6px 12px;
				text-transform: uppercase;
				background: $brand-gray-03;

				@include display-inline-flex;
				@include border-radius(10px 0 0 10px);
				@include font-s($font-xs, $weight-regular, 1, $white-color);

				.role-text
				{
					margin-right: 6px;
				}
			}

			.card-created-meta
			{
				padding-right: 25px;

				@include display-inline-flex;
				@include font-s($font-xs, $weight-regular, 1.2, $brand-dark-01);

				.user-avatar
				{
					margin-right: 8px;

					img
					{
						width: 25px;
						height: 25px;
						object-fit: cover;

						@include border-radius(99px);
					}
				}

				.meta-icon
				{
					margin-right: 5px;

					i
					{
						font-size: $font-xs;
					}
				}

				.meta-text
				{
					margin-right: 15px;
				}

				.meta-user
				{
					@include display-inline-flex;
				}
			}
		}

		.card-rights-list
		{
			@include display-inline-flex;

			h5, li, span
			{
				@include font-s($font-xs, $weight-semibold, 1.2, $brand-gray-06);
			}

			h5
			{
				margin: 0;
				text-transform: uppercase;
				color: rgba($brand-warning, 0.7);
			}

			ul
			{
				@include flex-wrap(nowrap);

				li
				{
					margin-right: 15px;

					@include user-select(none);

					&:last-child
					{
						margin-right: 0;
					}
				}
			}

			.list-title
			{
				margin-right: 12px;
			}

			.list-contents
			{
				overflow: auto;
				padding: 6px 0;

				@include flex(1 1 100%);
				@include position;

				.simplebar-track.simplebar-horizontal
				{
					height: 5px;

					.simplebar-scrollbar
					{
						top: 0;
						height: 100%;
					}
				}
			}
		}

		.card-body
		{
			margin: 0;
			padding: 20px;
			padding-right: 0;
			border-bottom: 1px dashed $brand-gray-01;

			@include display-inline-flex(stretch, space-between);
		}

		.card-footer
		{
			border: 0;
			padding: 6px 36px;
			background: transparent;
		}

		&:last-child
		{
			margin: 0;
		}
	}

	&.card-explore
	{
		.card-additional-footer
		{
			padding: 0 20px;

			@include position;
			@include display-inline-flex(center, justify-center);

			.card-btn-calendar
			{
				height: 100%;
				border-left: 1px solid rgba($brand-gray-01, 0.5);

				@include position(absolute, 99, $top: 0, $right: 0);

				.btn
				{
					padding: 0;
					width: 68px;
					height: 100%;
					background: none;

					@include border-radius(0);

					&:hover, &:active, &:focus
					{
						background: rgba($brand-dark, 0.05);
					}
				}
			}

			.mutualPics
			{
				font-size: $font-xs;

				img
				{
					width: 20px;
					height: 20px;
					margin-left: -10px;

					&:first-child
					{
						margin-left: 0;
					}

					&:last-child
					{
						margin-right: 4px;
					}
				}
			}
		}
	}

	&.card-alumini
	{
		.card-header
		{
			padding: 7px 14px;

			@include display-inline-flex;

			.header-date, .header-time
			{
				margin: 0;

				@include display-inline-flex;
			}

			.header-date
			{
				margin-right: 20px;
				padding-right: 20px;
				border-right: 1px solid $brand-dark;
			}
		}
	}
}

/* ----------  Company Cards  ---------- */

.company-profile__products--card
{
	.btns
	{
		i, .fa
		{
			color: $brand-warning;
		}
	}

	.card-title
	{
		margin-bottom: 8px;

		@include display-inline-flex;

		h4
		{
			margin: 0;

			@include flex(1);
		}
	}
}

.modal
{
	.company-profile__products--card
	{
		padding: 15px 0;
		border-bottom-style: dashed;
		border-bottom-color: $brand-gray-01;
	}
}

/* ----------  Cards Listing  ---------- */

.cards-listing
{
	padding: 15px;
	margin-bottom: 30px;
	border: 1px solid $brand-gray-01;

	@include border-radius(8px);

	.card
	{
		border: 0;
		padding: 15px 0;
		background: none;
		border-bottom: 1px dashed $brand-gray-01;

		@include border-radius(0);

		h2
		{
			margin: 0;
			padding: 0;
			border: 0;
		}

		p
		{
			margin: 0;
		}

		.card-logo
		{
			img
			{
				max-width: 40px;
			}
		}

		.card-title
		{
			margin: 0;
			padding: 0 15px;
			padding-right: 50px;

			@include flex(1);

			h2, h3, h4
			{
				@include font-p($font-base, $weight-regular, 1.4, $brand-dark);
			}
		}

		.card-designation
		{
			p
			{
				margin: 0;

				@include font-p($font-sm, $weight-regular, 1.2, rgba($brand-dark, 0.8));
			}
		}

		.card-header
		{
			border: 0;
			padding: 0;
			background: none;
			margin-bottom: 10px;

			@include position;
			@include border-radius(0);
			@include display-inline-flex;

			.card-actions
			{
				@include display-inline-flex;
				@include position(absolute, 5, $top: 0, $right: 0);

				.atn
				{
					display: block;
					margin-right: 15px;

					@include transition;
					@include font-fa($font-base, $weight-regular, 1, rgba($brand-dark-01, 0.6));

					&:last-child
					{
						margin: 0;
					}

					&:hover, &:active, &:focus
					{
						color: $brand-warning;
					}
				}
			}

			&:last-child
			{
				margin-bottom: 0;
			}
		}

		.card-body
		{
			margin: 0;
			padding: 0;
			background: none;
			padding-right: 35px;

			p
			{
				@include font-p($font-base, $weight-regular, 1.4, rgba($brand-dark, 0.6));
			}
		}

		.card-footer
		{
			border: 0;
			margin: 0;
			padding: 0;
			background: none;

			@include border-radius(0);

			.card-footer-actions
			{
				margin: 0;
				margin-top: 5px;

				@include justify-content(flex-end);

				.atn
				{
					@include transition;
					@include font-p($font-xs, $weight-regular, 1.2, $brand-gray-03);

					&:hover, &:active, &:focus
					{
						color: $brand-warning;
					}
				}
			}
		}

		&:last-child
		{
			border-bottom: 0;
			// padding-bottom: 0;
		}

		&.card-horizontal
		{
			@include display-inline-flex(normal);

			> .card-image
			{
				padding-right: 10px;
			}

			.card-header
			{
				@include align-items(normal);
				@include flex-direction(column);
			}

			.card-content
			{
				@include flex(1);
			}

			.card-title
			{
				padding: 0;
			}
		}

		&.userVcard
		{
			> .card-image
			{
				padding-right: 25px;
			}

			.card-title
			{
				margin-bottom: 10px;
			}
		}
	}

	&.cards-listing-spaced
	{
		padding-left: 30px;
		padding-right: 30px;
	}

	&:last-child
	{
		margin-bottom: 0;
	}
}


/* ----------  Wallet Cards  ---------- */

.card.card-wallet
{
	overflow: hidden;

	h3
	{
		margin: 0;
		text-transform: uppercase;

		@include font-p($font-md, $weight-bold, 1.2, $brand-dark);
	}

	h4
	{
		margin: 0;

		@include font-p($font-sm, $weight-regular, 1.2, $brand-dark);
	}

	.btn
	{
		line-height: 1;
		padding: 8px 10px;
	}

	.card-balance
	{
		.balance-title
		{
			margin-bottom: 10px;
		}

		.balance-amount
		{
			@include display-inline-flex;

			span
			{
				display: block;

				@include font-p(rem-calc(28), $weight-bold, 1.2, $brand-dark);
			}

			.amount-symbol-icon
			{
				margin-right: 4px;
				font-size: rem-calc(24);
			}
		}
	}

	.card-header, .card-footer
	{
		border: 0;
	}

	.card-header
	{
		padding: 8px 10px;
		text-align: center;
		padding-bottom: 6px;

		@include border-radius(0);
		@include faded-background($brand-gray-03, 0.5);
	}

	.card-body
	{
		padding: 15px 10px;

		@include flex(1);
		@include display-flex(column);
	}

	.card-footer
	{
		padding: 15px 10px;
		background: none;

		@include display-inline-flex;

		.btn
		{
			margin-right: 10px;

			@include flex(1);

			&:last-child
			{
				margin: 0;
			}
		}
	}
}

.card.card-wallet-details
{
	@include display-inline-flex(stretch);

	h4
	{
		margin: 0;

		@include font-p($font-sm, $weight-regular, 1.2, $brand-dark);
	}

	.card-sections
	{
		border-right: 1px solid rgba($brand-gray-01, 0.5);

		@include flex(1);
		@include display-flex;

		.card-section
		{
			margin: 0;
			padding: 0;
			padding: 0 3px;
			max-height: 50%;
			border-bottom: 1px solid rgba($brand-gray-01, 0.5);

			@include flex(1 0 50%);
			@include display-flex;

			ul
			{
				margin: 0;

				li
				{
					margin-bottom: 10px;

					@include display-inline-flex;
					@include font-p($font-sm, $weight-regular, 1.2, $brand-dark);

					span
					{
						display: block;
					}

					.item-text
					{
						min-width: 65px;
						margin-right: 10px;
					}

					.item-amount
					{
						@include display-inline-flex;

						.amount-icon
						{
							margin-right: 5px;
						}

						.amount-symbol-icon
						{
							margin-right: 3px;
							font-size: $font-xs;
						}

						.amount-text
						{
							font-weight: $weight-medium;
						}
					}

					&:last-child
					{
						margin-bottom: 0;
					}
				}
			}

			.section-title
			{
				margin: 0;
				padding: 6px 0;
				text-align: center;
				padding-bottom: 4px;
				border-bottom: 1px solid rgba($brand-gray-01, 0.5);
			}

			.section-content
			{
				padding: 15px 0;

				@include flex(1);
				@include display-flex(column, center, center);
			}

			&:last-child
			{
				border-bottom: 0;
			}

			&.section-hero
			{
				max-height: none;
				text-align: center;

				@include flex(1);

				.section-content
				{
					padding: 0;

					@include display-flex;

					ul
					{
						height: 100%;

						@include display-flex;

						li
						{
							margin: 0;
							padding: 15px 0;
							font-size: $font-base;
							border-bottom: 1px solid rgba($brand-gray-01, 0.5);

							@include flex(1);
							@include flex-direction(column);
							@include justify-content(space-between);

							.item-amount
							{
								.amount-icon
								{
									margin-right: 15px;
									font-size: rem-calc(24);
								}

								.amount-symbol-icon
								{
									font-size: rem-calc(17);
								}

								.amount-text
								{
									font-size: $font-lg;
								}
							}

							&.item-cash
							{
								.item-amount
								{
									.amount-icon
									{
										color: rgba($brand-warning-02, 0.8);
									}
								}
							}

							&.item-voucher
							{
								.item-amount
								{
									.amount-icon
									{
										color: rgba($brand-warning-01, 0.7);
									}
								}
							}

							&:last-child
							{
								border-bottom: 0;
							}
						}
					}
				}
			}
		}

		&:last-child
		{
			border-right: 0;
		}
	}
}

.card.card-bank
{
	h4
	{
		@include font-p($font-base, $weight-regular, 1.2, $brand-gray-04);
	}

	h5
	{
		@include font-p($font-sm, $weight-medium, 1.2, $brand-gray-01);
	}

	.card-floating-actions
	{
		@include position(absolute, 5, $top: 10px, $right: 10px);

		ul
		{
			margin: 0;

			li
			{
				margin-right: 15px;

				a
				{
					display: block;

					@include font-fa($font-sm, $weight-regular, 1, rgba($brand-dark-01, 0.6));

					i, span
					{
						display: block;
					}
				}

				&:last-child
				{
					margin-right: 0;
				}
			}
		}
	}

	.card-bank-details
	{
		margin-bottom: 20px;

		@include display-inline-flex;

		.bank-logo
		{
			width: 68px;
			height: 68px;
			margin-right: 20px;
			background: $brand-gray-01;

			@include border-radius(99px);

			img
			{
				width: 100%;
				height: 100%;
				object-fit: cover;

				@include border-radius(99px);
			}
		}

		.bank-name
		{
			margin-bottom: 10px;
		}
	}

	.card-bank-account-details
	{
		padding: 0 10px;

		ul
		{
			margin: 0;

			li, a
			{
				@include font-p($font-sm, $weight-regular, 1.4, $brand-dark);
			}

			li
			{
				margin-bottom: 10px;

				&:last-child
				{
					margin: 0;
				}
			}
		}
	}

	.card-body
	{
		padding: 0 18px;
		padding-top: 25px;
		padding-bottom: 20px;

		@include position;
	}
}

/* ----------  Post Detail Card  ---------- */

.card.card-post-details
{
	padding: 0;
	margin-bottom: 30px;

	.card-meta-header
	{
		margin: 0;
	}

	.card-post-head
	{
		margin-bottom: 20px;

		h2, .post-title
		{
			margin-bottom: 8px;

			@include font-p($font-lg, $weight-medium, 1.3, $brand-dark);
		}

		.post-designation
		{
			margin: 0;

			@include font-p($font-sm, $weight-regular, 1.3, $brand-dark);
		}
	}

	.card-post-info-list
	{
		margin-bottom: 20px;

		ul
		{
			margin-bottom: 10px;

			li
			{
				margin-right: 25px;

				@include display-inline-flex;
				@include font-p($font-sm, $weight-regular, 1.3, rgba($brand-dark, 0.8));

				span, i
				{
					display: block;
				}

				i
				{
					font-size: $font-base;
					color: $brand-gray-04;
				}

				.item-icon
				{
					margin-right: 5px;
				}

				&:last-child
				{
					margin: 0;
				}
			}

			&:last-child
			{
				margin: 0;
			}
		}

		&:last-child
		{
			margin-bottom: 0;
		}
	}

	.card-description
	{
		margin: 0;
	}

	.card-body
	{
		padding: 30px;
		padding-bottom: 20px;
	}

	.card-footer
	{
		border: 0;
		padding: 0 40px;
		background: none;
		padding-bottom: 20px;

		@include display-inline-flex(center, space-between);

		.card-primary-actions
		{
			.btn
			{
				min-width: 145px !important;
			}

			ul
			{
				li
				{
					margin-right: 25px;

					&:empty
					{
						display: none;
					}

					&:last-child
					{
						margin: 0;
					}
				}
			}
		}

		.card-posted-by
		{
			@include display-inline-flex;

			span, img, i
			{
				display: block;
			}

			span
			{
				@include font-p($font-xs, $weight-regular, 1.2, rgba($brand-dark, 0.6));
			}

			.posted-by-title
			{
				margin-right: 10px;
			}

			.posted-by-user
			{
				@include display-inline-flex;

				.user-avatar
				{
					margin-right: 10px;

					img
					{
						width: 30px;
						height: 30px;
						object-fit: cover;
					}
				}

				.user-name
				{
					color: $brand-dark;
					font-size: $font-sm;
				}
			}
		}
	}

	&:last-child
	{
		margin-bottom: 0;
	}
}

.card.card-post-descriptions
{
	.card-body
	{
		padding: 20px 30px;
	}
}

.card.voucherDtCard
{
	padding: 0;

	.card-header
	{
		padding: 10px;
		background: none;
		text-align: center;
		border-bottom: 1px dashed $brand-gray-01;

		.card-title
		{
			margin: 0;

			h3
			{
				margin: 0;

				@include font-p($font-base, $weight-regular, 1.2, $brand-dark);
			}
		}
	}
}
