.wallet-card {
    padding: 0 !important;
    &__header {
        background-color: $brand-gray-02;
        display: flex;
        justify-content: space-between;
        padding: 4px 0;
        padding-left: 20px;
        margin-bottom: 20px;
        &--bonus {
            font-size: $font-sm;
            border-bottom: 1px solid $brand-gray-02;
            padding: 3px 0;
            text-align: center;
            &_2 {
                border: 1px solid $brand-gray-02;
            }
        }
        span {
            font-size: $font-sm;
        }
        button {
            background-color: $orange;
            border: none;
            color: $white-color;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            font-size: $font-xs;
            padding: 0 10px;
        }
    }
    &__body {
        padding: 0 10px;
        &--yellow-btn, &--orange-btn {
            border: 2px solid #D6A021;
            border-radius: 12px;
            background-color: transparent;
            font-size: $font-xs;
            padding: 5px 8px;
            margin-top: 8px;
        }
        &--orange-btn {
            border: 2px solid $orange;
        }
    }
    .wallet-stat {
        padding: 8px;
    }
    h3 {
        margin: 26px 0 !important;
    }
}

.wallet-transaction-head {
    background-color: $brand-gray-01;
    border-radius: 8px;
    padding: 10px 0;
    margin-bottom: 20px;
}

.wallet-transaction-table {
    border: 1px solid $brand-gray-01;
    border-radius: 8px;
    padding: 12px 0;
    font-family: $font-secondary;
    font-size: $font-xs;
    div {
        border-right: 1px solid $brand-gray-01;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    div:nth-child(3) {
        font-family: $font-primary;
        font-size: $font-sm;
        justify-content: end;
        padding-left: 60px;
    }
    .chain {
        border: 1px dashed $orange;
        border-radius: 50%;
        padding: 6px 10px;
    }
}
.add-bank-modal{
    z-index: 99999 !important;
}
.add-bank-modal .modal-content {
    max-height: none !important;
    .modal-header {
        font-size: $font-sm !important;
    }
    .modal-footer {
        justify-content: center;
        border-top: none;
    }
    label {
        font-size: $font-xs !important;
    }
}