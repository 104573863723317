/* ----------  Wallet Container  ---------- */

.wallet-container
{
	.wallet-highlights-container
	{
		margin-bottom: 30px;

		.row-wallet-cards
		{
			margin: 0 -20px;

			[class^="col"]
			{
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		.wallet-cards-container
		{
			height: 100%;

			@include display-inline-flex(stretch, normal);

			.card
			{
				height: 100%;
				max-width: 50%;

				@include flex(1 0 50%);

				&:first-child
				{
					border-right: 0;
				}
			}

			.card.card-wallet
			{
				@include border-radius(8px 0 0 8px);
			}

			.card.card-wallet-details
			{
				@include border-radius(0 8px 8px 0);
			}
		}
	}


	.wallets-transactions-container
	{
		.transactions-head
		{
			margin-bottom: 15px;
			padding-bottom: 10px;
			border-bottom: 1px solid $brand-gray-04;

			@include display-inline-flex;

			.head-title
			{
				margin-right: 40px;

				h3
				{
					margin: 0;

					@include font-p($font-md, $weight-medium, 1.2, $brand-dark);
				}
			}

			.head-transactions-type
			{
				margin-right: 15px;

				@include flex(1);
				@include display-inline-flex;

				.custom-control
				{
					margin-right: 20px;

					&:last-child
					{
						margin: 0;
					}
				}
			}

			.head-search
			{
				margin-right: 25px;
			}

			.head-transactions-filters
			{
				margin-right: 12px;

				@include display-inline-flex;

				.dropdown
				{
					border-right: 1px solid $brand-gray-01;

					.dropdown-toggle
					{
						padding: 0 10px;
						background: none;

						@include display-inline-flex;

						.fa, .fas, .far
						{
							font-size: $font-base;
							color: $brand-gray-04;
						}

						&:after
						{
							display: none;
						}

						&:hover, &:active, &:focus
						{
							background: none;
						}
					}

					&:last-child
					{
						border-right: 0;
					}
				}
			}

			.head-actions
			{
				.btn
				{
					min-width: 150px;
				}

				ul
				{
					margin: 0 -5px;

					li
					{
						padding: 0 5px;
					}
				}
			}
		}

		.transactions-table
		{
			table.table
			{
				border: 0;

				thead
				{
					border: 0;
					border-bottom: 10px solid transparent;

					tr, th
					{
						border: 0;
						padding: 0;
					}

					tr
					{
						th
						{
							cursor: pointer;
							text-align: center;

							@include font-s($font-sm, $weight-semibold, 1.3, $brand-dark);

							.th-item
							{
								min-height: 38px;
								background: $brand-gray-01;

								@include display-flex(column, center, center);

								> span
								{
									padding: 0 18px;

									@include position;
									@include display-inline-flex($inline: true);

									&:before, &:after
									{
										@include position(absolute, 99, $right: 0);
										@include font-fa($font-xs, $weight-bold, 1, $brand-dark);
									}

									&:before
									{
										content: '\f077';
										top: -4px;
									}

									&:after
									{
										content: '\f078';
										bottom: -4px;
									}
								}
							}

							&:first-child
							{
								span
								{
									@include border-radius(8px 0 0 8px);
								}
							}

							&:last-child
							{
								span
								{
									@include border-radius(0 8px 8px 0);
								}
							}

							&.no-sort
							{
								.th-item
								{
									> span
									{
										&:before, &:after
										{
											display: none;
										}
									}
								}
							}

							&.sorting
							{
								.th-item
								{
									> span
									{
										&:before, &:after
										{
											top: 50%;
											font-size: $font-sm;

											@include transform(translateY(-50%));
										}
									}
								}

								&.asc
								{
									.th-item
									{
										> span
										{
											&:before
											{
												content: '\f062';
											}

											&:after
											{
												display: none;
											}
										}
									}
								}

								&.desc
								{
									.th-item
									{
										> span
										{
											&:before
											{
												display: none;
											}

											&:after
											{
												content: '\f063';
											}
										}
									}
								}
							}
						}
					}
				}

				tbody
				{
					tr
					{
						border-bottom: 15px solid transparent;

						td
						{
							padding: 0;
							height: auto;

							@include font-p($font-sm, $weight-regular, 1.3, $brand-dark);

							.td-item
							{
								padding: 10px 14px;
								min-height: 85px;

								border: 1px solid $brand-gray-01;
								border-right-width: 0;
								border-left-width: 0;

								@include position;
								@include display-flex(column, normal, center);

								p
								{
									margin: 0;
								}

								&:after
								{
									content: '';
									width: 1px;
									height: calc(100% - 20px);
									background: $brand-gray-01;

									@include transform(translateY(-50%));
									@include position(absolute, 1, $top: 50%, $right: 0);
								}
							}

							&:first-child
							{
								.td-item
								{
									border-left-width: 1px;

									@include border-radius(8px 0 0 8px);
								}
							}

							&:last-child
							{
								.td-item
								{
									border-right-width: 1px;

									@include border-radius(0 8px 8px 0);

									&:after
									{
										display: none;
									}
								}
							}

							&:only-child
							{
								.td-item
								{
									border-width: 1px;

									@include border-radius(8px);

									&:before, &:after
									{
										display: none;
									}
								}
							}

							&.td-serial
							{
								width: 90px;
							}

							&.td-date
							{
								width: 110px;
							}

							&.td-remark
							{
								.td-item
								{
									font-size: $font-base;
									color: rgba($brand-dark, 0.6);
								}
							}

							&.td-user
							{
								width: 200px;

								.td-item
								{
									@include display-inline-flex;

									span, img
									{
										display: block;
									}

									.user-avatar
									{
										margin-right: 6px;

										img
										{
											width: 40px;
											height: 40px;
											object-fit: cover;

											@include border-radius(99px);
										}
									}
								}
							}

							&.td-chain
							{
								width: 70px;

								.td-item
								{
									@include position;

									.chain-count
									{
										width: 100%;
										height: 100%;

										@include position(absolute, 3, 0, 0);
										@include display-flex(column, center, center);
									}
								}
							}

							&.td-amount
							{
								width: 100px;

								.td-item
								{
									@include display-inline-flex;

									.amount-icon
									{
										margin-right: 10px;
										font-size: $font-base;

										.fa-ticket-alt
										{
											color: rgba($brand-warning-01, 0.8);
										}

										.fa-coins
										{
											color: rgba($brand-warning-02, 0.8);
										}
									}

									.amount-symbol-icon
									{
										margin-right: 3px;
										font-size: $font-xs;
									}

									.amount-text
									{
										font-weight: $weight-medium;
									}
								}
							}

							&.td-status
							{
								width: 60px;

								.td-item
								{
									font-size: $font-lg;

									.fa, .fas, .far
									{
										color: rgba($brand-dark, 0.6);
									}
								}
							}
						}

						&:last-child
						{
							border-bottom: 0;
						}
					}
				}
			}
		}
	}
}
