/* ----------  Grid  ---------- */

$gutters: (8, 10, 15, 16);

.row
{
	@each $gutter in $gutters
	{
		$space: $gutter / 2;

		&.gutter-#{$gutter}
		{
			margin-left: #{$space * -1}px !important;
			margin-right: #{$space * -1}px !important;

			> [class*="col"]
			{
				padding-left: #{$space}px !important;
				padding-right: #{$space}px !important;
			}
		}
	}
}
