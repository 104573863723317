.company-edit-profile {
    &__left {
        h3 {
            font-size: $font-base;
        }
        span {
            font-size: $font-sm;
        }
    }
    .react-autosuggest__input {
        padding: 17px 10px;
    }
    .edit-btn {
        i {
            color: $orange;
        }
    }
    .btn-view-all {
        color: $orange;
        font-size: $font-sm !important;
    }
    .products{
        border: 1px solid $brand-gray-01;
        padding: 0 40px;
        border-radius: 8px;
    }
    .product-card {
        .btns {
            text-align: end;
        }
        border-bottom: 1px solid $brand-gray-01;
            padding: 20px 0;
            img {
                width: 40px;
                border-radius: 50%;
            }
            h4 {
                font-size: $font-sm;
            }
            p{
                font-size: $font-sm;
                color: $brand-gray-04;
                margin-bottom: 0;
            }
    }
    .product-card:last-child {
        border-bottom: none;
    }
    .subsidary-card {
        padding: 1rem 0.5rem !important;
    }
}