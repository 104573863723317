.ind-edit-profile {
    .position-lg-sticky {
        @include media-breakpoint-up(lg) {
            position: sticky;
            top: 80px;
        }
    }
    font-family: $font-primary;
    .profileCard {
        h3 {
            font-weight: 400;
            small {
                font-size: $font-xs;
            }
        }
        li {
            padding: 2px 0;
        }
    }

    h5 {
        font-weight: 500;
    }
    .react-tags {
        border: 1px solid $brand-gray-01;
        border-radius: 8px;
    }
    .btn-dark:hover {
        border: 1px solid #081238!important;
        background-color: white !important;
        color: #081238 !important;
    }
    .btn-dark-o {
        border: 1px solid #081238!important;
        background-color: white !important;
        color: #081238 !important;
    }
    .btn-dark-o:hover {
        color: #fff!important;
        background-color: #081238!important;
        background-color: var(--blue-black)!important;
    }
}

.edit-modals .modal-content {
    max-height: none !important;
}