.institute-sec-nav {
    padding: 10px 0;
    .nav-item {
        margin-left: 20px;
        @include media-breakpoint-down(md) {
            margin-left: 0;
        }
    }
    .nav-item:first-child {
        margin-left: 0;
    }
    input {
        font-style: italic;
    }
    // .btn {
    //     background: none;
    //     border: none;
    //     background-color: transparent !important;
    //     i {
    //         color: $brand-gray-04;
    //     }
    // }
    label {
        margin-bottom: 0;
        font-size: $font-sm;
    }
    .import-btn {
        background-color: $brand-gray-03 !important;
        margin-left: 10px;
    }
}

.btn-bg-none {
    background-color: transparent !important;
    border: none !important;
}

@import 'navbar';
@import 'home';
@import 'campus';
@import 'editProfile';
@import 'manageUsers';